import '@mdxeditor/editor/style.css';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { editVacancy } from '../../../../api/vacancies';
import InputAutosize from '../../../../components/ui/InputAutosize';
import {
  COLORS,
  labelsByGuestResponseStatus,
  labelsBySalaryCurrency,
} from '../../../../constants';
import { ReactComponent as ArrowDown } from '../../../../icons/arrow-down.svg';
import { ReactComponent as DeleteOutline } from '../../../../icons/delete-outline.svg';
import {
  showErrorMsg,
  showSuccessMsg,
} from '../../../../store/slices/alertSlice';
import classes from './EditGuestResponse.module.scss';
import { getResume } from '../../../../api/resume';
import { getApplicant } from '../../../../api/candidates';
import { ContactMenuSelect } from '../../../candidates/candidateScreen/components/ContactMenuSelect/ContactMenuSelect';
import { useDebounceCallback } from '../../../../constants';
import TextareaAutosize from '../../../../components/ui/TextareaAutosize';

import {
  getGuestResponse,
  getGuestResponsePdf,
  editGuestResponse,
  deleteGuestResponse,
  getGuestVacancyResponseResume,
  editGuestVacancyResponseResume,
} from '../../../../api/guest';
import { Loader } from '../../../../components/ui/Loader';
import { createSkill } from '../../../candidates/candidateScreen/components/ConstructorResumeBlock/utils';
import { deleteSkill } from '../../../candidates/candidateScreen/components/ConstructorResumeBlock/utils';
import { updateSkill } from '../../../candidates/candidateScreen/components/ConstructorResumeBlock/utils';
import { ReactComponent as PlusSvg } from '../../../../icons/plus_circle.svg';
import { NotFoundPage } from '../../../system/NotFoundPage';

const isReadyToRelocate = {
  true: 'Да',
  false: 'Нет',
};

export const EditGuestResponse = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    vacancy: {},
    resumeResponse: {},
    patchVacancy: {},
    loaded: false,
    notFound: false,
  });

  const updateServerResume = async (newResume) => {
    const id = user.id;
    const result = await editGuestResponse(user.access_token, id, newResume);
    const resResume = await editGuestVacancyResponseResume(
      user.access_token,
      state.vacancy.resumeRESPONSE.id,
      {
        ...state.resumeResponse,
      }
    );
    if (resResume) {
      setState((prevState) => ({
        ...prevState,
        resumeResponse: {
          newResume,
        },
      }));
    }
    if (result) {
      setState((prevState) => ({
        ...prevState,
        vacancy: {
          ...prevState.vacancy,
          resume: newResume,
        },
      }));
    } else {
      console.error('Failed to update resume');
    }
  };

  const saveBtnHandler = async () => {
    try {
      const res = await editGuestResponse(user.access_token, id, {
        ...state.patchVacancy,
      });

      if (!res) {
        dispatch(showErrorMsg('Ошибка при сохранении'));
        return;
      }

      const resumeData = {
        firstName: state.resumeResponse.firstName || '',
        middleName: state.resumeResponse.middleName || '',
        lastName: state.resumeResponse.lastName || '',
        isReadyToRelocate: state.resumeResponse.isReadyToRelocate || false,
        isVisibleToEmployers:
          state.resumeResponse.isVisibleToEmployers || false,
        title: state.resumeResponse.title || '',
        desiredSalary: state.resumeResponse.desiredSalary || '',
        desiredSalaryCurrency:
          state.resumeResponse.desiredSalaryCurrency || 'RUB',
      };

      const resResume = await editGuestVacancyResponseResume(
        user.access_token,
        state.vacancy.resume.id,
        resumeData
      );

      if (!resResume) {
        dispatch(showErrorMsg('Ошибка при сохранении резюме'));
        return;
      }

      setState((p) => ({
        ...p,
        vacancy: { ...p.vacancy, ...state.patchVacancy },
        resumeResponse: { ...p.resumeResponse },
      }));

      dispatch(showSuccessMsg('Сохранено'));
    } catch (error) {
      console.error('Error saving guest response:', error);
      dispatch(showErrorMsg('Ошибка при сохранении'));
    }
  };

  const deleteBtnHandler = async () => {
    if (
      window.confirm(
        `Вы действительно хотите удалить Отклик ${state.patchVacancy.name}?`
      )
    ) {
      const result = await deleteGuestResponse(user.access_token, id);
      if (!result) return dispatch(showErrorMsg('Ошибка'));
      navigate(-1);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getGuestResponse(user.access_token, id, {
        include: ['vacancy', 'resume'],
      });
      if (!response)
        return dispatch(showErrorMsg('Ошибка при загрузке вакансии'));
      const pdfLink = await getGuestResponsePdf(user.access_token, id);

      if (response === 404) {
        setState((p) => ({
          ...p,
          notFound: true,
        }));
      }

      setState((p) => ({
        ...p,
        loaded: true,
        vacancy: response,
        patchVacancy: {
          createdAt: response?.createdAt,
          moderationStatus: response?.moderationStatus,
          pdfLink: pdfLink,
        },
      }));
    })();
  }, [user.access_token]);

  useEffect(() => {
    if (state.vacancy.resume?.id) {
      (async () => {
        const response = await getGuestVacancyResponseResume(
          user.access_token,
          state.vacancy.resume.id,
          {
            include: ['response', 'contacts'],
          }
        );
        if (response === 404) {
          setState((p) => ({
            ...p,
            notFound: true,
          }));
        }
        setState((prevState) => ({
          ...prevState,
          loaded: true,
          resumeResponse: response,
        }));
      })();
    }
  }, [user.access_token, id, state.vacancy.resume?.id, dispatch]);

  if (state.notFound) {
    return <NotFoundPage />;
  }

  return !state.loaded ? (
    <Loader />
  ) : (
    <div className='row'>
      <div className='col-lg-4 d-flex flex-column gap-3 pb-3'>
        {/* <CompanyCard employer={state.vacancy.employer} canEdit={false} /> */}

        {/* <ul className={classes.VacancyParameters + ' p-4 p-xl-5'}>
            <li className='d-flex w-100 flex-row'>
              <p style={{ fontWeight: '600' }}>Локация: Казахстан</p>
              <ParamAdvSelect
                isSearchable={true}
                options={citiesAvailable}
                placeholder='указать'
                value={{
                  label: state.patchVacancy.city,
                  value: state.patchVacancy.city,
                }}
                onChange={(v) =>
                  setState((p) => ({
                    ...p,
                    patchVacancy: { ...p.patchVacancy, city: v.value },
                  }))
                }
              />
              <InputAutosize
                type='text'
                className={classes.ParameterInput}
                placeholder='указать'
                value={state.patchVacancy.city}
                onChange={(e) =>
                  setState((p) => ({
                    ...p,
                    patchVacancy: { ...p.patchVacancy, city: e.target.value },
                  }))
                }
                minWidth='1ch'
                defaultWidth='7ch'
              />
            </li>
          </ul> */}

        <div className='d-flex w-sm-max rounded flex-row bg-white py-2 px-3 roudned'>
          <p className='fw-bolded fs-5 me-3 my-auto'>Статус:&nbsp;</p>
          <select
            className={classes.ParameterSelect + ' p-1'}
            value={state.patchVacancy.moderationStatus || ''}
            style={{
              border: '1px solid ' + COLORS.gray_text,
              borderRadius: '5px',
            }}
            onChange={(e) =>
              setState((p) => ({
                ...p,
                patchVacancy: {
                  ...p.patchVacancy,
                  moderationStatus: e.target.value,
                },
              }))
            }
          >
            {Object.keys(labelsByGuestResponseStatus).map((opt) => (
              <option key={opt} value={opt}>
                {labelsByGuestResponseStatus[opt]}
              </option>
            ))}
          </select>
          <ArrowDown className='my-auto' style={{ marginLeft: '-20px' }} />
        </div>
        <button className='btn success' onClick={saveBtnHandler}>
          Сохранить вакансию
        </button>
        <button className='btn red_primary_outline' onClick={deleteBtnHandler}>
          Удалить вакансию
        </button>
      </div>
      {state.resumeResponse && (
        <div
          className='col order-lg-first  bg-white rounded-3 px-4 py-5 px-sm-5 d-flex flex-column mt-3 gap-4 gap-sm-5'
          style={{ height: 'fit-content' }}
        >
          <div className='col d-flex flex-column mb-2'>
            <span className='fs-4 fw-semibold'>
              {Object.keys(state.resumeResponse).length !== 0 && (
                <>
                  <InputAutosize
                    type='text'
                    className='fs-5 fw-semibold header_input me-2'
                    placeholder='Имя'
                    minWidth='1ch'
                    defaultWidth='13ch'
                    value={state.resumeResponse.firstName || ''}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        resumeResponse: {
                          ...prevState.resumeResponse,
                          firstName: e.target.value,
                        },
                      }));
                    }}
                  />
                  <InputAutosize
                    type='text'
                    className='fs-5 fw-semibold header_input'
                    placeholder='Фамилия'
                    minWidth='1ch'
                    defaultWidth='13ch'
                    value={state.resumeResponse.lastName || ''}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        resumeResponse: {
                          ...prevState.resumeResponse,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                  <hr
                    style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                  ></hr>
                </>
              )}
            </span>
            {/* Contacts */}
            {state.resumeResponse.contacts &&
              state.resumeResponse.contacts.length !== 0 && (
                <>
                  <span className='ufs-5 fs-5 fw-semibold mb-2'>Контакты:</span>
                  {state.resumeResponse.contacts?.map((contact, index) => (
                    <span key={`${contact.type}-${index}`} className='mb-2'>
                      {contact.type === 'PHONE'
                        ? 'Телефон:'
                        : contact.type === 'TELEGRAM'
                        ? 'Телеграм:'
                        : contact.type === 'GITHUB'
                        ? 'GitHub:'
                        : contact.type === 'EMAIL'
                        ? 'Почта:'
                        : contact.type === 'VK'
                        ? 'ВКонтакте:'
                        : contact.type === 'FACEBOOK'
                        ? 'FaceBook:'
                        : contact.type === 'LINKEDIN'
                        ? 'LinkedIn:'
                        : 'Другое:'}{' '}
                      {contact.value}
                    </span>
                  ))}
                  <hr
                    style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                  ></hr>
                </>
              )}
            {Object.keys(state.resumeResponse).length !== 0 && (
              <>
                <InputAutosize
                  type='text'
                  className='fs-5 fw-medium header_input'
                  placeholder='Специальность'
                  minWidth='1ch'
                  defaultWidth='13ch'
                  value={state.resumeResponse.title || ''}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      resumeResponse: {
                        ...prevState.resumeResponse,
                        title: e.target.value,
                      },
                    }));
                  }}
                />
                <hr
                  style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                ></hr>
              </>
            )}
            {/* Salary */}
            {Object.keys(state.resumeResponse).length !== 0 && (
              <>
                <div className='fw-bolded me-3 my-auto'>
                  <InputAutosize
                    type='number'
                    className='fs-5 fw-medium header_input me-2'
                    placeholder='Желаемая ЗП'
                    minWidth='1ch'
                    defaultWidth='13ch'
                    value={state.resumeResponse.desiredSalary || ''}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        resumeResponse: {
                          ...prevState.resumeResponse,
                          desiredSalary: e.target.value,
                        },
                      }));
                    }}
                  />
                  <select
                    className={classes.ParameterSelect + ' p-1 pe-4'}
                    value={
                      state.resumeResponse.desiredSalaryCurrency
                        ? state.resumeResponse.desiredSalaryCurrency
                        : setState((p) => ({
                            ...p,
                            resumeResponse: {
                              ...p.resumeResponse,
                              desiredSalaryCurrency: labelsBySalaryCurrency.RUB,
                            },
                          }))
                    }
                    style={{
                      border: '1px solid' + COLORS.gray_text,
                      borderRadius: '5px',
                    }}
                    onChange={(e) =>
                      setState((p) => ({
                        ...p,
                        resumeResponse: {
                          ...p.resumeResponse,
                          desiredSalaryCurrency: e.target.value
                            ? e.target.value
                            : labelsBySalaryCurrency.RUB,
                        },
                      }))
                    }
                  >
                    {Object.keys(labelsBySalaryCurrency).map((opt) => (
                      <option key={opt} value={opt}>
                        {labelsBySalaryCurrency[opt]}
                      </option>
                    ))}
                  </select>
                  <ArrowDown
                    className='my-auto'
                    style={{ marginLeft: '-20px' }}
                  />
                </div>
                <hr
                  style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                ></hr>
              </>
            )}
            {/* Ready to relocate */}
            {Object.keys(state.resumeResponse).length !== 0 && (
              <>
                <span className='fs-6 fw-semibold mb-2'>
                  Готов к релокации:
                </span>
                <div>
                  <select
                    className={classes.ParameterSelect + ' p-1 pe-4'}
                    value={state.resumeResponse.isReadyToRelocate || ''}
                    style={{
                      border: '1px solid' + COLORS.gray_text,
                      borderRadius: '5px',
                    }}
                    onChange={(e) =>
                      setState((p) => ({
                        ...p,
                        resumeResponse: {
                          ...p.resumeResponse,
                          isReadyToRelocate: e.target.value,
                        },
                      }))
                    }
                  >
                    {Object.keys(isReadyToRelocate).map((opt) => (
                      <option key={opt} value={opt}>
                        {isReadyToRelocate[opt]}
                      </option>
                    ))}
                  </select>
                  <ArrowDown
                    className='my-auto'
                    style={{ marginLeft: '-20px' }}
                  />
                </div>
                <hr
                  style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                ></hr>
              </>
            )}
            {/* is visible to employers */}
            {Object.keys(state.resumeResponse).length !== 0 && (
              <>
                <span className='fs-6 fw-semibold mb-2'>
                  Виден работадателю:
                </span>
                <div>
                  <select
                    className={classes.ParameterSelect + ' p-1 pe-4'}
                    value={state.resumeResponse.isVisibleToEmployers || ''}
                    style={{
                      border: '1px solid' + COLORS.gray_text,
                      borderRadius: '5px',
                    }}
                    onChange={(e) =>
                      setState((p) => ({
                        ...p,
                        resumeResponse: {
                          ...p.resumeResponse,
                          isVisibleToEmployers: e.target.value,
                        },
                      }))
                    }
                  >
                    {Object.keys(isReadyToRelocate).map((opt) => (
                      <option key={opt} value={opt}>
                        {isReadyToRelocate[opt]}
                      </option>
                    ))}
                  </select>
                  <ArrowDown
                    className='my-auto'
                    style={{ marginLeft: '-20px' }}
                  />
                </div>
                <hr
                  style={{ color: COLORS.hover_primary, borderWidth: '2px' }}
                ></hr>
              </>
            )}
            <p style={{ fontWeight: '600' }}>
              <a
                href={state.patchVacancy.pdfLink || undefined}
                target='_blank'
                rel='noreferrer'
              >
                Резюме:{' '}
                {state.patchVacancy.pdfLink ? 'ССЫЛКА' : 'нет ПДФ файла'}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const KeySkill = ({ s, i, setState }) => {
  return (
    <span className='delete-parent delete-not-mobile'>
      <button
        className='rounded-start p-2'
        style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
        onClick={() =>
          setState((p) => ({
            ...p,
            patchVacancy: {
              ...p.patchVacancy,
              keySkills: p.patchVacancy.keySkills.filter((s, idx) => idx !== i),
            },
          }))
        }
      >
        <DeleteOutline fill='black' />
      </button>
      <InputAutosize
        type='text'
        className='fw-semibold px-3 py-2 rounded-end no_outline'
        style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
        placeholder='Навык'
        minWidth='4ch'
        defaultWidth='5.25rem'
        value={s || ''}
        onChange={(e) => {
          setState((p) => ({
            ...p,
            patchVacancy: {
              ...p.patchVacancy,
              keySkills: p.patchVacancy.keySkills.map((s, idx) =>
                idx === i ? e.target.value : s
              ),
            },
          }));
        }}
      />
    </span>
  );
};

const About = ({ resume, dispatch, updateServerResume, token, setState }) => {
  return (
    <TextareaAutosize
      type='text'
      className='p-2 rounded-3'
      placeholder='Описание'
      minHeight='8rem'
      defaultHeight='1.5rem'
      style={{ resize: 'none', minWidth: '100px' }}
      value={resume.summary || ''}
      onChange={(e) => {
        setState((prevState) => ({
          ...prevState,
          vacancy: {
            ...prevState.vacancy,
            resume: {
              ...prevState.vacancy.resume,
              summary: e.target.value,
            },
          },
        }));
      }}
    />
  );
};

const Skill = ({ s, updateServerResume, token, resume, setState, i }) => {
  return (
    <span className='delete-parent delete-not-mobile'>
      <button
        className='rounded-start p-2'
        style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
        onClick={() =>
          deleteSkill(token, resume, i, setState, updateServerResume)
        }
      >
        <DeleteOutline fill='black' />
      </button>
      <InputAutosize
        type='text'
        className='fs-6 fw-medium px-3 py-2 rounded-end no_outline'
        style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
        placeholder='Навык'
        minWidth='4ch'
        defaultWidth='5.25rem'
        value={s || ''}
        onChange={(e) => {
          updateSkill(
            token,
            resume,
            i,
            e.target.value,
            setState,
            updateServerResume
          );
        }}
      />
    </span>
  );
};

// const ParamAdvSelect = ({ ...props }) => {
//   return (
//     <Select
//       theme={(t) => ({ ...t, colors: { ...t.colors, primary: 'transparent' } })}
//       styles={{
//         control: (styles) => ({
//           ...styles,
//           outline: 'none',
//           border: 'none',
//           fontSize: '1rem',
//           flexGrow: 1,
//           minHeight: 'fit-content',
//           minWidth: '150px',
//           cursor: 'pointer',
//         }),
//         input: (styles) => ({ ...styles, padding: 0, margin: 0 }),
//         valueContainer: (styles) => ({ ...styles, padding: 0 }),
//         placeholder: (styles) => ({
//           ...styles,
//           textDecoration: 'underline',
//           color: 'black',
//         }),
//         indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
//       }}
//       {...props}
//     />
//   );
// };
