import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCompany } from '../../../api/employer';
import { Loader } from '../../../components/ui/Loader';
import MyVacancies from './MyVacancies/MyVacancies';
import CompanyCard from './myCompanyCard/CompanyCard';
import { NotFoundPage } from '../../system/NotFoundPage';

export const Employer = () => {
  const user = useSelector((state) => state.userSlice.user);
  const { id } = useParams();
  const [state, setState] = useState({
    loaded: false,
    employer: {},
    notFound: false,
  });

  useEffect(() => {
    async function fetchData() {
      const employer = await getCompany(id, user.access_token, ['vacancies']);
      if (employer === 404) setState((p) => ({ ...p, notFound: true }));
      setState((p) => ({
        ...p,
        loaded: true,
        employer,
      }));
    }
    if (user.access_token !== undefined) fetchData();
  }, [id, user.access_token]);

  // const employer = employers.find(e => e.id === id)

  if (state.notFound) {
    return <NotFoundPage />;
  }

  return !state.loaded ? (
    <Loader />
  ) : (
    <div className='row gx-4 '>
      <div className='col-lg-5 col-xxl-4 mb-3 mb-lg-0'>
        <CompanyCard employer={state.employer} canEdit={true} />
      </div>
      <div className='col ps-sm-5'>
        <MyVacancies vacancies={state.employer.vacancies} short={true} />
      </div>
    </div>
  );
};
