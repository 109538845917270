import {useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { uuidRegexp } from '../../../../../constants'
import { showErrorMsg } from '../../../../../store/slices/alertSlice'
import { AppInput } from './../../../../../components/ui/AppInput'
import { makeVacancyResponseFromApplicant } from './utils'
import {UploadPDFResumeModal} from "../../../../../components/modals/UploadPDFResumeModal";

export const ActionsBlock = () => {
	const [relateModalShow, setRelateModalShow] = useState(false)
	const [uploadPDFModalShow, setUploadPDFModalShow] = useState(false)
	const [state, setState] = useState({ link: '' })
	const dispatch = useDispatch()
	const { id: candidateId } = useParams()
	const user = useSelector(state => state.userSlice.user)

	const relateToVacancy = async () => {
		const vacancyId = state.link.match(uuidRegexp)[0]
		if (!vacancyId) {
			dispatch(showErrorMsg('Не нашли id в вашей строке('))
		}
		const status = await makeVacancyResponseFromApplicant(
			user.access_token,
			vacancyId,
			candidateId,
			dispatch
		)
    
		if (status) {
			setRelateModalShow(false)
		}
	}

	return (
		<div className='bg-white rounded-3 w-100 px-4 py-4 d-flex flex-column gap-3'>
			<RelateCandidateModal
				show={relateModalShow}
				setShow={setRelateModalShow}
				state={state}
				setState={setState}
				relateToVacancy={relateToVacancy}
			/>
			<UploadPDFResumeModal
				show={uploadPDFModalShow}
				setShow={setUploadPDFModalShow}
				candidateId={candidateId}
			/>
			<button className='btn primary' onClick={() => setRelateModalShow(true)}>
				Привязать к вакансии
			</button>
			<button className='btn primary' onClick={() => setUploadPDFModalShow(true)}>
				Загрузить PDF-файл резюме
			</button>
		</div>
	)
}

const RelateCandidateModal = ({
	show,
	setShow,
	state,
	setState,
	relateToVacancy,
}) => {
	const handleClose = () => setShow(false)

	return (
		<Modal
			dialogClassName='rounded-4'
			centered
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title style={{ fontWeight: '600' }}>
					Привязка к вакансии
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex'>
					<AppInput
						label='Ссылка на вакансию'
						value={state.link}
						className='w-100'
						placeholder={'https://service.hurtle.ru/vacancies/...'}
						onChange={e => setState(p => ({ ...p, link: e }))}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className='btn primary' onClick={relateToVacancy}>
					Привязать
				</button>
			</Modal.Footer>
		</Modal>
	)
}
