import { Route, Routes, useNavigate } from 'react-router';
import { Stack } from 'react-bootstrap';
import { AuthScreen } from './screens/auth/AuthScreen';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { MainHeader } from './components/MainHeader';
import { MainScreen } from './screens/main/MainScreen';
import { useEffect } from 'react';
import { setUser } from './store/slices/userSlice';
import { Loader } from './components/ui/Loader';
import { COLORS, ErrorMsg, MAX_WIDTH, SuccessMsg } from './constants';
import { SpamPage } from './screens/system/SpamPage';

function App() {
  return (
    <Stack style={{ minHeight: '100vh', backgroundColor: COLORS.accountBg }}>
      <div className='d-flex flex-column px-3 px-lg-5'>
        <div className='w-100 mx-auto' style={{ maxWidth: MAX_WIDTH }}>
          <ErrorMsg />
          <SuccessMsg />
          <MainHeader />
          <Routes>
            <Route path='/*' element={<MainScreen />} />
            <Route path='auth' element={<AuthScreen />} />
            <Route path='logout' element={<Logout />} />
            <Route path='spam' element={<SpamPage />} />
          </Routes>
        </div>
      </div>
    </Stack>
  );
}

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove('access_token');
    setTimeout(() => {
      dispatch(
        setUser({
          loaded: false,
          access_token: undefined,
          login: '',
          name: '',
          password: '',
          access_level: 0,
        })
      );
      navigate('/auth');
    }, 1000);
  });

  return <Loader />;
};

export default App;
