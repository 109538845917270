import { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { getMe } from '../api/auth'
import { COLORS, ErrorMsg, PASTEL_COLORS, SOURCE } from '../constants'
import { showErrorMsg } from '../store/slices/alertSlice'
import { setUser } from '../store/slices/userSlice'
import { LogoutModal } from './modals/LogoutModal'

let i = Math.floor(Math.random() * PASTEL_COLORS.length)
export const MainHeader = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(state => state.userSlice.user)
	const [state, setState] = useState({
		logoutModalShown: false,
		errorMsg: '',
	})

	useEffect(() => {
		if (!user.access_token) return navigate('/auth')
		;(async () => {
			try {
				if (user.loaded && user.id) return
				const response = await getMe(user.access_token)
                
				if (response.status === 429) {
					dispatch(showErrorMsg('Слишком частые запросы'))
					navigate('/spam')
					return
				}

				if (!response.ok) throw new Error()


				const data = await response.json()
				if (data.error === 'Token is invalid') {
					navigate('/logout')
				}

				dispatch(
					setUser({
						...data,
						access_token: user.access_token,
						loaded: true,
					})
				)
			} catch (e) {
				console.log(e)
				navigate('/logout')
				return
			}
		})()
	}, [user.access_token, dispatch])

	return (
		<div className='pt-3 pb-2' style={{ transition: 'none', outline: 'none' }}>
			{state.errorMsg && <ErrorMsg state={state} setState={setState} />}
			<LogoutModal shown={state.logoutModalShown} setState={setState} />
			<Navbar collapseOnSelect expand='md' className='m-auto'>
				<Navbar.Brand className='me-5'>
					<img
						src={SOURCE.source_url + 'hurtle-logo.svg'}
						style={{ cursor: 'pointer', height: '35px' }}
						onClick={() => navigate('/')}
						alt=''
					/>
				</Navbar.Brand>
				<Navbar.Toggle
					aria-controls='responsive-navbar-nav'
					bsPrefix='no_outline'
				>
					<img
						className='d-block d-md-none'
						src={SOURCE.source_url + 'menu.svg'}
						style={{ height: '30px' }}
						alt=''
					/>
				</Navbar.Toggle>
				<Navbar.Collapse id='responsive-navbar-nav pe-4'>
					<Nav className='me-auto mt-md-0 mt-3'>
						{user.access_token !== undefined && <DefaultNavBar />}
					</Nav>
					<Nav>
						{user.access_token === undefined ? (
							<DefaultRightBar />
						) : (
							user.loaded && <RightAccountNav user={user} setState={setState} />
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

const DefaultNavBar = ({}) => {
	const loc = useLocation()
	const navigate = useNavigate()
	return (
		<>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/')}
				eventKey='1'
				style={{
					color: loc.pathname === '/' ? COLORS.primary : COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Встречи
			</Nav.Link>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/candidates')}
				eventKey='2'
				style={{
					color: loc.pathname.includes('/candidates')
						? COLORS.primary
						: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Кандидаты
			</Nav.Link>
			{/* <Nav.Link className="text-nowrap pe-4" onClick={() => navigate('/feedback')} eventKey="3"
            style={{ color: loc.pathname.includes('/feedback') ? COLORS.primary : COLORS.header_text, fontWeight: '600', fontSize: '18px', outline: 'none' }} >Оценка</Nav.Link> */}
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/employers')}
				eventKey='4'
				style={{
					color: loc.pathname.includes('/employers')
						? COLORS.primary
						: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Компании
			</Nav.Link>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/vacancies')}
				eventKey='5'
				style={{
					color: loc.pathname.includes('/vacancies')
						? COLORS.primary
						: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Вакансии
			</Nav.Link>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/guestResponses')}
				eventKey='5'
				style={{
					color: loc.pathname.includes('/guestResponses')
						? COLORS.primary
						: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Быстрые отклики
			</Nav.Link>
		</>
	)
}

const RightAccountNav = ({ user, setState }) => {
	const navigate = useNavigate()
	return (
		<div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start'>
			<div className='d-flex flex-column me-3 mt-1'>
				<p className='text-wrap text-end align-items-end d-none d-lg-block'>
					{user.name}
				</p>
				<Nav.Link
					style={{ color: COLORS.primary }}
					className='btn btn-md p-0 text-decoration-none align-self-end text-nowrap my-auto'
					eventKey='6'
					onClick={() => setState(p => ({ ...p, logoutModalShown: true }))}
				>
					Выйти
				</Nav.Link>
			</div>
			<div className='my-auto me-md-0 me-3'>
				<Nav.Link
					onClick={() => navigate('/account')}
					className='d-flex lh-lg align-items-center justify-content-center'
					eventKey='7'
					style={{
						fontSize: '18px',
						backgroundColor: PASTEL_COLORS[i],
						width: '50px',
						height: '50px',
						borderRadius: '15px',
						cursor: 'pointer',
						color: '#000',
					}}
				>
					{user.name[0]}
				</Nav.Link>
			</div>
		</div>
	)
}

const DefaultRightBar = () => {
	return (
		<div className='d-inline-flex flex-row flex-wrap-reverse align-items-center justify-content-start justify-content-md-end text-nowrap no_outline'>
			<Nav.Link
				onClick={() => window.location.replace('https://b2b.hurtle.ru/')}
				style={{ color: COLORS.primary, fontWeight: '500' }}
				eventKey='6'
				className='me-4 mb-2 text-center'
			>
				Я ищу сотрудников
			</Nav.Link>
			<Nav.Link
				onClick={() => window.location.replace('https://hurtle.ru/')}
				className='btn rounded-3 py-2 px-3 mb-2 red_primary'
				eventKey='7'
				style={{ fontWeight: '500', minWidth: '180px' }}
			>
				Найти работу
			</Nav.Link>
		</div>
	)
}
