// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_input {
    outline: none !important;
    border: 0 none !important;
    background: transparent;
    padding: 0;
    color: var(--header-text);
}

.delete-icon

.header_input:focus {
    color: var(--header-text);
}

.header_input::placeholder {
    color: var(--header-text);
}


@media screen and (max-width: 575px) {
    .delete-parent:not(.delete-not-mobile):hover .delete-moveable {
        margin-left: unset;
    }

    .delete-parent:not(.delete-not-mobile) .delete-btn {
        left: 2px !important;
        opacity: 100%;
        visibility: visible;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/screens/candidates/candidateScreen/components/ConstructorResumeBlock/styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,uBAAuB;IACvB,UAAU;IACV,yBAAyB;AAC7B;;AAEA;;;IAGI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,oBAAoB;QACpB,aAAa;QACb,mBAAmB;IACvB;AACJ;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".header_input {\n    outline: none !important;\n    border: 0 none !important;\n    background: transparent;\n    padding: 0;\n    color: var(--header-text);\n}\n\n.delete-icon\n\n.header_input:focus {\n    color: var(--header-text);\n}\n\n.header_input::placeholder {\n    color: var(--header-text);\n}\n\n\n@media screen and (max-width: 575px) {\n    .delete-parent:not(.delete-not-mobile):hover .delete-moveable {\n        margin-left: unset;\n    }\n\n    .delete-parent:not(.delete-not-mobile) .delete-btn {\n        left: 2px !important;\n        opacity: 100%;\n        visibility: visible;\n    }\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\ninput[type=number] {\n    -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
