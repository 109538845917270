import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { deleteSlotMeeting, getMyMeetingSlot } from '../../../api/meetingSlot'
import { getMyMeetings } from '../../../api/meetings'
import { CreateSlotModal } from '../../../components/modals/CreateSlotModal'
import { MeetingInfoModal } from '../../../components/modals/MeetingInfoModal'
import { Loader } from '../../../components/ui/Loader'
import {
	COLORS,
	MEETING_INCLUDE,
	MEETING_TYPES,
	datePlusTime,
	toTime,
} from '../../../constants'

import { MonthCalendar } from '../../../components/ui/MonthCalendar'
import { ReactComponent as BucketSvg } from '../../../icons/bucket.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'

const todayDate = new Date()
const currentMonth = todayDate.getMonth()
const currentYear = todayDate.getFullYear()
export const Main = () => {
	const user = useSelector(state => state.userSlice.user)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [state, setState] = useState({
		slots: {
			loaded: false,
			selectedDate: '',
			data: {},
		},
		meetings: {
			loaded: false,
			data: [],
		},
		meetingModal: {
			meeting: {},
			shown: false,
		},
		calendarDate: new Date(currentYear, currentMonth),
		createSlotModalShown: false,
	})

	const { FEEDBACK, SCRIPT, APPLICANT, EMPLOYER, SLOT } = MEETING_INCLUDE

	const freeSlots =
		state.slots.data[state.slots.selectedDate]?.filter(el => !el.meeting) || []

	// freeSlots.sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())

	async function navigateClickHandler(id) {
		navigate(`/meetings/` + id)
	}
	async function fetchSlots() {
		const beforeDateTime = new Date(state.calendarDate)
		beforeDateTime.setMonth(state.calendarDate.getMonth() + 1)
		let slots = await getMyMeetingSlot(user.access_token, {
			afterDateTime: state.calendarDate.toISOString(),
			beforeDateTime: beforeDateTime.toISOString(),
		})
		if (!slots) return dispatch(showErrorMsg('Ошибка при загрузке слотов'))
		const slotsRes = {}
		slots.items = slots.items.sort((a, b) => a.dateTime > b.dateTime)
		let selectedDate = (slots.items || [])[0]?.dateTime
		if (selectedDate) {
			selectedDate = new Date(selectedDate)
			selectedDate = [
				selectedDate.getDate().toString(),
				(selectedDate.getMonth() + 1).toString().padStart(2, 0),
				selectedDate.getFullYear(),
			].join('-')
		}
		slots.items.forEach(item => {
			let datetime = new Date(item.dateTime)
			const date = [
				datetime.getDate().toString(),
				(datetime.getMonth() + 1).toString().padStart(2, 0),
				datetime.getFullYear(),
			].join('-')

			slotsRes[date] = [...(slotsRes[date] || []), item].sort(
				(a, b) =>
					new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
			)
		})

		setState(p => ({
			...p,
			slots: {
				loaded: true,
				data: slotsRes,
				selectedDate: selectedDate || '',
			},
		}))
	}

	async function fetchMeetings() {
		const beforeDateTime = new Date(state.calendarDate)
		beforeDateTime.setMonth(state.calendarDate.getMonth() + 1)

		const meetings = await getMyMeetings(user.access_token, {
			include: [FEEDBACK, SCRIPT, APPLICANT, EMPLOYER, SLOT],
			size: '80',
			afterDateTime: state.calendarDate.toISOString(),
			beforeDateTime: beforeDateTime.toISOString(),
		})
		if (!meetings) return dispatch(showErrorMsg('Ошибка при загрузке встреч'))
		const meetingsRes = {}
		meetings.items.forEach(item => {
			let datetime = new Date(item.slot.dateTime)
			const date = [
				datetime.getDate().toString(),
				(datetime.getMonth() + 1).toString().padStart(2, 0),
				datetime.getFullYear(),
			].join('-')

			meetingsRes[date] = [...(meetingsRes[date] || []), item].sort(
				(a, b) =>
					new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
			)
		})
		setState(p => ({ ...p, meetings: { loaded: true, data: meetingsRes } }))
	}

	useEffect(() => {
		if (!state.slots.loaded) fetchSlots()
		if (!state.meetings.loaded) fetchMeetings()
	}, [
		state.createSlotModalShown,
		user.access_token,
		state.slots.loaded,
		state.meetings.loaded,
	])
	
	useEffect(() => {
		state.calendarDate && fetchMeetings()
		state.calendarDate && fetchSlots()
	}, [state.calendarDate])

	return !state.slots.loaded && !state.meetings.loaded ? (
		<Loader />
	) : (
		<div className='w-100'>
			<MeetingInfoModal
				state={state.meetingModal}
				setState={setState}
				user={user}
			/>
			<CreateSlotModal set={setState} shown={state.createSlotModalShown} />
			<p className='fs-2 mb-4' style={{ fontWeight: '600' }}>
				Мои слоты
			</p>

			<div className='row gap-4'>
				<div className='col-md-5 col-sm-6 '>
					<MonthCalendar
						selectDate={setState}
						calendarDate={state.calendarDate}
						selectedDate={state.slots.selectedDate}
						data={state.slots.data}
						meetings={state.meetings.data}
					/>
					<button
						className='btn px-3 py-2 primary mt-3 w-100'
						onClick={() =>
							setState(p => ({ ...p, createSlotModalShown: true }))
						}
					>
						Добавить
					</button>
				</div>
				<div className='col d-flex flex-column'>
					{state.slots.selectedDate !== '' && freeSlots.length > 0 ? (
						<div className='row gy-3'>
							{freeSlots.map((el, i) => (
								<Slot
									setState={setState}
									data={el}
									dispatch={dispatch}
									key={i}
									user={user}
								/>
							))}
						</div>
					) : (
						<p
							className='fs-5 text-center my-auto'
							style={{ color: COLORS.primary }}
						>
							Свободных слотов не создано
						</p>
					)}
				</div>
			</div>

			{!state.meetings.loaded ? (
				<Loader />
			) : (
				<>
					<p className='fs-2 mt-5'>Мои встречи {state.slots.selectedDate}</p>
					<div className='d-flex flex-column'>
						{state.meetings.data[state.slots.selectedDate]?.filter(
							m => m.status === 'PLANNED'
						).length > 0 ? (
							<div className='row mx-1 gap-2 mt-3'>
								{state.meetings.data[state.slots.selectedDate]
									.filter(m => m.status === 'PLANNED')
									.sort((a, b) => a.slot.dateTime > b.slot.dateTime)
									.map((el, i) => {
										return (
											<div
												className='col-auto p-3 rounded-3 light-light-primary'
												key={i}
												style={{
													border: '1px solid ' + COLORS.primary,
													cursor: 'pointer',
												}}
												onClick={() => navigateClickHandler(el.id)}
											>
												<p>
													{el.name + ', '}
													<span
														style={{ color: COLORS.primary, fontWeight: '600' }}
													>
														{toTime(el.slot.dateTime)}
													</span>
												</p>
											</div>
										)
									})}
							</div>
						) : (
							<p>В этот день ещё не было встреч</p>
						)}
					</div>
					{state.meetings.data[state.slots.selectedDate]?.filter(
						m => m.status !== 'PLANNED'
					).length > 0 && (
						<>
							<p className='fs-2 mt-5' style={{ fontWeight: '600' }}>
								Завершённые встречи
							</p>
							<div className='d-flex flex-column'>
								{
									<div className='row mx-1 gap-2 mt-3'>
										{state.meetings.data[state.slots.selectedDate]
											.filter(m => m.status !== 'PLANNED')
											.sort((a, b) => a.slot.dateTime > b.slot.dateTime)
											.map((el, i) => {
												return (
													<div
														className='col-auto p-3 rounded-3 light-light-primary'
														key={i}
														style={{
															border: '1px solid ' + COLORS.primary,
															cursor: 'pointer',
														}}
														onClick={() => navigateClickHandler(el.id)}
													>
														<p>
															{el.name + ', '}
															<span
																style={{
																	color: COLORS.primary,
																	fontWeight: '600',
																}}
															>
																{toTime(el.slot.dateTime)}
															</span>
														</p>
													</div>
												)
											})}
									</div>
								}
							</div>
						</>
					)}
				</>
			)}
		</div>
	)
}

const Slot = ({ data, setState, dispatch, user }) => {
	async function deleteSlotClickHandler() {
		const res = await deleteSlotMeeting(user.access_token, data.id)
		if (!res) return dispatch(showErrorMsg('Ошибка'))
		setState(p => ({ ...p, slots: { ...p.slots, loaded: false } }))
	}

	return (
		<div className='col-auto'>
			<div
				className='d-flex flex-row justify-content-between align-items-center rounded-3 p-md-3 p-2'
				style={{ border: '1px solid ' + COLORS.primary }}
			>
				<div className='d-flex flex-column me-2'>
					<p className='fs-5 fw-bolded'>{datePlusTime(data.dateTime) + '; '}</p>
					<p>{data.types.map(t => MEETING_TYPES[t]).join(', ')}</p>
				</div>
				<BucketSvg
					height='28px'
					width='28px'
					style={{ cursor: 'pointer', flexShrink: '0' }}
					alt=''
					onClick={deleteSlotClickHandler}
				/>
			</div>
		</div>
	)
}
