import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { deleteVacancy, getCount } from '../../../../api/vacancies'
import { COLORS } from '../../../../constants'
import { ReactComponent as TrashBinSvg } from '../../../../icons/delete-outline.svg'
import { ReactComponent as EditPenSvg } from '../../../../icons/edit-pen.svg'
import { ReactComponent as EyeStatsSvg } from '../../../../icons/eye_stats.svg'
import { ReactComponent as MoreActionsSvg } from '../../../../icons/more_actions.svg'
import { ReactComponent as PeopleStatsSvg } from '../../../../icons/people_stats.svg'
import { showErrorMsg } from '../../../../store/slices/alertSlice'
import classes from './MyVacancies.module.scss'

const MyVacancies = ({ vacancies }) => {
	const [short, setShort] = useState(true)
	const { id } = useParams()
	vacancies = vacancies || []
	const access_token = useSelector(state => state.userSlice.user.access_token)
	const dispatch = useDispatch()

	const [responses, setResponses] = useState([])

	useEffect(() => {
		;(async () => {
			const res = await getCount(id, access_token)
			if (!res) return dispatch(showErrorMsg('Ошибка'))
      console.log(res)
			setResponses(res)
		})()
	}, [access_token, dispatch, id])

	const addVacancyHandler = async () => {
		// const r = await dispatch(addVacancyAsync({
		//     vacancy: {
		//         city: "",
		//         experience: "NO_EXPERIENCE",
		//         name: "Новая вакансия",
		//         description: "",
		//         teamRole: "COLLABORATIVE_WORK",
		//         shortDescription: null,
		//         salary: 0,
		//         salaryCurrency: "RUB",
		//         employmentType: "FULL_TIME",
		//         reportingForm: "INDEPENDENT_WORK",
		//         workingHours: "GROUP_WORK",
		//         workplaceModel: "OFFICE",
		//         keySkills: ['']
		//     }
		// }));
		// const newVacancy = r.payload;
	}

	return (
		<div className={classes.Container}>
			<div
				className={`d-flex ${
					short ? 'flex-column' : 'flex-row justify-content-between'
				}`}
			>
				<div className='fs-3' style={{ fontWeight: '600' }}>
					Вакансии{' '}
					<span style={{ color: COLORS.gray_text }}>({vacancies.length})</span>
				</div>

				{/* <button className={classes.LinkText} onClick={addVacancyHandler}>
                    Создать вакансию
                </button> */}
			</div>

			{(vacancies || []).length === 0 && (
				<span className='fs-6 w-100 text-center' color={COLORS.gray_text}>
					У вас пока нет вакансий
				</span>
			)}

			<ul className={classes.ItemsContainer}>
				{(vacancies || [])
					.slice(0, short ? 3 : vacancies.length)
					.map((el, i) => (
						<Vacancy
							vacancy={el}
							count={
								responses.find(response => response.id === el.id)?._count
									?.responses
							}
							key={i}
						/>
					))}
			</ul>

			{short && (vacancies || []).length !== 0 && (
				<p className={classes.LinkTextBottom} onClick={() => setShort(!short)}>
					Больше
				</p>
			)}
		</div>
	)
}

const Vacancy = ({ vacancy, count }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [dropdownOpened, setDropdown] = useState(false)
	const user = useSelector(state => state.userSlice.user)
	const openDropdown = () => setDropdown(true)
	const closeDropdown = () => setDropdown(false)

	return (
		<li
			className={classes.Item}
			onClick={() => navigate(`/vacancies/${vacancy.id}`)}
			key={vacancy.id}
		>
			<div className='row bg-white' style={{ padding: '19px 22px' }}>
				<div className={classes.ItemText + ' col'}>{vacancy.name}</div>

				<div className={classes.IconsContainer + ' col-sm-4 col-auto'}>
					<div className={classes.NumberOfView + ' pe-sm-5 pe-3'}>
						<EyeStatsSvg className={classes.Icon} />
						{vacancy.uniqueViewerIps?.length || 0}
					</div>

					<div className={classes.NumberOfView}>
						<PeopleStatsSvg className={classes.Icon} />
						{count}
					</div>
				</div>

				<div
					className='position-relative col-auto'
					onClick={e => e.stopPropagation()}
				>
					<button
						className='no_outline'
						onMouseEnter={openDropdown}
						onClick={() => openDropdown()}
					>
						<MoreActionsSvg className={classes.Icon} />
					</button>
					<div
						className={`${classes.Dropdown} ${
							dropdownOpened ? classes.DropdownOpened : ''
						}`}
						onMouseLeave={closeDropdown}
					>
						<div
							className={classes.DropdownItem}
							onClick={() => navigate(`/vacancies/${vacancy.id}`)}
						>
							<EditPenSvg fill='#000' />
							<span className='fs-6 fw-semibold'>Редактировать</span>
						</div>
						<div
							className={classes.DropdownItem}
							onClick={async () =>
								window.confirm(`Удалить вакансию ${vacancy.name} ?`) &&
								(await deleteVacancy(user.access_token, vacancy.id))
							}
						>
							<TrashBinSvg />
							<span className='fs-6 fw-semibold'>Удалить</span>
						</div>
					</div>
				</div>
			</div>
		</li>
	)
}

export default MyVacancies
