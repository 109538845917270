import { COLORS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckSvg } from '../../icons/check.svg';
import { ReactComponent as CrossSvg } from '../../icons/cross.svg';
import { ReactComponent as BucketSvg } from '../../icons/bucket.svg';
import { useNavigate } from 'react-router';
import { deleteAccount } from '../../api/employer';
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice';
import { getAllEmployers } from '../../api/employer';
import { DataTable } from '../../components/table/Table';
import { useEffect, useState } from 'react';
import { Loader } from '../../components/ui/Loader';

export const Employers = () => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  );
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loaded: false,
    employers: [],
    notFound: false,
  });

  useEffect(() => {
    (async () => {
      if (!state.loaded) {
        const res = await getAllEmployers(access_token, {
          include: ['vacancies'],
        });
        if (res == 404) setState((p) => ({ ...p, notFound: true }));
        if (!res) return dispatch(showErrorMsg('Ошибка'));
        setState((p) => ({ ...p, loaded: true, employers: res }));
      }
    })();
  }, [state.employers, state.loaded]);

  return !state.loaded ? (
    <Loader />
  ) : (
    <div className='w-100'>
      <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
        Компании
      </p>
      <DataTable
        Template={Template}
        getMethod={getAllEmployers}
        Wrapper={Wrapper}
      />
    </div>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className='table-responsive' key='uniqueKey'>
      <table className='table table-hover align-middle' id='myTable'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>фио</th>
            <th scope='col'>почта</th>
            <th scope='col'>контакт</th>
            <th scope='col'>действия</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
const Template = ({ i, u, setState }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();

  async function deleteAccountClickHandler(e, account) {
    e.stopPropagation();
    if (
      !window.confirm(
        `Вы действительно хотите удалить аккаунт ${account.firstName} ${account.lastName}?`
      )
    ) {
      return;
    }

    const result = await deleteAccount(user.access_token, account.id);
    if (!result) return dispatch(showErrorMsg('Ошибка'));
    setState((p) => ({ ...p, loaded: false }));
    return dispatch(showSuccessMsg('Удалено'));
  }

  return (
    <tr key={i} onClick={() => navigate('/employers/' + u.id)}>
      <th scope='row'>{i + 1}</th>
      <td style={{ cursor: 'pointer' }}>
        {u.lastName +
          ' ' +
          (u.firstName || '')[0] +
          '. ' +
          (u.middleName ? u.middleName[0] + '.' : '')}
      </td>
      <td style={{ cursor: 'pointer' }}>
        {u.email.length > 23
          ? u.email.substring(0, 23) + '... '
          : u.email + ' '}
        {u.confirmed ? (
          <CheckSvg
            width='9px'
            cursor='pointer'
            title='подтверждён'
            fill={COLORS.success}
          />
        ) : (
          <CrossSvg
            width='9px'
            cursor='pointer'
            fill={COLORS.red_primary}
            title='не подтверждён'
          />
        )}
      </td>
      <td style={{ cursor: 'pointer' }}>
        {u.contact?.length > 23
          ? u.contact?.substring(0, 23) + '...'
          : u.contact}
      </td>
      <td>
        <BucketSvg
          style={{ cursor: 'pointer' }}
          height='26px'
          width='26px'
          onClick={(e) => deleteAccountClickHandler(e, u)}
        />
      </td>
    </tr>
  );
};
