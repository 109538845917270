import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { Loader } from '../../components/ui/Loader';
import { Main } from './layouts/Main';
import { VacanciesScreen } from '../vacancies/VacanciesScreen';
import { EmployersScreen } from '../business/EmployersScreen';
import { Meeting } from '../meetings/Meeting';
import { CandidatesScreen } from '../candidates/CandidatesScreen';
import { VacanciesResponseScreen } from './../guestVacancyResponse/guestVacancyResponse';
import { PromocodesScreen } from '../promocodes/PromocodesScreen';
import { NotFoundPage } from '../system/NotFoundPage';

export const MainScreen = () => {
  const user = useSelector((state) => state.userSlice.user);
  if (user.access_token === undefined && user.loaded) {
    return <Navigate to='/auth' />;
  }

  return !user.loaded ? (
    <Loader />
  ) : (
    <div className='w-100 mt-3 mb-5 mx-auto'>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='meetings/:id' element={<Meeting />} />
        <Route path='candidates/*' element={<CandidatesScreen />} />
        {/* <Route path="feedback" element={<FeedbackScreen />} /> */}
        <Route path='employers/*' element={<EmployersScreen />} />
        <Route path='vacancies/*' element={<VacanciesScreen />} />
        <Route path='promocodes' element={<PromocodesScreen />} />
        <Route path='guestResponses/*' element={<VacanciesResponseScreen />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
