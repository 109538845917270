import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteAccount, getAllApplicants } from '../../api/candidates';
import { getMeetings } from '../../api/meetings';
import { DataTable } from '../../components/table/Table';
import {
  COLORS,
  USER_ERROR_MESSAGES,
  USER_SUCCESS_MESSAGES,
  toDate,
  toTime,
} from '../../constants';

import { ReactComponent as BucketSvg } from '../../icons/bucket.svg';
import { ReactComponent as CheckSvg } from '../../icons/check.svg';
import { ReactComponent as CrossSvg } from '../../icons/cross.svg';
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice';
import { FilterApplicants, SearchApplicants } from './Grades';
import { clearSliceFilters } from '../../store/slices/tableSlice';

const todayDate = new Date();

export const Candidates = () => {
  const { search, hadMeetingGte, hadMeetingLte } = useSelector(
    (state) => state.tableSlice.filters
  );

  // Зпорос для даты
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const [meetings, setMeetings] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const meetingData = await getMeetings(user.access_token, {
          include: ['slot'],
        });
        if (!meetingData) throw new Error('Ошибка при загрузке встреч');
        setMeetings(meetingData);
      } catch (error) {
        dispatch(showErrorMsg(error.message));
      }
    })();
  }, [user.access_token, dispatch]);

  const clearFilters = () => {
    dispatch(clearSliceFilters({ search: search }));
  };

  return (
    <div className='w-100'>
      <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
        Кандидаты
      </p>
      <DataTable
        Template={Template}
        getMethod={getAllApplicants}
        Wrapper={Wrapper}
        SearchAndFilter={SearchAndFilter}
        filterObject={{ search, hadMeetingGte, hadMeetingLte }}
        clearFilters={clearFilters}
        meetings={meetings}
      />
    </div>
  );
};

const SearchAndFilter = ({ filters, setFilters, onFilter, clearFilters }) => {
  return (
    <div className='d-flex align-items-center gap-4'>
      <SearchApplicants state={filters} setState={setFilters} />
      <FilterApplicants state={filters} setState={setFilters} />
      <button className='btn btn-warning' onClick={clearFilters}>
        Очистить
      </button>
      <button className='btn btn-success' onClick={onFilter}>
        Применить
      </button>
    </div>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>фио</th>
            <th scope='col'>почта</th>
            <th scope='col'>контакт</th>
            <th scope='col' style={{ cursor: 'pointer' }}>
              дата собеседования
            </th>
            <th scope='col'>навыки</th>
            <th scope='col-auto'>действия</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const defaultState = {
  meeting: {
    loaded: false,
  },
};

const Template = ({ u, i, meetings, setState }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();

  async function deleteAccountClickHandler(e, account) {
    e.stopPropagation();
    if (
      !window.confirm(
        `Вы действительно хотите удалить аккаунт ${account.lastName} ${account.firstName}?`
      )
    )
      return;

    const res = await deleteAccount(account.id, user.access_token);
    if (res)
      return dispatch(showSuccessMsg(USER_SUCCESS_MESSAGES.ACCOUNT_DELETE));
    // setState(p => ({ ...p, loaded: false }))
    dispatch(showErrorMsg(USER_ERROR_MESSAGES.DELETE));
  }

  return (
    <tr key={i} onClick={() => navigate('/candidates/' + u.id)}>
      <th scope='row'>{i + 1}</th>
      <td style={{ cursor: 'pointer' }}>
        {u.lastName +
          ' ' +
          (u.firstName || '')[0] +
          '. ' +
          (u.middleName ? u.middleName[0] + '.' : '')}
      </td>

      <td style={{ cursor: 'pointer' }}>
        {u.email.length > 23
          ? u.email.substring(0, 23) + '... '
          : u.email + ' '}
        {u.confirmed ? (
          <CheckSvg
            width='9px'
            cursor='pointer'
            title='подтверждён'
            fill={COLORS.success}
          />
        ) : (
          <CrossSvg
            width='9px'
            cursor='pointer'
            fill={COLORS.red_primary}
            title='не подтверждён'
          />
        )}
      </td>

      <td style={{ cursor: 'pointer' }}>
        {u.contact?.length > 23
          ? u.contact?.substring(0, 23) + '...'
          : u.contact}
      </td>
      {/* {console.log('MEETINGS: ', meetings)} */}
      <td style={{ cursor: 'pointer' }}>
        {meetings && (
          <span>
            {meetings.items?.some((e) => e.applicantId === u.id)
              ? (() => {
                  const currentUserMeeting = meetings.items.find(
                    (e) => e.applicantId === u.id
                  );
                  const dateTime = toDate(currentUserMeeting.slot.dateTime);
                  const status = currentUserMeeting.status;

                  if (status === 'COMPLETED') {
                    return 'Записан';
                  } else if (status === 'CANCELED') {
                    return 'Отменена';
                  } else if (
                    new Date(currentUserMeeting.slot.dateTime).getTime() >
                    todayDate.getTime()
                  ) {
                    return `${dateTime}, ${toTime(
                      currentUserMeeting.slot.dateTime
                    )}`;
                  } else {
                    return 'Не прошел';
                  }
                })()
              : 'Встреч нет'}
          </span>
        )}
      </td>

      <td style={{ cursor: 'pointer' }}>
        {u.resume && u.resume.skills && u.resume.skills.length > 0 ? (
          <span>
            {u.resume.skills.slice(0, 3).map((skill, index) => (
              <span key={index}>
                {index > 0 && ', '}
                {skill}
              </span>
            ))}
            {u.resume.skills.length > 3 && '...'}
          </span>
        ) : (
          'Нет навыков'
        )}
      </td>
      <td>
        <div className=' d-flex '>
          <BucketSvg
            height='25px'
            width='25px'
            className='mx-auto'
            onClick={(e) => deleteAccountClickHandler(e, u)}
          />
        </div>
      </td>
    </tr>
  );
};
