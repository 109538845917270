import { SOURCE, ROLE, labelsBySalaryCurrency } from '../constants';

export async function getAllGuests(token, queryObj = {}, page, size) {
  let include = queryObj.include || [];
  let search = queryObj.search.search;
  const params = new URLSearchParams();

  include.forEach((el) => {
    params.append('include', el);
  });
  // Нет поиска на api
  // if (search) params.append("search", search);
  if (page) params.append('page', page);
  if (size) params.append('size', size);

  const response = await fetch(
    `${SOURCE.url}guestVacancyResponses/my?` + params,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getGuestVacancyResponseResume(token, id, queryObj = {}) {
  let query = '?';
  let include = queryObj.include || [];
  include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });
  const response = await fetch(
    `${SOURCE.url}guestVacancyResponseResumes/${id}` +
      (query === '?' ? '' : query),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }
  const json = await response.json();
  return json;
}

export async function editGuestVacancyResponseResume(
  token,
  id,
  guestResponseResume
) {
  const response = await fetch(
    `${SOURCE.url}guestVacancyResponseResumes/${id}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(guestResponseResume),
    }
  );

  if (!response.ok) {
    return false;
  }
  return true;
}

export async function getGuestResponse(token, id, queryObj = {}) {
  let query = '?';
  let include = queryObj.include || [];
  include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });
  const response = await fetch(
    `${SOURCE.url}guestVacancyResponses/${id}` + (query === '?' ? '' : query),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getGuestResponsePdf(token, id) {
  const response = await fetch(
    `${SOURCE.url}guestVacancyResponses/${id}/resumeFile`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }
  const link = URL.createObjectURL(await response.blob());
  return link;
}

export async function editGuestResponse(token, id, guestResponse) {
  const response = await fetch(`${SOURCE.url}guestVacancyResponses/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      _requester: ROLE.MANAGER,
      // status: response.moderationStatus,
      moderationStatus: guestResponse.moderationStatus,
    }),
  });

  if (!response.ok) {
    return false;
  }
  return true;
}

export async function deleteGuestResponse(token, id) {
  const response = await fetch(`${SOURCE.url}guestVacancyResponses/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  return response.ok;
}
