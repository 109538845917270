import styles from './styles.module.css'
import { FULL_MONTHS } from '../../../../../../constants'
export const DescriptionExperience = ({
    company_name,
    title_job,
    experience_start_year,
    experience_end_year,
    experience_start_month,
    experience_end_month,
    description_experience
}) => {
    console.log(experience_start_month)
    const experienceStart = `${FULL_MONTHS[+experience_start_month-1]}, ${experience_start_year}`
    const experienceEndYear = experience_end_year === null ? '' : experience_end_year
    const experienceEndMonth = experience_end_month === null ? '' : FULL_MONTHS[+experience_end_month-1]
    return (
        <div className={`row gy-1 ${styles.wrapper}`}>
            <p className={`col-12 col-sm-3 ${styles.experience}`}>
                {`${experienceStart} - `} {experienceEndMonth ? `${experienceEndMonth}, ` : ''} {experienceEndYear ? `${experienceEndYear}` : 'по н.в.'}
            </p>

            <div className="col-12 col-sm-9">
                <p className={styles.job}>{company_name}, {title_job}</p>
                <p className={styles.description}>{description_experience}</p>
            </div>
        </div>
    )
}