import {useDispatch, useSelector} from "react-redux";
import {DefaultAvatar} from "../../../components/ui/DefaultAvatar";
import {ReactComponent as EditPenSvg} from "../../../icons/edit-pen.svg";
import {COLORS, SOURCE, teamSizeLabels, teamSizeOptions, toDate} from "../../../constants";
import {AppTextarea} from "../../../components/ui/AppTextarea";
import Select from "react-select";
import {useEffect, useState} from "react";
import {AppSwitch} from "../../../components/ui/AppSwitch/AppSwitch";
import {AppInput} from "../../../components/ui/AppInput";
import {getCompany, patchEmployer, uploadEmployerAvatar} from "../../../api/employer";
import {useParams} from "react-router";
import {showErrorMsg, showSuccessMsg} from "../../../store/slices/alertSlice";
import {Loader} from "../../../components/ui/Loader";

export const EditEmployer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const access_token = useSelector(state => state.userSlice.user.access_token);
    const [state, setState] = useState({
        citiesAvailable: [],
        loaded: false,
        employer: {},
        patchUser: {}
    });

    const handleSaveData = async () => {
        const ok = await patchEmployer(access_token, state.employer.id, state.patchUser);
        if (ok) {
            setState(p => ({...p, employer: {...p.employer, ...state.patchUser}}));
            dispatch(showSuccessMsg("Сохранено"));
        } else dispatch(showErrorMsg("Ошибка"));
    }

    const handleUploadAvatar = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const ok = uploadEmployerAvatar(access_token, state.employer.id, formData);
        if (ok) dispatch(showSuccessMsg("Аватар загружен"));
        else dispatch(showErrorMsg("Ошибка"))
    }

    useEffect(() => {
        fetch("https://raw.githubusercontent.com/pensnarik/russian-cities/master/russian-cities.json")
            .then(r => r.json()
                .then(data => setState(p => ({...p, citiesAvailable: data.map(e => ({label: e.name, value: e.name}))}))))
    }, []);

    useEffect(() => {
        async function fetchData() {
            const employer = await getCompany(id, access_token, ["vacancies"])
            setState(p => ({
                ...p,
                loaded: true,
                employer,
                patchUser: {
                    description: employer.description,
                    size: employer.size || "LESS_THAN_15",
                    city: employer.city,
                    isStartup: employer.isStartup,
                    website: employer.website
                }
            }))
        }
        if (access_token !== undefined) fetchData()
    }, [id, access_token])

    return !state.loaded ? <Loader /> : <div className="w-100 d-flex justify-content-center">
        <div className="bg-white rounded-3 px-4 py-4 px-md-5 py-md-5 d-flex flex-column gap-4" style={{width: "min(100%, 650px)"}}>
            <div className="d-flex flex-row w-100 position-relative gap-4">
                <DefaultAvatar src={SOURCE.url + `employers/${state.employer.id}/avatar`} style={{width: "min(110px, 20vw)", height: "min(110px, 20vw)"}} />
                <button className="rounded-circle position-absolute bg-white d-flex justify-content-center align-items-center" style={{
                    width: "min(35px, 7vw)",
                    height: "min(35px, 7vw)",
                    top: "calc(min(110px, 20vw) - min(40px, 8vw) / 2)",
                    left: "calc(min(110px, 20vw) - min(40px, 8vw) / 2)",
                    boxShadow: "0 4px 4px 0 #00000008"
                }}>
                    <input type="file" accept="image/png,image/jpeg,image/webp"
                           className="position-absolute opacity-0 top-0 start-0"
                           style={{width: "min(35px, 7vw)", height: "min(35px, 7vw)"}} onInput={handleUploadAvatar}/>
                    <EditPenSvg style={{width: "60%", height:"60%"}} fill="#000" />
                </button>
                <div className="flex-grow-1 d-flex flex-column">
                    <span className="fs-3 fw-semibold">{state.employer.name}</span>
                    <span className="fs-6 fw-medium" style={{color: "#616161"}}>
                        договор №{state.employer.agreementNumber} от {toDate(state.employer.createdAt)}
                    </span>
                </div>
            </div>
            <div className="w-100">
                <span className="fs-5 fw-semibold">Описание</span>
                <AppTextarea className="mt-1" placeholder="Расскажите о вашей компании" value={state.patchUser.description} maxLength={512}
                             onChange={(e) => {setState(p => ({...p, patchUser: {...p.patchUser, description: e.target.value}}))}} />
            </div>
            <div className="w-100">
                <span className="fs-5 fw-semibold">Объем</span>
                <Select className="mt-1" options={teamSizeOptions} placeholder="Выберите объем"
                        theme={t => ({...t, colors: {...t.colors, primary: COLORS.primary}})} value={{value: state.patchUser.size, label: teamSizeLabels[state.patchUser.size]}}
                        onChange={(v) => {setState(p => ({...p, patchUser: {...p.patchUser, size: v.value}}))}}/>
            </div>
            <div className="w-100">
                <span className="fs-5 fw-semibold">Город</span>
                <Select className="mt-1" isSearchable={true} options={state.citiesAvailable} placeholder="Выберите город"
                        theme={t => ({...t, colors: {...t.colors, primary: COLORS.primary}})} value={{label: state.patchUser.city, value: state.patchUser.city}}
                        onChange={(v) => {setState(p => ({...p, patchUser: {...p.patchUser, city: v.value}}))}}/>
            </div>
            <div className="w-100 d-flex align-items-center">
                <AppSwitch value={state.patchUser.isStartup} onChange={(e) => {
                    setState(p => ({...p, patchUser: {...p.patchUser, isStartup: !p.patchUser.isStartup}}))}
                }/>
                <span className="fs-6 fw-semibold ms-2">Стартап</span>
            </div>
            <div className="w-100">
                <span className="fs-5 fw-semibold">Наш сайт</span>
                <AppInput inputClassName="py-2" placeholder="https://..." value={state.patchUser.website} className="mt-1"
                          onChange={(v) => {setState(p => ({...p, patchUser: {...p.patchUser, website: v}}))}}/>
            </div>
            <div className="w-100 d-flex flex-column flex-md-row gap-2 gap-md-4 justify-content-end">
                <button className="btn success fs-6 fs-medium px-4 py-2 col-12 col-md"
                        onClick={handleSaveData}>
                    Сохранить изменения
                </button>
                <button className="btn red_primary fs-6 fs-medium px-4 py-2 col-12 col-md"
                        onClick={() => setState(p => ({...p, patchUser: {description: "", size: "LESS_THAN_15", city: "", isStartup: false, website: ""}}))}>
                    Стереть все
                </button>
            </div>
        </div>
    </div>
}