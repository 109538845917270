import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { COLORS } from "../../constants";

export const LogoutModal = ({ shown, setState }) => {
    const navigate = useNavigate()

    function logoutClickHandler() {
        setState(p => ({ ...p, logoutModalShown: false })); 
        navigate('/logout')
    }

    function cancelClickHandler() {
        setState(p => ({ ...p, logoutModalShown: false }))
    }
    
    function hideHandler() {
        setState(p => ({ ...p, logoutModalShown: false }))
    }

    return (
        <Modal 
            dialogClassName="rounded-4" 
            onHide={hideHandler}
            centered 
            show={shown} 
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: '600' }}>Подтверждение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Вы точно хотите выйти?
            </Modal.Body>
            <Modal.Footer>
                <button 
                    className='btn btn-primary primary' 
                    onClick={cancelClickHandler}
                    style={{ backgroundColor: COLORS.primary }} 
                >
                    Нет, остаться!
                </button>
                <button 
                    className='btn btn-md text-nowrap w-100' 
                    onClick={logoutClickHandler}
                    style={{ backgroundColor: COLORS.footer, color: COLORS.primary, maxWidth: '100px' }}
                >
                    Выйти
                </button>
            </Modal.Footer>
        </Modal>
    )
}