import { SOURCE } from '../constants';

export async function getResume(id, token, queryObj = {}) {
  let query = '?';
  queryObj.include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });

  const response = await fetch(
    `${SOURCE.url}resumes/${id}${query === '?' ? '' : query}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  if (response.status === 404) return 404;

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const json = await response.json();
  return json;
}

export async function patchResume(access_token, id, updated) {
  try {
    const res = fetch(SOURCE.url + 'resumes/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResume(updated, access_token) {
  try {
    const res = await fetch(SOURCE.url + 'resumes', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResumeExperience(access_token, resume) {
  try {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + 'resumeExperience', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        company: '',
        position: '',
        startMonth: dateNow.getMonth() + 1,
        startYear: dateNow.getFullYear(),
        endMonth: dateNow.getMonth() + 1,
        endYear: dateNow.getFullYear(),
      }),
    });
    if (!res.ok) return false;
    return await res.json();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchResumeExperience(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeExperience/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteResumeExperience(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeExperience/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResumeEducation(access_token, resume) {
  try {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + 'resumeEducation', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        name: '',
        degree: '',
        startYear: dateNow.getFullYear(),
        endYear: dateNow.getFullYear(),
      }),
    });
    if (!res.ok) return false;
    return await res.json();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchResumeEducation(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeEducation/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteResumeEducation(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeEducation/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResumeCertificate(access_token, resume) {
  try {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + 'resumeCertificates', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        name: '',
        year: dateNow.getFullYear(),
      }),
    });
    if (!res.ok) return false;
    return await res.json();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchResumeCertificate(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeCertificates/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteResumeCertificate(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeCertificates/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResumeLanguage(access_token, resume) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        level: 'A1',
        resumeId: resume.id,
        name: '',
      }),
    });
    if (!res.ok) return false;
    return await res.json();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchResumeLanguage(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteResumeLanguage(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function createResumeContact(access_token, resume, type) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        type,
        resumeId: resume.id,
        name: null,
        value: '',
        preferred: true,
      }),
    });
    if (!res.ok) return false;
    return await res.json();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchResumeContact(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteResumeContact(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}
