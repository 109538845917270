import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { hideErrorMsg, hideSuccessMsg } from './store/slices/alertSlice'
export const MONTHS = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
]
export const FULL_MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
]
export const SHORT_MONTHS = [
	'янв.',
	'фев.',
	'мар.',
	'апр.',
	'мая',
	'июн.',
	'июл.',
	'авг.',
	'сент.',
	'окт.',
	'ноя.',
	'дек.',
]
export const PASTEL_COLORS = ['#FFFCB0', '#D4FFC0', '#D1F4FF', '#FDD0B7']

export const COLORS = {
	primary: '#6757F1', //'#7268CB',
	light_primary: '#F1F0FA',
	red_primary: '#F8485D',
	red_light_primary: '#FFF9FA',
	text_primary: '#5246B8',
	hover_primary: '#4C3EC9',
	danger: '#F8485D',
	footer: '#272727', // '#F8F8FD'
	secondary: '#FAFAFD',
	text: '#272727',
	gray_text: '#849095',
	second_text: '#6E6D75',
	header_text: '#849095',
	pastel_secondary: '#FFF9F9',
	pastel: '#FBFBFF',
	warning: '#FF9900',
	light_success: '#40C700',
	hr: '#C7C8C9',
	light_neutral: '#F1F1F1',
	neutral: '#E8E8EC',
	dark_neutral: '#9B9B9B',
	accountBg: '#F8F7FB',
	success: '#038900',
}
export const MAX_WIDTH = '1400px'
export const SOURCE = {
	url:
		process.env.REACT_APP_NODE_ENV === 'dev'
			? 'https://dev.hurtle.ru/api/v1/'
			: 'https://hurtle.ru/api/v1/',
	source_url: 'https://resource.hurtle.ru/',
}

export const useDebounceCallback = (func, delay) => {
	const [args, setArgs] = useState(null)
	useEffect(() => {
		if (args === null) return
		const timeout = setTimeout(() => func(...args), delay)
		return () => clearTimeout(timeout)
	}, [args]) // DO NOT add func and delay to deps
	return (...args) => setArgs(args)
}

export const ErrorMsg = () => {
    const dispatch = useDispatch()
    const message = useSelector(state => state.alertSlice.alert.errorMsg)
    const hideHandler = () => dispatch(hideErrorMsg());
    useEffect(() => {
        const timeout = setTimeout(hideHandler, 3000);
        return () => clearTimeout(timeout);
    }, [message]);
    return (
        <div className='position-fixed' style={{ maxWidth: '200px', top: '10px', right: '10px', zIndex: '999999'}}>
            <Alert show={Boolean(message)} variant="danger" onClose={() => dispatch(hideErrorMsg())}
                   dismissible>
                <Alert.Heading>Ошибка</Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
        </div>
    );
};

export const SuccessMsg = () => {
    const dispatch = useDispatch()
    const message = useSelector(state => state.alertSlice.alert.successMsg)
    const hideHandler = () => dispatch(hideSuccessMsg());
    useEffect(() => {
        const timeout = setTimeout(hideHandler, 3000);
        return () => clearTimeout(timeout);
    }, [message]);
    return (
        <div className='position-fixed' style={{maxWidth: '200px', top: '10px', right: '10px', zIndex: '999999'}}>
            <Alert show={Boolean(message)} variant="success"
                   onClose={() => dispatch(hideSuccessMsg())} dismissible>
                <Alert.Heading>Успешно!</Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
        </div>
    );
};

export const toTime = datetime => {
	return new Date(datetime).toLocaleTimeString().slice(0, 5)
}
export const toDate = datetime => {
	return new Date(datetime).toLocaleDateString()
}

export const datePlusTime = datetime => {
	let [date, time] = new Date(datetime).toLocaleString().split(', ')
	date = date.split('.')
	date[1] = MONTHS[parseInt(date[1] - 1)]
	return date.join(' ') + ', ' + time.substring(0, 5)
}
export const uuidRegexp = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/
export const searchApplicantRegexp = /^(?=(?:[^\p{L}\p{N}!@#\$%\^&\*()_+\-=\[\]{};':"\\|,.<>\/?]*[\p{L}\p{N}!@#\$%\^&\*()_+\-=\[\]{};':"\\|,.<>\/?]+[^\p{L}\p{N}!@#\$%\^&\*()_+\-=\[\]{};':"\\|,.<>\/?]*){1,7}$)[\p{L}\p{N}!@#\$%\^&\*()_+\-=\[\]{};':"\\|,.<>\/?\s]{1,100}$/u
export const Validation = {
	validName: name => /^[A-zА-яЁё]+$/i.test(name),
	validFio: fio =>
		fio.trim().split(' ').length >= 2 &&
		fio.trim().split(' ').length < 4 &&
		fio
			.trim()
			.split(' ')
			.filter(el => !Validation.validName(el)).length === 0,
	validMail: mail =>
		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			mail
		), // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
	validPhone: phone => phone !== '' && !phone.includes('_'),
	validPassword: pswd => pswd.length >= 6, // /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(pswd)
	validDateTime: date => date.length === 20,
	validDate: date => date.length === 10,
	validTelegram: tg => tg !== '',
	validMask: mask => !mask.includes('_') && mask !== '',
}

export const labelsByEmploymentType = {
	FULL_TIME: 'Полная занятость',
	PART_TIME: 'Частичная занятость',
	PROJECT: 'Проектная',
	VOLUNTEER: 'Волонтерство',
	PROBATION: 'Стажировка',
}

export const labelsByWorkplaceModel = {
	OFFICE: 'Офис',
	HYBRID: 'Гибрид',
	REMOTE: 'Удаленно',
}

export const labelsByExperience = {
	NO_EXPERIENCE: 'Нет опыта',
	BETWEEN_1_AND_3: '1-3 года',
	BETWEEN_3_AND_6: '3-6 лет',
	MORE_THAN_6: '6 и более лет',
}

export const labelsByTeamRole = {
	COLLABORATIVE_WORK: 'Координирование команды',
	TEAM_COORDINATION: 'Совместная работа',
	TEAM_MANAGEMENT: 'Управление командой',
	INDEPENDENT_WORK: 'Самостоятельная работа',
}

export const labelsByReportingForm = {
	GROUP_WORK: 'Групповая работа',
	PROJECT_MANAGER: 'Project-менеджер',
	DIRECTOR: 'Директор',
	INDEPENDENT_WORK: 'Независимая работа',
}

export const labelsByWorkingHours = {
	GROUP_WORK: 'Групповая работа',
	STRICT_SCHEDULE: 'Строгий график',
	FREELANCE: 'Фриланс',
	FIXED_HOURS: 'Фиксированные часы',
}

export const labelsBySalaryCurrency = {
	RUB: 'руб/мес.',
	USD: 'дол/мес.',
	EUR: 'евро/мес.',
	GBP: 'фунт/мес.',
	GEL: 'GEL (₾)',
	AZN: 'AZN (₼)',
	BYR: 'BYR (Br)',
	KGS: 'KGS (с)',
	KZT: 'KZT (₸)',
	UZS: 'UZS (S)',
}

export const labelsByVacancyStatus = {
	UNDER_REVIEW: 'На рассмотрении',
	FAILED_TO_PASS_REVIEW: 'Не прошла модерацию',
	PUBLISHED: 'Опубликована',
	HIDDEN: 'Скрыта',
}

export const labelsByGuestResponseStatus = {
	UNDER_REVIEW: 'На рассмотрении',
	FAILED_TO_PASS_REVIEW: 'Не прошла модерацию',
	PUBLISHED: 'Опубликована',
}

export const teamSizeLabels = {
	LESS_THAN_15: 'до 15 человек',
	BETWEEN_15_AND_50: 'до 50 человек',
	BETWEEN_50_AND_100: 'до 100 человек',
	BETWEEN_100_AND_500: 'до 500 человек',
	MORE_THAN_500: 'свыше 500 человек',
}

export const teamSizeOptions = [
	{ label: 'До 15 человек', value: 'LESS_THAN_15' },
	{ label: 'До 50 человек', value: 'BETWEEN_15_AND_50' },
	{ label: 'До 100 человек', value: 'BETWEEN_50_AND_100' },
	{ label: 'До 500 человек', value: 'BETWEEN_100_AND_500' },
	{ label: 'Свыше 500 человек', value: 'MORE_THAN_500' },
]

export const MEETING_TYPES = {
	CONSULTATION_B2C_EXPERT: 'Карьерная консультация',
	CONSULTATION_B2B: 'Консультация бизнес',
	CONSULTATION_B2C: 'Консультация',
	INTERVIEW: 'Собеседование',
}

export const ROLE = {
	MANAGER: 'MANAGER',
	EMPLOYER: 'EMPLOYER',
	APPLICANT: 'APPLICANT',
}

export const VALIDATE_ERROR = {
	LOGIN: 'Длина логина не меньше 4 символов',
	PASSWORD: 'Длина пароля не меньше 4 символов',
	NAME: 'Введите имя',
}

export const USER_ERROR_MESSAGES = {
	WRONG_LOG_OR_PAS: 'Неверный логин или пароль',
	USER_REGISTERED: 'Пользователь с таким логином уже зарегистрирован',
	UNKNOWN: 'Неизвестная ошибка',
	ERROR_SERVER: 'Произошла ошибка на сервере.',
	ERROR_SAVE: 'Произошла ошибка при сохранении',
	DELETE: 'Произошла ошибка при удалении',
	ERROR_SLOT: 'Произошла ошибка при создании слота',
	ERROR_SLOTS: 'Произошла ошибка при создании слотов',
}

export const USER_SUCCESS_MESSAGES = {
	ACCOUNT_DELETE: 'Аккаунт успешно удален',
	DELETE: 'Успешно удалено',
	SAVE: 'Сохранено',
	CREATE_SLOT: 'Слот создан!',
	CREATE_SLOTS: 'Слоты созданы!',
}

export const MEETING_INCLUDE = {
	FEEDBACK: 'feedback',
	SCRIPT: 'scriptProtocols',
	APPLICANT: 'applicant',
	EMPLOYER: 'employer',
	SLOT: 'slot',
}

export const MEETING_STATUS = {
	PLANNED: 'Запланировано',
	CANCELED: 'Отменено',
	COMPLETED: 'Завершено',
}

export const meetingStatusOptions =[
    {key: "PLANNED", value: "Запланировано"},
    {key: "COMPLETED", value: "Завершено"}
]

export const typeAttributeByContactType = type => {
	switch (type) {
		case 'EMAIL':
			return 'email'
		case 'PHONE':
			return 'tel'
		default:
			return 'text'
	}
}

export const PLACEHOLDER_CONTACT = {
	EMAIL: 'somebody@gmail.com',
	PHONE: '+7 (000) 000-00-00',
	TELEGRAM: '@nickname',
	GITHUB: 'https://github.com/user',
	VK: 'https://vk.com/id',
	FACEBOOK: 'https://www.facebook.com/',
	LINKEDIN: 'https://www.linkedin.com/',
	OTHER: 'контакт',
}

export const CONTACT_LABELS = {
	EMAIL: 'Почта',
	PHONE: 'Телефон',
	TELEGRAM: 'Telegram',
	GITHUB: 'GitHub',
	VK: 'VK',
	FACEBOOK: 'Facebook',
	LINKEDIN: 'LinkedIn',
	OTHER: 'Другое',
}

