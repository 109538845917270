import InputMask from 'react-input-mask';
import { usesetState, useSelector, useDispatch } from 'react-redux';
import { DefaultAvatar } from '../../../../../components/ui/DefaultAvatar';
import InputAutosize from '../../../../../components/ui/InputAutosize';
import TextareaAutosize from '../../../../../components/ui/TextareaAutosize';
import {
  COLORS,
  SOURCE,
  labelsBySalaryCurrency,
  useDebounceCallback,
} from '../../../../../constants';
import { ReactComponent as BaselinePlace } from '../../../../../icons/baseline-place.svg';
import { ReactComponent as DeleteOutline } from '../../../../../icons/delete-outline.svg';
import { ReactComponent as PlusSvg } from '../../../../../icons/plus_circle.svg';
import { ReactComponent as TwoCoins } from '../../../../../icons/two-coins.svg';
import { ContactMenuSelect } from '../ContactMenuSelect/ContactMenuSelect';
import { SaveResumeButton } from '../SaveResumeButton/SaveResumeButton';
import {
  createCertificate,
  createEducation,
  createExperience,
  createLanguage,
  createSkill,
  deleteCertificate,
  deleteEducation,
  deleteExperience,
  deleteLanguage,
  deleteSkill,
  editCertificate,
  editEducation,
  editExperience,
  editLanguage,
  updateResume,
  updateSkill,
  uploadAvatar,
} from './utils';
import './styles.css';
import {
  patchResume,
  patchResumeCertificate,
  patchResumeEducation,
  patchResumeExperience,
  patchResumeLanguage,
} from '../../../../../api/resume';
import { useEffect, useState } from 'react'

export default function ConstructorResumeBlock({
  applicant,
  resume,
  setState,
}) {
  const dispatch = useDispatch();

  const [resumeCopy, setResumeCopy] = useState(resume)

  useEffect(()=> {
    setResumeCopy(resume)
    console.log('123')
  }, [resume])

  const updateServerResume = useDebounceCallback(patchResume, 500);
  const updateServerExperience = useDebounceCallback(
    patchResumeExperience,
    500
  );
  const updateServerEducation = useDebounceCallback(patchResumeEducation, 500);
  const updateServerCertificate = useDebounceCallback(
    patchResumeCertificate,
    500
  );
  const updateServerLanguage = useDebounceCallback(patchResumeLanguage, 500);

  const token = useSelector((state) => state.userSlice.user.access_token);
  
  return (
    <>
      <div className='row gap-2'>
        {/* Personal Details Block */}
        <div className='w-auto position-relative mb-2'>
          <DefaultAvatar
            src={SOURCE.url + `applicants/${applicant.id}/avatar`}
            alt='avatar'
            className='rounded img-fluid'
            style={{
              objectFit: 'cover',
              width: 'min(110px,20vw)',
              height: 'min(110px,20vw)',
              boxShadow: '0px 0px 12px 0px rgba(103, 87, 241, 0.30)',
            }}
          />
          <input
            type='file'
            accept='image/png,image/jpeg,image/webp'
            className='position-absolute opacity-0 top-0 start-0'
            style={{ cursor: 'pointer', width: '110px', height: '110px' }}
            onInput={(e) => {
              uploadAvatar(token, applicant.id, e.target.files[0], dispatch);
            }}
          />
        </div>
        <div className='col d-flex flex-column mb-2'>
          <span className='fs-4 fw-semibold'>
            {applicant.firstName} {applicant.lastName}
          </span>
          <span
            className='user-select-all mb-2'
            style={{ cursor: 'pointer', color: COLORS.primary }}
          >
            {applicant.contact}
          </span>
          {Object.keys(resume).length !== 0 && (
            <>
              <InputAutosize
                type='text'
                className='fs-5 fw-semibold header_input'
                placeholder='Специальность'
                minWidth='1ch'
                defaultWidth='13ch'
                value={resume.title}
                onChange={(e) => {
                  updateResume(
                    token,
                    resume,
                    { title: e.target.value },
                    setState,
                    updateServerResume
                  );
                }}
              />
              <ExtraInfo
                resume={resume}
                display='d-sm-flex d-none mt-2'
                updateServerResume={updateServerResume}
                setState={setState}
                token={token}
              />
            </>
          )}
        </div>
        {Object.keys(resume).length !== 0 && (
          <div
            className='d-flex flex-row justify-content-end'
            style={{ width: 'fit-content' }}
          >
            <ContactMenuSelect
              applicant={applicant}
              resume={resume}
              setState={setState}
            />
          </div>
        )}
      </div>
      {Object.keys(resume).length !== 0 && (
        <>
          <ExtraInfo
            resume={resume}
            display='d-sm-none d-flex'
            updateServerResume={updateServerResume}
            setState={setState}
            token={token}
          />
        </>
      )}
      {/* About Block */}
      {
        <div>
          <span className='fs-4 fw-semibold'>
            O себе
            <span></span>
          </span>
          <div className='row g-2 g-md-3 mt-1'>
            {
              <About
                token={token}
                resume={resumeCopy}
                updateServerResume={updateServerResume}
                dispatch={dispatch}
                updateResumeCopy={setResumeCopy}
              />
            }
          </div>
        </div>
      }
      {/* Skills Block */}
      {(resume.skills || []).length ? (
        <div className='row'>
          <span className='fs-4 fw-semibold mb-3'>
            Навыки
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createSkill(token, resume, setState, updateServerResume)
                }
              />
            </span>
          </span>
          <div className='d-flex flex-row flex-wrap gap-3 '>
            {(resume.skills || []).map((s, i) => (
              <Skill
                key={i}
                i={i}
                s={s}
                setState={setState}
                token={token}
                resume={resume}
                updateServerResume={updateServerResume}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      {/* Experience Block */}
      {(resume.experience || []).length ? (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Опыт работы
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() => createExperience(token, resume, setState)}
              />
            </span>
          </span>
          {(resume.experience || []).map((exp) => (
            <Experience
              key={exp.id}
              exp={exp}
              setState={setState}
              token={token}
              resume={resume}
              updateServerExperience={updateServerExperience}
            />
          ))}
        </div>
      ) : (
        ''
      )}
      {/* Education Block */}
      {(resume.education || []).length ? (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Образование
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() => createEducation(token, resume, setState)}
              />
            </span>
          </span>
          {(resume.education || []).map((edu) => (
            <Education
              key={edu.id}
              edu={edu}
              setState={setState}
              token={token}
              resume={resume}
              updateServerEducation={updateServerEducation}
            />
          ))}
        </div>
      ) : (
        ''
      )}
      {/* Certificates Block */}
      {(resume.certificates || []).length ? (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Курсы и сертификаты
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() => createCertificate(token, resume, setState)}
              />
            </span>
          </span>
          {(resume.certificates || []).map((cert) => (
            <Certificate
              key={cert.id}
              cert={cert}
              token={token}
              resume={resume}
              setState={setState}
              updateServerCertificate={updateServerCertificate}
            />
          ))}
        </div>
      ) : (
        ''
      )}
      {/* Languages Block */}
      {(resume.languages || []).length ? (
        <div className='row'>
          <span className='fs-4 mb-3 fw-semibold'>
            Языки
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() => createLanguage(token, resume, setState)}
              />
            </span>
          </span>
          <div className='d-flex flex-row flex-wrap gap-3'>
            {(resume.languages || []).map((lang, i) => (
              <Language
                key={lang.id}
                token={token}
                resume={resume}
                lang={lang}
                updateServerLanguage={updateServerLanguage}
                setState={setState}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      {/* Resume controls */}
      {Object.keys(resume).length !== 0 && (
        <div className='row gap-2 gap-sm-4'>
          <SaveResumeButton resume={resume} applicant={applicant} />
        </div>
      )}
    </>
  );
}

const ExtraInfo = ({
  display,
  resume,
  setState,
  updateServerResume,
  token,
}) => {
  return (
    <div
      className={'flex-column flex-sm-row flex-wrap gap-2 gap-sm-4 ' + display}
    >
      <div className='d-flex flex-row gap-2'>
        <BaselinePlace />
        <InputAutosize
          type='text'
          className='fs-6 fw-semibold header_input'
          placeholder='Город'
          minWidth='1ch'
          defaultWidth='5ch'
          value={resume.city}
          onChange={(e) => {
            updateResume(
              token,
              resume,
              { city: e.target.value },
              setState,
              updateServerResume
            );
          }}
        />
      </div>
      <div className='d-flex flex-row gap-2'>
        <TwoCoins />
        <InputAutosize
          type='text'
          className='fs-6 fw-semibold header_input'
          placeholder='з/п'
          minWidth='1ch'
          defaultWidth='2.5ch'
          value={resume.desiredSalary}
          onChange={(e) => {
            const val = +e.target.value.replace(/\D/g, '') || null;
            updateResume(
              token,
              resume,
              { desiredSalary: val, desiredSalaryCurrency: 'RUB' },
              setState,
              updateServerResume
            );
          }}
        />
        <span
          className='fs-6 fw-semibold'
          style={{ color: COLORS.header_text }}
        >
          {labelsBySalaryCurrency[resume.desiredSalaryCurrency]}
        </span>
      </div>
    </div>
  );
};

const About = ({ resume, dispatch, updateServerResume, token, updateResumeCopy }) => {
  return (
    <TextareaAutosize
      type='text'
      className='p-2 rounded-3'
      placeholder='Описание'
      minHeight='8rem'
      defaultHeight='1.5rem'
      style={{ resize: 'none', minWidth: '100px' }}
      value={resume.summary}
      onChange={(e) => {
        updateResumeCopy(p => ({...p, summary: e.target.value}))
        updateResume(
          token,
          resume,
          { summary: e.target.value },
          dispatch,
          updateServerResume
        );
      }}
    />
  );
};

const Skill = ({ s, updateServerResume, token, resume, setState, i }) => {
  return (
    <span className='delete-parent delete-not-mobile'>
      <button
        className='rounded-start p-2'
        style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
        onClick={() =>
          deleteSkill(token, resume, i, setState, updateServerResume)
        }
      >
        <DeleteOutline fill='black' />
      </button>
      <InputAutosize
        type='text'
        className='fs-6 fw-medium px-3 py-2 rounded-end no_outline'
        style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
        placeholder='Навык'
        minWidth='4ch'
        defaultWidth='5.25rem'
        value={s}
        onChange={(e) => {
          updateSkill(
            token,
            resume,
            i,
            e.target.value,
            setState,
            updateServerResume
          );
        }}
      />
    </span>
  );
};

const DeleteButton = ({ del, id, obj, setState }) => {
  const token = useSelector((state) => state.userSlice.user.access_token);
  return (
    <div className='col-auto pe-0'>
      <button
        className='rounded-1 p-1'
        style={{ top: '-2px', backgroundColor: COLORS.light_neutral }}
        onClick={() => del(token, id, obj, setState)}
      >
        <DeleteOutline />
      </button>
    </div>
  );
};

const Experience = ({
  exp,
  token,
  resume,
  setState,
  updateServerExperience,
}) => {
  return (
    <div className='row delete-parent'>
      <DeleteButton
        del={deleteExperience}
        id={exp.id}
        obj={resume.experience}
        setState={setState}
      />
      {/* <div className="col-auto pe-0">
        <button className=" p-1" style={{top: "-2px", backgroundColor: COLORS.light_neutral}} 
                onClick={() =>  deleteExperience(user.access_token, exp.id, resume.experience, setState)}>
            <DeleteOutline />
        </button>
    </div> */}
      <div className='col col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
        <input
          className='fs-6 fw-medium mb-1 no_outline '
          style={{ color: COLORS.primary }}
          value={exp.company}
          onChange={(ev) =>
            editExperience(
              token,
              exp.id,
              { company: ev.target.value },
              resume.experience,
              setState,
              updateServerExperience
            )
          }
          placeholder='Компания'
        />
        <input
          className='fs-6 fw-medium no_outline'
          value={exp.position}
          onChange={(ev) =>
            editExperience(
              token,
              exp.id,
              { position: ev.target.value },
              resume.experience,
              setState,
              updateServerExperience
            )
          }
          placeholder='Позиция'
        />
        <span>
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '3.5rem' }}
            mask='99.9999'
            placeholder='мм.гггг'
            value={(exp.startMonth || '') + '.' + (exp.startYear || '')}
            onChange={(ev) => {
              let value = ev.target.value;
              if (value[0] !== '1' && value[0] !== '0') {
                value = '0'.concat(value.slice(1));
              }
              editExperience(
                token,
                exp.id,
                {
                  startMonth: (value.split('.')[0] || '').replaceAll('_', ''),
                  startYear: (value.split('.')[1] || '').replaceAll('_', ''),
                },
                resume.experience,
                setState,
                updateServerExperience
              );
            }}
          />
          &nbsp;-&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '3.75rem' }}
            mask='99.9999'
            placeholder='мм.гггг'
            value={(exp.endMonth || '') + '.' + (exp.endYear || '')}
            onChange={(ev) => {
              let value = ev.target.value;
              if (value[0] !== '1' && value[0] !== '0') {
                value = '0'.concat(value.slice(1));
              }
              editExperience(
                token,
                exp.id,
                {
                  endMonth:
                    (value.split('.')[0] || '').replaceAll('_', '') || null,
                  endYear:
                    (value.split('.')[1] || '').replaceAll('_', '') || null,
                },
                resume.experience,
                setState,
                updateServerExperience
              );
            }}
          />
        </span>
      </div>
      <div className='col'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={exp.description}
          onChange={(ev) =>
            editExperience(
              token,
              exp.id,
              { description: ev.target.value },
              resume.experience,
              setState,
              updateServerExperience
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  );
};

const Education = ({ token, resume, setState, updateServerEducation, edu }) => {
  return (
    <div className='row delete-parent flex-wrap'>
      <DeleteButton
        del={deleteEducation}
        id={edu.id}
        obj={resume.education}
        setState={setState}
      />
      <div className='col col-md-6 d-flex flex-column'>
        <input
          className='fs-6 fw-medium no_outline delete-moveable'
          style={{ color: COLORS.primary }}
          value={edu.name}
          onChange={(ev) =>
            editEducation(
              token,
              edu.id,
              { name: ev.target.value },
              resume.education,
              setState,
              updateServerEducation
            )
          }
          placeholder='Учебное заведение'
        />
        <span className='fs-6 fw-medium'>
          <InputAutosize
            className='fs-6 fw-medium no_outline'
            value={edu.degree}
            onChange={(ev) =>
              editEducation(
                token,
                edu.id,
                { degree: ev.target.value },
                resume.education,
                setState,
                updateServerEducation
              )
            }
            placeholder='Степень'
            minWidth='1ch'
            defaultWidth='7ch'
          />
          ,&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '2.5rem' }}
            mask='9999'
            placeholder='гггг'
            value={edu.startYear}
            onChange={(ev) =>
              editEducation(
                token,
                edu.id,
                { startYear: (ev.target.value || '').replaceAll('_', '') },
                resume.education,
                setState,
                updateServerEducation
              )
            }
          />
          -&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '2.5rem' }}
            mask='9999'
            placeholder='гггг'
            value={edu.endYear}
            onChange={(ev) =>
              editEducation(
                token,
                edu.id,
                {
                  endYear: (ev.target.value || '').replaceAll('_', '') || null,
                },
                resume.education,
                setState,
                updateServerEducation
              )
            }
          />
        </span>
      </div>
      <div className='col text-nowrap'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={edu.description}
          onChange={(ev) =>
            editEducation(
              token,
              edu.id,
              { description: ev.target.value },
              resume.education,
              setState,
              updateServerEducation
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  );
};

const Certificate = ({
  cert,
  updateServerCertificate,
  token,
  resume,
  setState,
}) => {
  return (
    <div className='row delete-parent'>
      <DeleteButton
        del={deleteCertificate}
        id={cert.id}
        obj={resume.certificates}
        setState={setState}
      />
      {/* <button className="delete-btn p-0" style={{top: "-2px", left: "20px"}}
            onClick={() =>  deleteCertificate(user.access_token, cert.id, resume.certificates, setState)}>
        <DeleteOutline />
    </button> */}
      <div className='col d-flex flex-column col-sm-5 col-md-4 col-lg-5 col-xl-4'>
        <input
          className='fs-6 fw-medium no_outline delete-moveable'
          style={{ color: COLORS.primary }}
          value={cert.name}
          onChange={(ev) =>
            editCertificate(
              token,
              cert.id,
              { name: ev.target.value },
              resume.certificates,
              setState,
              updateServerCertificate
            )
          }
          placeholder='Название учреждения'
        />
        <InputMask
          className='fs-6 fw-medium no_outline m-0 p-0'
          style={{ width: '2.85rem' }}
          mask='9999'
          placeholder='гггг'
          value={cert.year}
          onChange={(ev) =>
            editCertificate(
              token,
              cert.id,
              { year: (ev.target.value || '').replaceAll('_', '') },
              resume.certificates,
              setState,
              updateServerCertificate
            )
          }
        />
      </div>
      <div className='col'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={cert.description}
          onChange={(ev) =>
            editCertificate(
              token,
              cert.id,
              { description: ev.target.value },
              resume.certificates,
              setState,
              updateServerCertificate
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  );
};

const Language = ({ lang, token, resume, updateServerLanguage, setState }) => {
  return (
    <div className='d-flex flex-row delete-parent delete-not-mobile'>
      <button
        className='rounded-start'
        style={{
          top: '-3px',
          backgroundColor: COLORS.light_neutral,
          marginRight: '1px',
        }}
        onClick={() =>
          deleteLanguage(token, lang.id, resume.languages, setState)
        }
      >
        <DeleteOutline />
      </button>
      <span
        className='fs-6 fw-medium px-3 py-2 rounded-end delete-moveable'
        style={{ backgroundColor: COLORS.light_neutral }}
      >
        <InputAutosize
          className='fs-6 fw-medium no_outline'
          value={lang.name}
          onChange={(ev) =>
            editLanguage(
              token,
              lang.id,
              { name: ev.target.value },
              resume.languages,
              setState,
              updateServerLanguage
            )
          }
          placeholder='Язык'
          minWidth='1ch'
          defaultWidth='4ch'
        />
        &nbsp;-&nbsp;
        <InputAutosize
          type='text'
          className='fs-6 fw-medium no_outline'
          style={{ color: COLORS.primary }}
          value={lang.level}
          minWidth='1ch'
          defaultWidth='2.5ch'
          onChange={(ev) =>
            editLanguage(
              token,
              lang.id,
              { level: ev.target.value },
              resume.languages,
              setState,
              updateServerLanguage
            )
          }
          placeholder='A1'
        />
      </span>
    </div>
  );
};
