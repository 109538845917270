import { COLORS, datePlusTime } from '../../../constants';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import ConstructorResumeBlock from './components/ConstructorResumeBlock/ConstructorResumeBlock';
import ConstructorSectionsBlock from './components/ConstructorSectionsBlock/ConstructorSectionsBlock';
import { ActionsBlock } from './components/Actions/ActionsBlock';

export const Candidate = ({ applicant, resume, meetings, setState }) => {
  const user = useSelector((state) => state.userSlice.user);

  if (applicant)
    return (
      <div className='w-100'>
        <div className='row w-100 m-0 gap-5'>
          <div className='col-lg-3 d-flex flex-column gap-4 p-0'>
            <ConstructorSectionsBlock
              applicant={applicant}
              resume={resume || {}}
              setState={setState}
            />
            <ActionsBlock />
          </div>
          <div
            className='col order-lg-first  bg-white rounded-3 px-4 py-5 px-sm-5 d-flex flex-column gap-4 gap-sm-5'
            style={{ height: 'fit-content' }}
          >
            <ConstructorResumeBlock
              applicant={applicant}
              resume={resume || {}}
              setState={setState}
            />
          </div>
        </div>
        <p className='mt-5 mb-4 fs-3' style={{ fontWeight: '600' }}>
          Встречи
        </p>
        {meetings.length === 0 ? (
          <p className='fs-5'>Пока не было встреч</p>
        ) : (
          <div className='bg-white rounded p-sm-4 p-3'>
            <div className='row mx-0 gap-3'>
              {meetings.map((m, i) => (
                <Meeting key={i} data={m} access_token={user.access_token} />
              ))}
            </div>
          </div>
        )}
        <div></div>
      </div>
    );
};

const Meeting = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      className='col-lg-3 rounded-3 py-3 px-4 light_light_primary'
      style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer' }}
      onClick={() => navigate('/meetings/' + data.id)}
    >
      <p className='fs-6 mb-2' style={{ fontWeight: '600' }}>
        {data.name}{' '}
        <span
          style={{ color: data.is_active ? COLORS.warning : COLORS.primary }}
        >
          {data.is_active ? '(предстоит)' : '(проведена)'}
        </span>
      </p>
      <p
        className='fs-6 mb-2'
        style={{ fontWeight: '600', color: COLORS.primary }}
      >
        {datePlusTime(data.updatedAt)}
      </p>
      <p className='fs-6'>
        Менеджер{' '}
        <span style={{ fontWeight: '600', color: COLORS.primary }}>
          {data.manager_id}
        </span>
      </p>
    </div>
  );
};
