
import {SOURCE} from "../../../../../constants";
import { showErrorMsg, showSuccessMsg } from "../../../../../store/slices/alertSlice";
import {
    createResumeCertificate,
    createResumeEducation,
    createResumeExperience, createResumeLanguage, deleteResumeCertificate,
    deleteResumeEducation,
    deleteResumeExperience, deleteResumeLanguage
} from "../../../../../api/resume";
import {uploadFile} from "../../../../../api/candidates";

export const createSkill = (access_token, resume, setState, updateServerResume) => {
    const updatedSkills = [...resume.skills, ""];
    setState(p => ({...p, resume: {...p.resume, skills: updatedSkills}}));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});
}

export const updateSkill = (access_token, resume, skillId, newSkill, setState, updateServerResume) => {
    const updatedSkills = resume.skills.map((s, i) => i === skillId ? newSkill : s);
    setState(p => ({...p, resume: {...p.resume, skills: updatedSkills}}));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});
}

export const deleteSkill = (access_token, resume, skillIdx, setState, updateServerResume) => {
    const updatedSkills = resume.skills.filter((s, i) => i !== skillIdx);
    setState(p => ({...p, resume: {...p.resume, skills: updatedSkills}}));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});
}

export const updateResume = (access_token, resume, updated, setState, updateServerResume) => {
    const formatted = structuredClone(updated);
    if (formatted.desiredSalary) formatted.desiredSalary = formatted.desiredSalary.toLocaleString();
    setState(p => ({...p, resume: {...p.resume, ...formatted}}));
    // if (!resume.id) {(async () => {
    //     await createResume(updated, access_token)
    //     // await updateUserResume(access_token, dispatch)
    // })()}
    updateServerResume(access_token, resume.id, updated);
}

export const createExperience = async (access_token, resume, setState) => {
    console.log(123)
    const data = await createResumeExperience(access_token, resume);
    console.log(data);
    if (data === false) return;
    data.startMonth = data.startMonth.toString().padStart(2, "0");
    data.endMonth = data.endMonth.toString().padStart(2, "0");
    setState(p => ({...p, resume: {...p.resume, experience: [...p.resume.experience, data]}}));
}

export const editExperience = async (access_token, experienceId, updated, resumeExp, setState, updateServerExperience) => {
    const experience = {...resumeExp.find(e => e.id === experienceId), ...updated};
    setState(p => ({...p, resume: {...p.resume, experience: p.resume.experience.map(e => e.id === experienceId ? experience : e)}}));
    if (updated.hasOwnProperty("startMonth")) updated.startMonth = +updated.startMonth;
    if (updated.hasOwnProperty("endMonth")) updated.endMonth = +updated.endMonth;
    if (updated.hasOwnProperty("endYear")) {
        updated.endYear = updated.endYear !== '' ? updated.endYear : null;
    }
    updateServerExperience(access_token, experienceId, updated);
}

export const deleteExperience = async (access_token, id, resumeExp, setState) => {
    const ok = await deleteResumeExperience(access_token, id);
    if (!ok) return;
    setState(p => ({...p, resume: {...p.resume, experience: p.resume.experience.filter(e => e.id !== id)}}));
}

export const createEducation = async (access_token, resume, setState) => {
    const data = await createResumeEducation(access_token, resume);
    if (data === false) return;
    setState(p => ({...p, resume: {...p.resume, education: [...p.resume.education, data]}}));
}

export const editEducation = async (access_token, educationId, updated, resumeEd, setState, updateServerEducation) => {
    const education = {...resumeEd.find(e => e.id === educationId), ...updated};
    setState(p => ({...p, resume: {...p.resume, education: p.resume.education.map(e => e.id === educationId ? education : e)}}));
    updateServerEducation(access_token, educationId, updated);
}

export const deleteEducation = async (access_token, id, resumeEd, setState) => {
    const ok = deleteResumeEducation(access_token, id);
    if (!ok) return;
    setState(p => ({...p, resume: {...p.resume, education: p.resume.education.filter(e => e.id !== id)}}));
}

export const createCertificate = async (access_token, resume, setState) => {
    const data = await createResumeCertificate(access_token, resume);
    if (data === false) return;
    setState(p => ({...p, resume: {...p.resume, certificates: [...p.resume.certificates, data]}}));
}

export const editCertificate = async (access_token, certificateId, updated, resumeCert, setState, updateServerCertificates) => {
    const education = {...resumeCert.find(e => e.id === certificateId), ...updated};
    updateServerCertificates(access_token, certificateId, updated);
    setState(p => ({...p, resume: {...p.resume, certificates: p.resume.certificates.map(e => e.id === certificateId ? education : e)}}));
}

export const deleteCertificate = async (access_token, id, resumeCert, setState) => {
    const ok = await deleteResumeCertificate(access_token, id);
    if (!ok) return;
    setState(p => ({...p, resume: {...p.resume, certificates: p.resume.certificates.filter(e => e.id !== id)}}));
}

export const createLanguage = async (access_token, resume, setState) => {
    const data = await createResumeLanguage(access_token, resume);
    if (data === false) return;
    setState(p => ({...p, resume: {...p.resume, languages: [...p.resume.languages, data]}}));
}

export const editLanguage = async (access_token, languageId, updated, resumeLang, setState, updateServerLanguage) => {
    const language = {...resumeLang.find(e => e.id === languageId), ...updated};
    updateServerLanguage(access_token, languageId, updated);
    setState(p => ({...p, resume: {...p.resume, languages: p.resume.languages.map(e => e.id === languageId ? language : e)}}));
}

export const deleteLanguage = async (access_token, id, resumeLang, setState) => {
    const ok = await deleteResumeLanguage(access_token, id);
    if (!ok) return;
    setState(p => ({...p, resume: {...p.resume, languages: p.resume.languages.filter(e => e.id !== id)}}));
}

export const uploadAvatar = async (access_token, userId, file, dispatch) => {
    try {
        const ok = await uploadFile(userId, access_token, file);
        if (!ok) return dispatch(showErrorMsg('Ошибка'));
        dispatch(showSuccessMsg('Загружено'));
    } catch (error) {
        console.error(error);
        dispatch(showErrorMsg("Произошла ошибка"));
    }
}