import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router';
import {
  createMeetingFeedback,
  patchMeetingFeedback,
} from '../../api/meetingFeedback';
import {
  deleteMeeting,
  getMeetingById,
  updateMeeting,
  uploadVideo,
} from '../../api/meetings';
import { AppSelect } from '../../components/ui/AppSelect';
import InputAutosize from '../../components/ui/InputAutosize';
import { Loader } from '../../components/ui/Loader';
import TextareaAutosize from '../../components/ui/TextareaAutosize';
import {
  COLORS,
  MEETING_STATUS,
  MEETING_TYPES,
  meetingStatusOptions,
  SOURCE,
  toDate,
  toTime,
  useDebounceCallback,
} from '../../constants';
import { ReactComponent as FileSvg } from '../../icons/file.svg';
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice';
import classes from './Meeting.module.scss';
import { NotFoundPage } from '../system/NotFoundPage';

const defaultState = {
  loading: false,
  videoName: '',
  videoUpload: null,
  feedback: null,
  edited: {
    name: '',
    description: '',
    transcript: '',
  },
  meeting: {
    loaded: false,
  },
  notFound: false,
};

export const Meeting = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(defaultState);
  const videoRef = useRef();
  const user = useSelector((state) => state.userSlice.user);
  useEffect(() => {
    if (!state.meeting.loaded)
      (async () => {
        const meeting = await getMeetingById(user.access_token, id, {
          include: ['slot', 'feedback'],
        });

        if (meeting === 404) {
          setState((p) => ({ ...p, notFound: true }));
          return;
        }

        if (!meeting)
          return dispatch(
            showErrorMsg(
              'Ошибка при загрузке встречи или встречи не существует'
            )
          );
        setState({
          ...state,
          meeting: { ...meeting, loaded: true },
          edited: {
            name: meeting.name,
            description: meeting.description,
            transcript: meeting.transcript,
          },
          feedback: (meeting.feedback || [null])[0],
        });
      })();
  }, [state.meeting.loaded]);

  const handleUploadVideo = async () => {
    try {
      if (!state.videoUpload) dispatch(showErrorMsg('Выберите видео'));
      setState({ ...state, loading: true });
      const result = await uploadVideo(
        user.access_token,
        id,
        state.videoUpload
      );
      setState({ ...state, loading: false, videoName: '', videoUpload: null });
      if (!result) return dispatch(showErrorMsg('Ошибка при загрузке видео'));
      dispatch(showSuccessMsg('Видео загружено!'));
    } catch (e) {
      console.log(e);
      dispatch(showErrorMsg('Ошибка при загрузке видео'));
    }
  };

  const updateServerFeedback = useDebounceCallback(async (text) => {
    if (!state.meeting.loaded) return;
    let data;
    if (!state.feedback.id) {
      data = await createMeetingFeedback(
        user.access_token,
        text,
        state.meeting.id
      );
      if (!data.error) setState((p) => ({ ...p, feedback: data }));
    } else
      data = await patchMeetingFeedback(
        user.access_token,
        state.feedback.id,
        text,
        state.meeting.id,
        state.feedback.name
      );
    if (data.error) dispatch(showErrorMsg('Произошла ошибка'));
  }, 500);

  const handleInputFeedback = async (e) => {
    setState((p) => ({
      ...p,
      feedback: { ...p.feedback, text: e.target.value },
    }));
    updateServerFeedback(e.target.value);
  };

  const setMeetingStatus = async (status) => {
    if (
      !window.confirm(
        status === 'CANCELED'
          ? 'Отменить встречу?'
          : status === 'COMPLETED'
          ? 'Завершить встречу?'
          : 'Запланировать встречу?'
      )
    )
      return;
    const data = await updateMeeting(user.access_token, id, { status: status });
    if (!data) return dispatch(showErrorMsg('Ошибка'));
    setState({ ...state, meeting: { ...state.meeting, loaded: false } });
  };

  const handleDeleteMeeting = async () => {
    if (!window.confirm('Вы действительно хотите отменить (удалить) встречу?'))
      return;
    const res = await deleteMeeting(user.access_token, id);
    if (!res.ok) return dispatch(showErrorMsg('Ошибка'));
    navigate('/meetings');
  };

  useEffect(() => {
    updateData();
  }, [state.edited]);

  const updateData = useDebounceCallback(() => {
    let updated = {};
    Object.keys(state.edited).map((k) => {
      if (state.edited[k] !== '' && state.meeting[k] !== state.edited[k]) {
        updated[k] = state.edited[k];
      }
    });
    Object.keys(updated).length &&
      updateMeeting(user.access_token, id, updated);
  }, 500);

  // useEffect(() => console.log(state), [state]);

  const handleViewMeeter = () => {
    if (state.meeting.applicantId)
      navigate('/candidates/' + state.meeting.applicantId);
    if (state.meeting.employerId)
      navigate('/employers/' + state.meeting.employerId);
  };
  const handleMeetingStatus = (e) => {
    setMeetingStatus(e.target.value);
  };

  if (state.notFound) {
    return <Navigate to='/not-found' />;
  }

  return !state.meeting.loaded ? (
    <Loader />
  ) : (
    <div className='d-flex flex-column gy-2'>
      <div className='d-flex flex-row flex-wrap mb-2'>
        <InputAutosize
          className={classes.Title + ' fs-4 fw-bolded me-2'}
          value={state.edited.name}
          onChange={(e) =>
            setState({
              ...state,
              edited: {
                ...state.edited,
                name: e.target.value,
              },
            })
          }
        />
        <p className='fs-4'>
          [{MEETING_TYPES[state.meeting.type]},{' '}
          {MEETING_STATUS[state.meeting.status]}]
        </p>
      </div>
      <TextareaAutosize
        className='fs-5 mt-1 mb-3 rounded p-2'
        minHeight='3.5rem'
        defaultHeight='3.5rem'
        style={{
          resize: 'none',
          minWidth: '300px',
          border: '1px solid' + COLORS.hr,
        }}
        value={state.edited.description || state.meeting.description}
        onChange={(e) =>
          setState({
            ...state,
            edited: {
              ...state.edited,
              description: e.target.value,
            },
          })
        }
      />
      <div className='row gy-2 gap-4 mt-3'>
        <div className='col-auto'>
          {state.meeting.guestEmail ? (
            <p className='fs-3'>{state.meeting.guestEmail}</p>
          ) : (
            <p
              className='underline'
              style={{ cursor: 'pointer', color: COLORS.primary }}
              onClick={handleViewMeeter}
            >
              Посмотреть информацию о собеседнике
            </p>
          )}

          <p className='fs-3 me-3 mt-4'>
            {toDate(state.meeting.slot.dateTime) +
              ', ' +
              toTime(state.meeting.slot.dateTime)}
          </p>
          <div
            className='d-flex flex-column gap-2'
            style={{ width: 'fit-content' }}
          >
            <button
              className='btn success'
              onClick={() => window.open(state.meeting.roomUrl)}
            >
              Подключиться
            </button>
            <button className='btn btn-danger' onClick={handleDeleteMeeting}>
              Удалить встречу
            </button>
          </div>

          <p className='fs-5 mt-4'>Видеозапись собеседования</p>
          <p
            onClick={() =>
              window.open(SOURCE.url + `meetings/${state.meeting.id}/video`)
            }
            className='text-decoration-underline'
            style={{ cursor: 'pointer' }}
          >
            Посмотреть
          </p>
          <div className='d-flex align-items-center pt-2 mb-4'>
            <div
              className='position-relative w-100'
              style={{ maxWidth: '300px' }}
            >
              <label
                style={{ zIndex: '1', top: '-10px' }}
                className='ms-3 position-absolute'
              >
                <span className='h6 small bg-white text-muted px-1'>
                  Прикрепите файл
                </span>
              </label>
              <input
                type='file'
                accept='video/mp4,video/webm,video/mov,video/mkv'
                ref={videoRef}
                className='d-none'
                onChange={(e) => {
                  let f = e.target.files[0];
                  if (
                    ['webm', 'mp4', 'mov', 'mkv'].indexOf(
                      f.name.split('.').at(-1)
                    ) < 0
                  ) {
                    dispatch(showErrorMsg('Недопустимый формат файла'));
                  } else if (f.size < 1500 * 10 ** 6) {
                    setState((p) => ({ ...p, videoUpload: f }));
                  } else
                    dispatch(
                      showErrorMsg('Размер файла не должен превышать 3 ГБ')
                    );
                  e.target.value = null;
                }}
              />
              <div
                className='d-flex w-100 flex-nowrap form-control p-3 justify-content-between align-items-center position-relative'
                style={{
                  height: '58px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => videoRef.current.click()}
              >
                {state.videoUpload !== null && (
                  <p className='fs-6 m-0 p-0 text-truncate'>
                    {state.videoUpload.name.length > 20
                      ? state.videoUpload.name.substring(0, 19) + '...'
                      : state.videoUpload.name}
                  </p>
                )}
                <FileSvg
                  className='position-absolute'
                  style={{ width: '20px', height: '22px', right: '10px' }}
                  alt=''
                />
              </div>
            </div>
            {state.videoUpload !== null && (
              <div className='d-flex flex-row ms-3'>
                {state.loading ? (
                  <Loader size='small' animation={false} />
                ) : (
                  <span
                    className='fs-5 underline'
                    onClick={handleUploadVideo}
                    style={{ color: COLORS.primary, cursor: 'pointer' }}
                  >
                    Загрузить
                  </span>
                )}
              </div>
            )}
          </div>
          <div>
            <AppSelect
              onChange={handleMeetingStatus}
              value={state.meeting.status}
              options={meetingStatusOptions}
              name='employmentType'
            />
          </div>

          <p className='fs-4 fw-bolded'>Отзыв о встрече</p>
          <TextareaAutosize
            value={state.feedback?.text || ''}
            className='fs-6 fw-medium w-100 rounded p-2'
            minHeight='3.5rem'
            defaultHeight='3.5rem'
            style={{
              resize: 'none',
              minWidth: '300px',
              border: '1px solid' + COLORS.hr,
            }}
            onChange={handleInputFeedback}
          />
        </div>
        <div className='col-md'>
          <p className='fs-4 fw-bolded'>Транскрипция</p>
          <TextareaAutosize
            value={state.edited.transcript || state.meeting.transcript}
            className='fs-6 fw-medium w-100 rounded p-2'
            minHeight='3.5rem'
            defaultHeight='3.5rem'
            style={{
              resize: 'none',
              minWidth: '300px',
              maxHeight: '700px',
              border: '1px solid' + COLORS.hr,
            }}
            onChange={(e) =>
              setState({
                ...state,
                edited: {
                  ...state.edited,
                  transcript: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
