import {useDispatch, useSelector} from "react-redux";
import {showErrorMsg, showSuccessMsg} from "../../store/slices/alertSlice";
import {COLORS, SOURCE} from "../../constants";
import {useDropzone} from "react-dropzone";
import {Modal} from "react-bootstrap";
import {ReactComponent as DownloadSvg} from "../../icons/download.svg";
import {useEffect} from "react";

export const UploadPDFResumeModal = ({ candidateId, show, setShow }) => {
    const user = useSelector(state => state.userSlice.user)
    const dispatch = useDispatch();

    const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({
        multiple: false,
        accept: { "application/pdf": [".pdf"] }
    });

    useEffect(() => console.log(show), [show])

    const handleUploadResume = async () => {
        if (acceptedFiles.length === 0) return await dispatch(showErrorMsg("Выберите файл"));
        const formData = new FormData();
        formData.append("file", acceptedFiles[0], acceptedFiles[0].name);
        try {
            const res = await fetch(SOURCE.url + "resumes-ocr/pdf", {
                headers: { Authorization: user.access_token },
                method: "PUT",
                body: formData
            });
            if (res.status === 413) return await dispatch(showErrorMsg("Файл слишком большой"));
            else if (res.status === 415) return await dispatch(showErrorMsg("Неверный тип файла"));
            else if (res.status === 429) return await dispatch(showErrorMsg("Вы отправляете файлы слишком часто. Попробуйте позже"));
            else if (!res.ok) return await dispatch(showErrorMsg("Ошибка на сервере"));
            return await dispatch(showSuccessMsg("Успешно"));
        } catch (e) {
            await dispatch(showErrorMsg("Ошибка на сервере"));
            console.error(e);
        }
    }

    const closeModal = () => setShow(false);

    return <Modal dialogClassName="rounded-4" contentClassName="p-3" size='xl' centered show={show}
                   onHide={closeModal} onClick={e => e.stopPropagation()}>
        <Modal.Header closeButton className="border-0">
            <Modal.Title style={{fontWeight: '600'}}>Быстрый отклик</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="w-100 d-flex flex-column align-items-center gap-2 fs-5">
                <span className="fw-semibold">Загрузить резюме из PDF</span>
                <div {...getRootProps({
                    className: "w-100 d-flex flex-column text-center justify-content-center align-items-center p-5 rounded-5 gap-3",
                    style: {
                        border: "2px dashed " + (isDragActive ? COLORS.primary : "lightgray"),
                        cursor: "pointer"
                    }
                })}>
                    <input {...getInputProps()} />
                    <DownloadSvg />
                    <span style={{color: isDragActive ? COLORS.primary : COLORS.dark_neutral}}>
                        Перенеси или загрузи из проводника<br/>PDF файл резюме
                    </span>
                    { acceptedFiles.length > 0 && <span>{acceptedFiles[0].name}</span> }
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
            <button className="btn primary py-2 px-5 fs-5" disabled={acceptedFiles.length === 0} onClick={handleUploadResume}>Отправить</button>
            <button className="btn outlined py-2 px-5 fs-5" onClick={closeModal}>Отменить</button>
        </Modal.Footer>
    </Modal>
}