import styles from "./AppSwitch.module.scss";

export const AppSwitch = ({
                              className,
                              onChange,
                              value,
                              ...props
                          }) => {
    return <label className={styles.switch + " " + className} {...props}>
        <input type="checkbox" checked={value} onChange={onChange} {...props} />
        <span className={styles.slider}></span>
    </label>
}