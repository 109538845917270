import { useEffect, useState } from "react"
import { PromocodeModal } from "../../components/modals/PromocodeModal"
import { Loader } from "../../components/ui/Loader"
import { getAllPromocodes } from "../../api/promocode"
import { useSelector } from "react-redux"
import { COLORS, toDate, toTime } from "../../constants"
import './PromocodesScreen.css'

export const PromocodesScreen = () => {
    const user = useSelector(state => state.userSlice.user)
    const [state, setState] = useState({
        modalData: {
            obj: null,
            shown: false
        },
        promocodes: {
            data: [],
            loaded: true
        }
    })
    useEffect(() => {
        (async () => {
            const response = await getAllPromocodes(user.access_token)
            if (!response.ok) return
            const res = await response.json()
            setState({ ...state, promocodes: { data: res.items, loaded: true } })
        })()
    }, [])
    return <div>
        { state.modalData.obj !== null && <PromocodeModal modalData={state.modalData} set={setState} />}
        {
            !state.promocodes.loaded ? <Loader /> : 
            state.promocodes.data.length === 0 ? <p className="text-center fs-5">Промокодов не создано ;(</p> : 
            state.promocodes.data.map((el, i) => <div className="mb-3 " onClick={() => setState({ ...state, modalData: { obj: el, shown: true } })} key={i}>
                <p className="fs-5 ps-3 code-wrapper" style={{ cursor: 'pointer', borderLeft: '2px solid ' + COLORS.primary, }}>
                    {el.value + ' | '  + el.discount + '% | ' + el.successfulUses + ' Активаций' + ' | действует до ' + toDate(el.expirationDate) + ', ' + toTime(el.expirationDate)}
                </p>
            </div>)
        }
        <button className="btn primary px-4 py-2 mt-sm-5 mt-4" onClick={() => setState({ ...state, modalData: { obj: {}, shown: true } })}>Создать промокод для встречи</button>
    </div>
}