import { ROLE, SOURCE } from '../constants';

export async function getAllVacancies(token, queryObj = {}, page, size) {
  let include = queryObj.include || [];
  let search = queryObj.search.search;
  const params = new URLSearchParams();

  include.forEach((el) => {
    params.append('include', el);
  });
  // Нет поиска на api
  // if (search) params.append("search", search);
  if (page) params.append('page', page);
  if (size) params.append('size', size);

  const response = await fetch(`${SOURCE.url}vacancies?` + params, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getVacancy(token, id, queryObj = {}) {
  let query = '?';
  let include = queryObj.include || [];
  include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });
  const response = await fetch(
    `${SOURCE.url}vacancies/${id}` + (query === '?' ? '' : query),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status == 404) {
    return 404;
  }

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function editVacancy(token, id, vacancy) {
  const response = await fetch(`${SOURCE.url}vacancies/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      _requester: ROLE.MANAGER,
      city: vacancy.city,
      name: vacancy.name,
      description: vacancy.description,
      teamRole: vacancy.teamRole,
      salary: vacancy.salary,
      salaryCurrency: vacancy.salaryCurrency,
      shortDescription: vacancy.shortDescription,
      status: vacancy.status,
      experience: vacancy.experience,
      employmentType: vacancy.employmentType,
      price: vacancy.price,
      reportingForm: vacancy.reportingForm,
      workingHours: vacancy.workingHours,
      workplaceModel: vacancy.workplaceModel,
      keySkills: vacancy.keySkills,
    }),
  });

  if (!response.ok) {
    return false;
  }
  return true;
}

export async function deleteVacancy(token, id) {
  const response = await fetch(`${SOURCE.url}vacancies/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getCount(id, token) {
  const response = await fetch(
    `${SOURCE.url}vacancyResponses/count?employerId=${id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  if (response.status === 404) return;

  const json = await response.json();
  return json;
}
