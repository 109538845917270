import { SOURCE } from '../constants'

export async function deleteSlotMeeting(token, id) {
	const response = await fetch(`${SOURCE.url}meetingSlots/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: token,
		},
	})

	if (!response.ok) return false
	return true
}

export async function getMyMeetingSlot(token, queryObj = {}) {
	const params = new URLSearchParams()

	for (const [key, value] of Object.entries(queryObj)) {
		if (value) {
			params.append(key, value)
		}
	}
	const response = await fetch(
		`${SOURCE.url}meetingSlots/my?available=true&size=2000&`+ new URLSearchParams(params),
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: token,
			},
		}
	)

	if (!response.ok) {
		return false
	}

	const json = await response.json()
	return json
}

export async function createMeetingSlot(token, obj) {
	const response = await fetch(`${SOURCE.url}meetingSlots`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify(obj),
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	const json = await response.json()
	return json
}
export async function createMeetingSlots(token, obj) {
	const response = await fetch(`${SOURCE.url}meetingSlots/withinRange`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify(obj),
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	const json = await response.json()
	return json
}
