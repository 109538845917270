import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice'
import { AppInput } from '../ui/AppInput'
import { AppSwitch } from '../ui/AppSwitch/AppSwitch'
import { createPromocode, deletePromocode, patchPromocode } from '../../api/promocode'

export const PromocodeModal = ({ modalData, set }) => {
	const dispatch = useDispatch()

    const isNew = Object.keys(modalData.obj).length === 0

	const [state, setState] = useState({
		value: isNew ? '' : modalData.obj.value,
        discount: isNew ? 0 : modalData.obj.discount,
        expirationDate: isNew ? '' : modalData.obj.expirationDate,
        isActive: isNew ? true : modalData.obj.isActive, 
        maxUses: isNew ? 0 : modalData.obj.maxUses,
	})

	const user = useSelector(state => state.userSlice.user)

    const save = async  () => {
        let result = false

        if (isNew){
            result = await create()
        }
        else result = await patch()
        if (result) {
            dispatch(showSuccessMsg('Успешно'))
            set(p => ({ ...p, modalData: { obj: null, shown: false} }))
        }
    }

	const create = async () => {
		try {
            const response = await createPromocode(user.access_token, { ...state, discount: parseFloat(state.discount)})
            if (response.status === 200) return true
            return false
		} catch (error) {
			dispatch(showErrorMsg('Ошибка при создании'))
            return false
		}
	}

    const patch = async () => {
		try {
            const response = await patchPromocode(user.access_token, { ...state, discount: parseFloat(state.discount)}, modalData.obj.value)
            if (response.status === 200) return true
            return false
		} catch (error) {
			dispatch(showErrorMsg('Ошибка при сохранении'))
            return false
		}
	}

    const deleteHandler = async () => {
		try {
            const response = await deletePromocode(user.access_token, state)
            if (response.status === 200) {
                dispatch(showSuccessMsg('Успешно'))
                set(p => ({ ...p, modalData: { obj: null, shown: false} }))
            }
            return false
		} catch (error) {
			dispatch(showErrorMsg('Ошибка при удалении'))
            return false
		}
	}
	return (
		<Modal
			dialogClassName='rounded-4'
			size='lg'
			centered
			show={modalData.shown}
			onHide={() => set(p => ({ ...p, modalData: { obj: null, shown: false} }))}
		>
			<Modal.Header closeButton>
				<Modal.Title className='fw-bolded'>{isNew ? 'Создать' : 'Редактировать'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                <div className='d-flex flex-column'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-lg d-flex'>
                            <AppInput label='Значение' className='w-100' value={state.value} onChange={v => setState({ ...state, value: v })} placeholder='промокод?' />
                        </div>
                        <div className='col-12 col-sm-6 col-lg d-flex'>
                            <AppInput label='Размер скидки' className='w-100' value={state.discount} onChange={v => setState({ ...state, discount: v })} placeholder='скидка в %?' />
                        </div>
                        <div className='col-12 col-sm-6 col-lg d-flex'>
                            <AppInput label='N активаций' className='w-100' value={state.maxUses} onChange={v => setState({ ...state, maxUses: v })} placeholder='количество использований?' />
                        </div>
                        <div className='col-12 col-sm-6 col-lg d-flex'>
                            <AppInput label='Действует до' className='w-100' type='datetime-local' value={state.expirationDate} onChange={v => setState({ ...state, expirationDate: v })} placeholder='заканчивается?' />
                        </div>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <p className='fs-5 me-2'>Активен</p>
                        <AppSwitch value={state.isActive} onChange={e => setState({ ...state, isActive: !state.isActive })}  />
                       { !isNew  && <button className='btn red_primary px-4 py-2 ms-auto' onClick={() => deleteHandler()} >Удалить</button> }
                    </div>
                </div>

			</Modal.Body>
			<Modal.Footer>
				<button
					className='btn px-3 py-2 ms-auto btn primary'
					disabled={false}
					onClick={() => save()}
				>
					{isNew ? 'Создать' : 'Редактировать'}
				</button>
			</Modal.Footer>
		</Modal>
	)
}
