import './styles.css';
import {useDispatch, useSelector} from "react-redux";
import {
    createCertificate,
    createEducation,
    createExperience, createLanguage,
    createSkill
} from "../ConstructorResumeBlock/utils";
import {COLORS, useDebounceCallback} from "../../../../../constants";
import {patchResume} from "../../../../../api/resume";

export default function ConstructorSectionsBlock({ applicant, resume, setState }) {
    const updateServerResume = useDebounceCallback(patchResume, 500);
    const access_token = useSelector(state => state.userSlice.user.access_token)
    return  <div className="bg-white rounded-3 w-100 px-4 py-4 d-flex flex-column gap-3">
        <span className="fs-5 fw-semibold mb-1">Секции</span>
        {
            !resume.id ? <p className='fs-5' style={{ color: COLORS.danger }}>Укажите специальность для редактирования вашего резюме</p> :
            <>
                <button className="fs-6 section-btn" disabled={resume.experience && resume.experience.length} 
                    onClick={() => createExperience(access_token, resume, setState)}>Опыт работы</button>
                <button className="fs-6 section-btn" disabled={resume.education && resume.education.length} 
                    onClick={() => createEducation(access_token, resume, setState)}>Образование</button>
                <button className="fs-6 section-btn" disabled={resume.certificates && resume.certificates.length} 
                    onClick={() =>  createCertificate(access_token, resume, setState)}>Курсы и сертификаты</button>
                <button className="fs-6 section-btn" disabled={resume.skills && resume.skills.length} 
                    onClick={() => createSkill(access_token, resume, setState, updateServerResume)}>Навыки</button>
                <button className="fs-6 section-btn" disabled={resume.languages && resume.languages.length} 
                    onClick={() => createLanguage(access_token, resume, setState)}>Языки</button>
            </>
        }
    </div>
}