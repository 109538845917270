import { useNavigate } from 'react-router';
import { DataTable } from '../../../components/table/Table';
import { getAllGuests } from '../../../api/guest';

import { Search } from './Grades';
import {
  clearSliceFilters,
  setFilters,
} from '../../../store/slices/tableSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toDate, toTime } from '../../../constants';

export const GVResponse = ({ setState }) => {
  const { search } = useSelector((state) => state.tableSlice.filters);
  const dispatch = useDispatch();

  const customGetMethod = (access_token, queryObj, page, size) => {
    return getAllGuests(
      access_token,
      { include: ['vacancy'], search: queryObj },
      page,
      size
    );
  };

  const clearFilters = () => {
    dispatch(clearSliceFilters({ search: '' }));
  };

  return (
    <div className='w-100'>
      <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
        Вакансии
      </p>
      <DataTable
        Template={Template}
        setParentState={setState}
        Wrapper={Wrapper}
        SearchAndFilter={SearchAndFilter}
        filterObject={{ search }}
        setFilterObject={setFilters}
        clearFilters={clearFilters}
        getMethod={customGetMethod}
      />
    </div>
  );
};

const SearchAndFilter = ({ filters, setFilters, onFilter, clearFilters }) => {
  return (
    <div className='d-flex align-items-center gap-4'>
      <Search state={filters} setState={setFilters} />
      <button className='btn btn-warning' onClick={clearFilters}>
        Очистить
      </button>
    </div>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className='table-responsive' key='uniqueKey'>
      <table className='table table-hover align-middle' id='myTable'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Вакансия</th>
            <th scope='col'>статус</th>
            <th scope='col'>статус</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const Template = ({ u, i }) => {
  const navigate = useNavigate();
  return (
    <tr key={i} onClick={() => navigate('/guestResponses/' + u.id)}>
      <th>{i + 1}</th>
      <td style={{ cursor: 'pointer' }}>{u.vacancy.name}</td>
      <td style={{ cursor: 'pointer' }}>
        {u.moderationStatus === 'PUBLISHED'
          ? 'Опубликована'
          : u.moderationStatus === 'UNDER_REVIEW'
          ? 'На рассмотрении'
          : u.moderationStatus === 'FAILED_TO_PASS_REVIEW'
          ? 'Не прошла модерацию'
          : 'Без статуса'}
      </td>
      <td style={{ cursor: 'pointer' }}>
        {toTime(u.createdAt) + ' ' + toDate(u.createdAt) + ' '}
      </td>
    </tr>
  );
};

const onModerationStatus = ({ u }) => {};
