import {createSlice} from '@reduxjs/toolkit'

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        alert: {
            successMsg: '',
            errorMsg: '',
            showAlerts: false,
        }
    },
    reducers: {
        showSuccessMsg: (state, action) => {
            state.alert.successMsg = action.payload
        },
        showErrorMsg: (state, action) => {
            state.alert = { ...state.alert, errorMsg: action.payload, showAlerts: true }
        },
        showAlerts: (state, action) => {
            state.alert.showAlerts = true
        },
        hideErrorMsg: (state, action) => {
            state.alert.errorMsg = ''
            state.alert.showAlerts = false
        },
        hideSuccessMsg: (state, action) => {
            state.alert.successMsg = ''
        },
        clear: (state, action) => {
            state.alert = { errorMsg: '', successMsg: '', showAlerts: false }
        },
    },
})

// Action creators are generated for each case reducer function
export const { showSuccessMsg, showErrorMsg, hideErrorMsg, hideSuccessMsg, clear } = alertSlice.actions;

export default alertSlice.reducer;