// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditVacancy_VacancyParameters__aYep7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  background: #fff;
  border-radius: 7px;
}
@media (max-width: 1168px) {
  .EditVacancy_VacancyParameters__aYep7 {
    padding: 15px 20px;
  }
}
@media (max-width: 850px) {
  .EditVacancy_VacancyParameters__aYep7 {
    padding: 38px 36px;
  }
}

.EditVacancy_VacancyDescBlock__BkMeJ {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #fff;
  border-radius: 7px;
}
@media (max-width: 1168px) {
  .EditVacancy_VacancyDescBlock__BkMeJ {
    padding: 15px 20px;
  }
}
@media (max-width: 850px) {
  .EditVacancy_VacancyDescBlock__BkMeJ {
    padding: 38px 41px;
  }
}

.EditVacancy_ParameterInput__uYi4E {
  outline: none;
  border: none;
  -moz-appearance: textfield;
}

.EditVacancy_ParameterInput__uYi4E::placeholder {
  color: black;
  text-decoration: underline;
}

.EditVacancy_ParameterInput__uYi4E::-webkit-outer-spin-button,
.EditVacancy_ParameterInput__uYi4E::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.EditVacancy_ParameterSelect__cupwM {
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.EditVacancy_Underline__PDzCA {
  text-decoration: underline;
}

.EditVacancy_DescriptionField__UYu\\+s {
  padding: 0.75rem 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/vacancies/layouts/EditVacancy/EditVacancy.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EATF;IAUI,kBAAA;EAEF;AACF;AAAE;EAbF;IAcI,kBAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,kBAAA;AAGF;AADE;EAPF;IAQI,kBAAA;EAIF;AACF;AAFE;EAXF;IAYI,kBAAA;EAKF;AACF;;AAFA;EACE,aAAA;EACA,YAAA;EACA,0BAAA;AAKF;;AAFA;EACE,YAAA;EACA,0BAAA;AAKF;;AAFA;;EAEE,wBAAA;EACA,SAAA;AAKF;;AAFA;EACE,aAAA;EACA,YAAA;EACA,wBAAA;EACA,qBAAA;EACA,eAAA;AAKF;;AAFA;EACE,0BAAA;AAKF;;AAFA;EACE,6BAAA;AAKF","sourcesContent":[".VacancyParameters {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  list-style: none;\n  background: #fff;\n  border-radius: 7px;\n\n  @media (max-width: 1168px) {\n    padding: 15px 20px;\n  }\n\n  @media (max-width: 850px) {\n    padding: 38px 36px;\n  }\n}\n\n.VacancyDescBlock {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  background: #fff;\n  border-radius: 7px;\n\n  @media (max-width: 1168px) {\n    padding: 15px 20px;\n  }\n\n  @media (max-width: 850px) {\n    padding: 38px 41px;\n  }\n}\n\n.ParameterInput {\n  outline: none;\n  border: none;\n  -moz-appearance: textfield;\n}\n\n.ParameterInput::placeholder {\n  color: black;\n  text-decoration: underline;\n}\n\n.ParameterInput::-webkit-outer-spin-button,\n.ParameterInput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.ParameterSelect {\n  outline: none;\n  border: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  cursor: pointer;\n}\n\n.Underline {\n  text-decoration: underline;\n}\n\n.DescriptionField {\n  padding: .75rem 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VacancyParameters": `EditVacancy_VacancyParameters__aYep7`,
	"VacancyDescBlock": `EditVacancy_VacancyDescBlock__BkMeJ`,
	"ParameterInput": `EditVacancy_ParameterInput__uYi4E`,
	"ParameterSelect": `EditVacancy_ParameterSelect__cupwM`,
	"Underline": `EditVacancy_Underline__PDzCA`,
	"DescriptionField": `EditVacancy_DescriptionField__UYu+s`
};
export default ___CSS_LOADER_EXPORT___;
