// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Meeting_Title__d3f99 {
  outline: none !important;
  border: 0 none !important;
  background: transparent;
  padding: 0;
  color: var(--text);
}

.Meeting_Title__d3f99:focus {
  color: var(--header-text);
}

.Meeting_Title__d3f99::placeholder {
  color: var(--header-text);
}`, "",{"version":3,"sources":["webpack://./src/screens/meetings/Meeting.module.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,yBAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".Title {\n    outline: none !important;\n    border: 0 none !important;\n    background: transparent;\n    padding: 0;\n    color: var(--text);\n}\n\n.Title:focus {\n    color: var(--header-text);\n}\n\n.Title::placeholder {\n    color: var(--header-text);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": `Meeting_Title__d3f99`
};
export default ___CSS_LOADER_EXPORT___;
