import {SOURCE} from "../../../../../constants";
import {createResumeContact, deleteResumeContact} from "../../../../../api/resume";

export const createContact = async (access_token, type, resume, setState) => {
    const data = await createResumeContact(access_token, resume, type);
    if (data === false) return;
    setState(p => ({...p, resume: {...p.resume, contacts: [...p.resume.contacts, data]}}));
}

export const editContact = async (access_token, contactId, updated, resumeContacts, setState, updateServerContact) => {
    const contact = {...resumeContacts.find(e => e.id === contactId), ...updated};
    updateServerContact(access_token, contactId, updated);
    setState(p => ({...p, resume: {...p.resume, contacts: p.resume.contacts.map(e => e.id === contactId ? contact : e)}}));
}

export const deleteContact = async (access_token, id, resumeContacts, setState) => {
    const ok = await deleteResumeContact(access_token, id);
    if (!ok) return;
    setState(p => ({...p, resume: {...p.resume, contacts: p.resume.contacts.filter(e => e.id !== id)}}));
}