import React, {useEffect, useRef, useState} from 'react'
import classes from './CompanyCard.module.scss'
import {COLORS, SOURCE, teamSizeLabels, toDate} from "../../../../constants";
import {useLocation, useNavigate} from "react-router";
import {useSelector} from "react-redux";
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar';

const CompanyCard = ({canEdit = false, employer}) => {
    const navigate = useNavigate();
    const [showFullText, setShowFullText] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const descriptionRef = useRef(null);

    useEffect(() => {
        if (descriptionRef.current) {
            setIsOverflowing(descriptionRef.current.scrollHeight > 84);
        }
    }, [employer.description]);

    const toggleShowMore = () => {
        setShowFullText(!showFullText);
    };
    return (
        <div className={classes.Container + ' p-4 p-xl-5 '}>
            <div className={classes.Company} onClick={() => navigate('/employers/' + employer.id)} style={{ cursor: 'pointer' }}>
                <DefaultAvatar
                    style={{ height: '80px', width: '80px' }}
                    className={classes.CompanyAvatar}
                    src={SOURCE.url + `employers/${employer.id}/avatar`}
                    alt="user"
                    onClick={() => console.log(1)}
                />
                <div className={classes.CompanyTitle + ' my-auto'}>
                    <div className={classes.CompanyTitleUp}>
                        {employer.name}
                    </div>
                    <div className={classes.CompanyTitleDown}>
                        договор №{employer.agreementNumber} от {toDate(employer.createdAt)}</div>
                </div>
            </div>
            <p className='user-select-all' style={{ color: COLORS.primary }}>{employer.contact}</p>

            <div
                className={classes.CompanyInfo}
                style={{maxHeight: showFullText ? 'none' : '84px'}}
                ref={descriptionRef}
            >
                {employer.description}

                {isOverflowing && (
                    <span
                        className={classes.ShowMoreButton}
                        onClick={toggleShowMore}
                    >
                        {showFullText ? 'Скрыть' : '... читать дальше'}
                    </span>
                )}
            </div>

            <ul className={classes.CompanyStat}>
                <li><b>Объем:</b>{` ${teamSizeLabels[employer.size] || 'Не указано'}`}</li>
                <li><b>Город:</b>{` ${employer.city || 'Не указано'}`}</li>
                <li><b>Стартап:</b>{` ${employer.isStartup ? "да" : "нет"}`}</li>
            </ul>

            <a
                href={employer.website}
                target={"_blank"}
                className={classes.Link}
            >
                Наш сайт
            </a>

            { canEdit && <button className='btn primary px-3 py-2'
                onClick={() => navigate("edit")}
            >Редактировать страницу</button> }
        </div>
    )
}
export default CompanyCard