import {useEffect, useRef} from "react";

export default function TextareaAutosize({minHeight, defaultHeight, ...props}) {
    const inputRef = useRef();

    const updateSize = (el) => {
        if (!el) return
        if (el.value) {
            el.style.height = minHeight;
            el.style.height = el.scrollHeight + "px";
        } else el.style.height = defaultHeight;
    }

    useEffect(() => {
        updateSize(inputRef.current);
    }, [props.value]);

    useEffect(() => {
        window.addEventListener("resize", () => updateSize(inputRef.current));
        return () => window.removeEventListener("resize", () => updateSize(inputRef.current));
    }, []);

    return <textarea {...props} style={{...props.style, minHeight, height: defaultHeight, transition: "none"}} ref={inputRef}
                  onChange={(e) => {
                      !props.value && updateSize(e.currentTarget);
                      props.onChange && props.onChange(e);
                  }}/>
}