import { Route, Routes } from 'react-router';
import { GVResponse } from './layouts/GVResponse';
import { EditGuestResponse } from './layouts/EditGuestResponse/EditGuestResponse';
import { NotFoundPage } from '../system/NotFoundPage';

export const VacanciesResponseScreen = () => {
  return (
    <Routes>
      <Route path='/' element={<GVResponse />} />
      <Route path='/:id' element={<EditGuestResponse />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
