import { createSlice } from '@reduxjs/toolkit';

const loadData = (items, data, page, size) => {
  const firstIndex = (page - 1) * size;
  const lastIndex = page * size - 1;

  let dataArray = [];
  if (!data) return { current: dataArray, data: data };

  // Проверка, имеется ли последний элемент
  // на этой странице
  // Или весь ли массив элементов существует
  // в разрезе данной страницы
  if (data[lastIndex] === undefined || !data.every((el) => Boolean(el))) {
    dataArray = items;
    // Get Page -> dataArray
  }

  for (let i = firstIndex; i++; i <= lastIndex) {
    data[i] = dataArray[i - firstIndex];
    if (i > lastIndex) {
      break;
    }
  }
  return { current: dataArray, data: data };
};

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    table: {
      data: [],
      current: [],
    },
    filters: {},
  },
  reducers: {
    getPage: (state, action) => {
      if (!state.table) return;
      const data = loadData(
        action.payload.items,
        state.table.data,
        action.payload.page,
        action.payload.size
      );
      state.table.current = data.current;
      state.table.data = data.data;
    },
    clear: (state, _) => {
      state.table = { data: [] };
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearSliceFilters: (state, action) => {
      state.filters = {
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { getPage, clear, setFilters, clearSliceFilters } =
  tableSlice.actions;

export default tableSlice.reducer;
