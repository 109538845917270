import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

// import { getMe } from '../../api/auth'
// import { showErrorMsg } from './alertSlice'
// export const getMeAsync = createAsyncThunk(
//     "GET",

//     async (_ , {rejectWithValue, getState, dispatch}) => {
//         const state = getState()
//         const token = state.userSlice.user.access_token
//         const data = await getMe(token)
//         if (!data) {
//             return dispatch(showErrorMsg('Произошла ошибка на сервере'))
//         }
//         return data;
//     },
// );

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {
            loaded: false,
            access_token: Cookies.get('access_token'),
        },
    },
    reducers: {
        setAuth: (state, action) => {
            Cookies.set('access_token', action.payload.access_token,  { expires: 365 })
            state.user.access_token = action.payload.access_token
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setErrorFetch: (state, action) => {
            state.user.errorFetch = action.payload
        }
    },
    // extraReducers: builder =>  {
    //     builder
    //         .addCase(getMeAsync.pending, (state, action) => {
    //             state.user.loaded = false
    //         })
    //         .addCase(getMeAsync.fulfilled, (state, action) => {
    //             if (action.payload.id)
    //                 state.user = { ...state.user, ...action.payload, loaded: true}
    //         })
    // }
})

// Action creators are generated for each case reducer function
export const { setAuth, setUser, setErrorFetch } = userSlice.actions

export default userSlice.reducer