import { useEffect, useState } from 'react'
import { COLORS, FULL_MONTHS } from '../../constants'
import { ReactComponent as ArrowLeft } from '../../icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg'

const todayDate = new Date()
export const MonthCalendar = ({
	selectDate,
	data,
	selectedDate,
	meetings,
	calendarDate,
}) => {
	const currentMonth = todayDate.getMonth() + 1
	const currentYear = todayDate.getFullYear()
	const currentDate = todayDate.getDate()
	const today = [
		currentDate.toString(),
		currentMonth.toString().padStart(2, '0'),
		currentYear.toString(),
	].join('-')
	const [state, setState] = useState({
		current: {
			year: currentYear,
			month: currentMonth,
			day: currentDate,
		},
		selected: {
			year: currentYear,
			month: currentMonth,
			day: currentDate,
		},
	})

	useEffect(() => {
		calendarDate.getTime() !==
			new Date(state.selected.year, state.selected.month - 1).getTime() &&
			selectDate(p => ({
				...p,
				calendarDate: new Date(state.selected.year, state.selected.month - 1),
			}))
	}, [state.selected])

	const leftArrowHandler = () => {
		setState(prevState => {
			let [newMonth, newYear] = lowerMonth(
				prevState.current.month,
				prevState.current.year,
				prevState.selected.month,
				prevState.selected.year
			)

			return {
				...prevState,
				selected: {
					...prevState.selected,
					month: newMonth,
					year: newYear,
				},
			}
		})
	}
	const rightArrowHandler = () => {
		setState(prevState => ({
			...prevState,
			selected: {
				...prevState.selected,
				year:
					prevState.selected.month + 1 === 13
						? prevState.selected.year + 1
						: prevState.selected.year,
				month:
					prevState.selected.month + 1 === 13
						? 1
						: prevState.selected.month + 1,
			},
		}))
	}

	return (
		<div
			className='d-flex flex-column rounded-4 p-4'
			style={{ border: '1px solid #D0D0D0' }}
		>
			<div className='d-flex flex-row mb-4'>
				<p className='me-auto' style={{ fontSize: '22px' }}>
					{FULL_MONTHS[state.selected.month - 1] + ' ' + state.selected.year}
				</p>
				<div className='d-flex flex-row my-auto'>
					<ArrowLeft
						cursor='pointer'
						className='me-sm-5 me-4'
						fill={
							state.current.month === state.selected.month
								? '#B4B4B4'
								: COLORS.primary
						}
						onClick={() =>
							state.current.month !== state.selected.month && leftArrowHandler()
						}
					/>
					<ArrowRight cursor='pointer' onClick={() => rightArrowHandler()} />
				</div>
			</div>

			<div className='row cols-7 flex-nowrap'>
				<div className='col text-center px-0'>
					<p>пн</p>
				</div>
				<div className='col text-center px-0'>
					<p>вт</p>
				</div>
				<div className='col text-center px-0'>
					<p>ср</p>
				</div>
				<div className='col text-center px-0'>
					<p>чт</p>
				</div>
				<div className='col text-center px-0'>
					<p>пт</p>
				</div>
				<div className='col text-center px-0'>
					<p>сб</p>
				</div>
				<div className='col text-center px-0'>
					<p>вс</p>
				</div>
			</div>
			<div className='row'>
				{new Date(state.selected.year, state.selected.month - 1, 1).getDay() !==
				0 ? (
					[
						...Array(
							new Date(
								state.selected.year,
								state.selected.month - 1,
								1
							).getDay() - 1
						).keys(),
					].map((d, i) => (
						<div
							className='d-flex align-items-end px-0 mb-2'
							style={{ width: '14.2857143%' }}
							key={i}
						>
							<p></p>
						</div>
					))
				) : (
					<></>
				)}

				{[
					...Array(
						daysInMonth(state.selected.month, state.selected.year)
					).keys(),
				].map((d, i) => {
					let thisDate = [
						i + 1,
						state.selected.month.toString().padStart(2, '0'),
						state.selected.year.toString(),
					].join('-')
					let hasSlots =
						Object.keys(data).indexOf(thisDate) +
							Object.keys(meetings).indexOf(thisDate) >
						-2
					return (
						<div
							className='d-flex align-items-end px-0 mb-2'
							style={{ width: '14.2857143%' }}
							key={i}
							onClick={() =>
								hasSlots &&
								selectDate(p => ({
									...p,
									slots: { ...p.slots, selectedDate: thisDate },
								}))
							}
						>
							<p
								className='m-auto d-flex align-items-center justify-content-center rounded-3'
								style={{
									width: '35px',
									height: '35px',
									cursor: !hasSlots < 0 ? 'auto' : 'pointer',
									color:
										thisDate === selectedDate ||
										(!hasSlots && thisDate === today)
											? '#fff'
											: !hasSlots
											? COLORS.header_text
											: COLORS.text,
									backgroundColor:
										thisDate === selectedDate
											? COLORS.primary
											: !hasSlots
											? thisDate === today
												? COLORS.red_primary
												: ''
											: COLORS.light_primary,
								}}
							>
								{i + 1}
							</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}

function daysInMonth(month, year) {
	// Use 1 for January, 2 for February, etc.
	return new Date(year, month, 0).getDate()
}

function lowerMonth(cMonth, cYear, selMonth, selYear) {
	if (cMonth === selMonth) {
		if (cYear === selYear) {
			return [selMonth, selYear]
		}
		selMonth -= 1
		if (selMonth === 1) {
			selYear -= 1
			selMonth = 12
		}
		return [selMonth, selYear]
	} else if (selMonth === 1) {
		selYear -= 1
		selMonth = 13
	}
	return [selMonth - 1, selYear]
}

function addZero(time) {
	if (time < 10) {
		return `0${time}`
	}

	return time
}
