import * as yup from 'yup'
import {
	showErrorMsg,
	showSuccessMsg,
} from '../../../../../store/slices/alertSlice'
import { ROLE, SOURCE } from './../../../../../constants'
import {createVacancyResponse} from "../../../../../api/vacancyResponses";

const BasicVacancyResponseSchema = yup.object({
	candidateId: yup
		.string()
		.defined()
		.length(36, 'Неверный формат id кандидата'),
	vacancyId: yup.string().defined().length(36, 'Неверный формат id вакансии'),
	_requester: yup.string().defined().oneOf([ROLE.MANAGER]),
})

export const makeVacancyResponseFromApplicant = async (
	access_token,
	vacancyId,
	candidateId,
	dispatch
) => {
	const data = {
		_requester: 'MANAGER',
		vacancyId: vacancyId,
		candidateId: candidateId,
	}

	try {
		BasicVacancyResponseSchema.validateSync(data)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}

	const ok = await createVacancyResponse(access_token, data);

	if (ok) {
		dispatch(showSuccessMsg("Все прошло успешно"))
		return true
	} else {
		dispatch(showErrorMsg("Произошла ошибка"))
	}
}