import { SOURCE } from "../constants";

export async function getAllPromocodes(token, ) {
    const response = await fetch(`${SOURCE.url}promoCodes`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    return response;
}

export async function createPromocode(token, data) {
    const response = await fetch(`${SOURCE.url}promoCodes`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data)
    })

    return response;
}

export async function patchPromocode(token, data, value) {
    const response = await fetch(`${SOURCE.url}promoCodes/` + value, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data)
    })

    return response;
}

export async function deletePromocode(token, data) {
    const response = await fetch(`${SOURCE.url}promoCodes/` + data.value , {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })

    return response;
}