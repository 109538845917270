import DataTable from 'datatables.net-dt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { getAllApplicants } from '../../api/candidates';
import { Loader } from '../../components/ui/Loader';
import { Candidates } from './Candidates';
import { CandidateScreen } from './candidateScreen/CandidateScreen';
import { NotFoundPage } from '../system/NotFoundPage';

export const CandidatesScreen = () => {
  const [state, setState] = useState({
    loaded: false,
    applicants: [],
  });
  const user = useSelector((state) => state.userSlice.user);

  // useEffect(() => {
  //     (async () => {
  //         if (!state.loaded) {
  //             const res = await getAllApplicants(user.access_token);
  //             setState(p => ({ ...p, loaded: true, applicants: res.items }))
  //         }
  //     })()
  //     new DataTable('#myTable')
  // }, [state.loaded, user.access_token, user.manager_id])

  return (
    <Routes>
      <Route path='/' element={<Candidates />} />
      <Route path='/:id' element={<CandidateScreen />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
