import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components/ui/Loader';
import { Route, Routes } from 'react-router';
import { getAllEmployers } from '../../api/employer';
import { Employers } from './Employers';
import { Employer } from './employer/Employer';
import { showErrorMsg } from '../../store/slices/alertSlice';
import { EditEmployer } from './employer/EditEmployer';
import { NotFoundPage } from '../system/NotFoundPage';

export const EmployersScreen = () => {
  return (
    <Routes>
      <Route path='/' element={<Employers />} />
      <Route path='/:id' element={<Employer />} />
      <Route path='/:id/edit' element={<EditEmployer />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
