import { useNavigate } from 'react-router';
import { COLORS } from '../../constants';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className='m-auto py-5 h-50 justify-content-center text-center'>
      <p
        style={{ color: COLORS.primary, fontSize: '120px', fontWeight: '600' }}
      >
        404
      </p>
      <p style={{ color: COLORS.text, fontSize: '23px', fontWeight: '500' }}>
        Кажется, вы заблудились...{' '}
      </p>
      <p
        className='mb-5'
        style={{ color: COLORS.text, fontSize: '23px', fontWeight: '500' }}
      >
        Такой страницы не существует, либо мы опять где-то накосячили, эх...
      </p>
      <button
        onClick={() => navigate('/')}
        className='btn btn-md p-3 mx-auto mt-3'
        style={{
          maxWidth: '250px',
          backgroundColor: COLORS.secondary,
          color: COLORS.primary,
        }}
      >
        Перейти на главную
      </button>
    </div>
  );
};
