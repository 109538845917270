import { SOURCE, ROLE } from "../constants";

export async function getMe(token) {
    const response = await fetch(`${SOURCE.url}managers/me`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token

        },
    })

    return response;
}

export async function createAccessToken(role, state) {
    const option = state.hasAccount ? {} : {
        name: state.name,
        access_level: [0,1,2,3,4,5,6,7,8,9]
    }

    const response = await fetch(`${SOURCE.url}auth/accessToken/?role=${ROLE.MANAGER}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            login: state.login,
            password: state.password,
            ...option
        })
    })

    if (!response.ok) {
        return false
    }

    const json = await response.json();
    return json;
}

