import { Modal } from "react-bootstrap";
import { COLORS, datePlusTime } from "../../constants";

export const MeetingInfoModal = ({ state, setState, user }) => {
    return (<Modal centered contentClassName='border-0 rounded-4 rounded-md-5 px-sm-4 px-2 py-4' size='lg' show={state.shown}
        onHide={() => setState(p => ({ ...p, meetingModal: { meeting: {}, shown: false }  }))}>
        <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
            <Modal.Title className='fs-4' style={{ fontWeight: '600' }}>{state.meeting.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex flex-row justify-content-between mb-2'>
                <p className='fs-5'>Дата и время</p>
                <div className='rounded-3 py-1 px-3' style={{ color: COLORS.primary, border: '1px solid ' + COLORS.primary }}>{Object.entries(state.meeting).length !== 0 && datePlusTime(state.meeting.time)}</div>
            </div>
            <div className='d-flex flex-row justify-content-between'>
                <p className='fs-5'>Менеджер</p>
                <div className='rounded-3 py-1 px-3 fs-6' style={{ color: COLORS.primary, border: '1px solid ' + COLORS.primary }}>{ user.name }</div>
            </div>
            <hr className='my-4' />
            <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>Что будет на встрече</p>
            <p className='fs-6' style={{ whiteSpace: 'pre-line' }}>{state.meeting.description}</p>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: '0 none' }}>
            <div onClick={() => window.open(state.meeting.link)}
                className='py-2 px-5 rounded-3 justify-content-center success' style={{ backgroundColor: COLORS.success, color: '#fff', cursor: 'pointer' }}>
                <img alt='' className='me-3' src={require('../../icons/sberjazz.svg').default} />Подключиться</div>
        </Modal.Footer>
    </Modal>)
}