import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router"
import { AppInput } from "../../components/ui/AppInput"
import { COLORS, ErrorMsg, VALIDATE_ERROR, USER_ERROR_MESSAGES, Validation } from "../../constants"
import { setAuth } from "../../store/slices/userSlice"
import { createAccessToken } from "../../api/auth"
import { showErrorMsg } from "../../store/slices/alertSlice"

export const AuthScreen = () => {
    const [state, setState] = useState({
        hasAccount: true,
        login: '',
        password: '',
        name: '',
        errorMsg: '',
        passwordVisible: false,
        showAlerts: false
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user  = useSelector(state => state.userSlice.user)

    if (user.loaded && user.access_token) return <Navigate to='/' />
    

    return <div className="d-flex justify-content-center w-100 my-auto my-5 py-sm-5">
        <form onSubmit={e => e.preventDefault()} className="d-flex flex-column justify-content-center align-items-cemter px-3 w-100" style={{ maxWidth: '750px' }}>
            <p className="text-center" style={{ fontSize: '34px', fontWeight: '600' }} >{ state.hasAccount ? 'Вход' : 'Регистрация' }</p>
            <div className="d-flex flex-column my-4">
                <AppInput className='mb-4' label='Логин' value={state.login} showAlert={ state.login.length < 4} onChange={v => setState(prev => ({ ...prev, login: v}))} />
                <AppInput className='mb-4' label='Пароль' showEye={true} showAlert={state.password.length < 4} switchEye={() => setState(p => ({ ...p, passwordVisible: !p.passwordVisible }))} type={ state.passwordVisible ? 'text' : 'password'}
                    value={state.password} onChange={v => setState(prev => ({ ...prev, password: v}))} />
                {!state.hasAccount && <AppInput className='mb-4' label='Имя' showAlert={state.showAlerts && state.name === '' && !state.hasAccount} value={state.name} onChange={v => setState(prev => ({ ...prev, name: v}))} />}
            </div>
            <div className="d-flex flex-row flex-wrap-reverse align-items-center">
                    {/* <div className="d-flex flex-column mb-2">
                        { state.hasAccount ? <p className="mb-3" style={{ color: COLORS.primary, cursor: 'pointer' }} onClick={() => setState(p => ({ ...p, hasAccount: false }))}>Зарегистрироваться</p> :
                        <p className="mb-3" style={{ color: COLORS.primary, cursor: 'pointer' }} onClick={() => setState(p => ({ ...p, hasAccount: true }))}>Уже есть аккаунт</p> }
                        <p style={{ color: COLORS.danger, cursor: 'pointer' }}>Забыли пароль?</p>
                    </div> */}
                <button className='btn primary py-2 w-100 mb-2 ms-auto' onClick={() => login(state, setState, navigate, dispatch, user)}
                    style={{ backgroundColor: COLORS.primary, maxWidth: '200px', color: '#fff', height: '45px' }}>{ state.hasAccount ? 'Войти' : 'Продолжить'}</button>
            </div>
        </form>
    </div>
}


async function login(state, setState, navigate, dispatch, user) {
    if (state.login.length < 4) {
        return dispatch(showErrorMsg(VALIDATE_ERROR.LOGIN))
    } 
    
    if (state.password.length < 4) {
        return dispatch(showErrorMsg(VALIDATE_ERROR.PASSWORD))
    } 
    
    if (state.name === '' && !state.hasAccount) {
        return dispatch(showErrorMsg(VALIDATE_ERROR.NAME))
    } 

    try {
        const response = await createAccessToken(user.role, state)

        if (!response){
            return dispatch(showErrorMsg( state.hasAccount ? USER_ERROR_MESSAGES.WRONG_LOG_OR_PAS : USER_ERROR_MESSAGES.USER_REGISTERED ))
        }

        if (response.token) {
            dispatch(setAuth({ access_token: response.token }))
            return navigate('/')
        }

        return dispatch(showErrorMsg( USER_ERROR_MESSAGES.UNKNOWN))
    } catch (error) {
        dispatch(showErrorMsg(USER_ERROR_MESSAGES.ERROR_SERVER))     
    }
}


