// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CandidateScreen_Navbar__-J7B5 {
  display: flex;
  width: 100%;
  background: #fff;
  margin: 23px 0 27px 0;
  padding: 22px 27px;
  border-radius: 7px;
}

.CandidateScreen_Button__XI1OV {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.45px;
  padding: 10px 23px;
  background: none;
  border-radius: 5px;
}
.CandidateScreen_ButtonBack__rYEX8 {
  background: none;
}
.CandidateScreen_ButtonActive__wyICv {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.45px;
  padding: 10px 23px;
  border-radius: 5px;
  background: #E8E8EC;
}`, "",{"version":3,"sources":["webpack://./src/screens/candidates/candidateScreen/CandidateScreen.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AAAN","sourcesContent":[".Navbar {\n    display: flex;\n    width: 100%;\n    background: #fff;\n    margin: 23px 0 27px 0;\n    padding: 22px 27px;\n    border-radius: 7px;\n  }\n  \n  .Button {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 20.45px;\n    padding: 10px 23px;\n    background: none;\n    border-radius: 5px;\n  \n    &Back {\n      background: none;\n    }\n  \n    &Active {\n      display: flex;\n      align-items: center;\n      gap: 12px;\n      font-size: 16px;\n      font-weight: 600;\n      line-height: 20.45px;\n      padding: 10px 23px;\n      border-radius: 5px;\n      background: #E8E8EC;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navbar": `CandidateScreen_Navbar__-J7B5`,
	"Button": `CandidateScreen_Button__XI1OV`,
	"ButtonBack": `CandidateScreen_ButtonBack__rYEX8`,
	"ButtonActive": `CandidateScreen_ButtonActive__wyICv`
};
export default ___CSS_LOADER_EXPORT___;
