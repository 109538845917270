import {SOURCE} from "../constants";

export async function createMeetingFeedback(access_token, text, meetingId, name = "Отзыв о встрече") {
    const res = await fetch(SOURCE.url + "meetingFeedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({name, text, meetingId})
    });
    return await res.json();
}

export async function patchMeetingFeedback(access_token, id, text, meetingId, name) {
    const res = await fetch(SOURCE.url + `meetingFeedback/${id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({name, text, meetingId})
    });
    return await res.json();
}