import {DescriptionExperience} from './DescriptionExperience';
import { ListLanguages } from './ListLanguages';
import { HeaderPage } from './HeaderPage'
import { ListSkills } from './ListSkills';
import {TitleSection} from './TitleSection';
import styles from './styles.module.css'
import { getObjContact } from '../utils';

export const PageOne = ({props, applicant}) => {
    const {
        title,
        contacts,
        summary,
        education,
        skills,
        experience,
        languages,
    } = props;

    const {
        firstName,
        lastName
    } = applicant;
    const contactOjb = getObjContact(contacts)
    
    return (
            <div className='container' style={{maxWidth: '700px', padding: '10px 15px'}}>
                <HeaderPage
                    mail={contactOjb.EMAIL}
                    phone={contactOjb.PHONE}
                    firstname={firstName}
                    lastname={lastName}
                    profession={title}
                />
                <div style={{paddingLeft: '35px'}}>
                    {summary && 
                    <div className='row mb-2'>
                        <p className='col fs-6 fw-normal'
                        style={{color: '#777777'}}>{summary}</p>
                    </div>}
                    {experience?.length ? 
                    <TitleSection title='ОПЫТ РАБОТЫ'/> : null}
                    {experience?.map((elem) => {
                        return <DescriptionExperience
                            key={elem.id}
                            company_name={elem.company}
                            title_job={elem.position}
                            experience_start_year={elem.startYear}
                            experience_end_year={elem.endYear}
                            experience_start_month={elem.startMonth}
                            experience_end_month={elem.endMonth}
                            description_experience={elem.description}
                        />
                    })}
                    {education?.length ?
                    <TitleSection title='ОБРАЗОВАНИЕ'/> : null}
                    {education?.map(education => {
                        return (
                            <div key={education.id} className={`row gy-1 ${styles.wrapper}`}>
                                <p className='fs-6 fw-bold'>{education.name}</p>
                                <p className='fs-6' style={{color: '#777777'}}>{education.description}</p>
                            </div>
                        )
                    })}
                    {languages?.length ? 
                    <ListLanguages
                        title_section="Языки"
                        list_languages={languages}
                    /> : null}
                    {skills?.length ?
                    <ListSkills
                        title_section='Навыки'
                        listSkills={skills}
                    /> : null}
                </div>
            </div>
    )
}
