import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchApplicantRegexp } from '../../../constants';
import { setFilters } from '../../../store/slices/tableSlice';
import { showErrorMsg } from '../../../store/slices/alertSlice';

function cleanSpaces(input) {
  return input.trimStart().replace(/\s+/g, ' ');
}

export const Search = () => {
  const filters = useSelector((state) => state.tableSlice.filters);
  const [text, setText] = useState(filters.search);

  const dispatch = useDispatch();

  const setSearch = (input) => {
    const search = cleanSpaces(input);

    if (!search || searchApplicantRegexp.test(search)) {
      setText(search);

      dispatch(
        setFilters({
          search: search.trim(),
        })
      );
    } else {
      dispatch(showErrorMsg('до 100 символов, до 7 слов'));
    }
  };

  return (
    <input
      className='form-control'
      placeholder='search...'
      style={{
        paddingTop: '0',
        paddingBottom: '0',
        height: '2.35rem',
      }}
      value={text}
      id='search'
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};
