import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounceCallback } from '../../constants'
import { AppSelect } from '../ui/AppSelect'
import { Loader } from '../ui/Loader'
import { useRowsData } from './hooks'

export const DataTable = ({
	Template,
	getMethod,
	Wrapper,
	SearchAndFilter,
	filterObject,
	clearFilters,
	meetings,
}) => {
	const user = useSelector(state => state.userSlice.user)

	const [dataState, setDataState] = useState({
		page: 1,
		size: 10,
		items: [],
	})
	const [state, setState] = useState({
		total: 0,
		totalPages: 0,
		load: false,
	})
	const [paginationPage, setPaginationPage] = useState(dataState.page)

	useEffect(() => {
		if (+paginationPage > 0) {
			if (paginationPage >= state.totalPages)
				setDataState(p => ({ ...p, page: state.totalPages }))
			else setDataState(p => ({ ...p, page: +paginationPage }))
		}
	}, [paginationPage])

	const { rows } = useRowsData(dataState)

	useEffect(() => {
		setDataState(p => ({ ...p, page: 1 }))
	}, [dataState.size])

  const firstUpdate = useRef(true);

	useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

		loadData()
	}, [dataState.page, dataState.size])

	const prevObjectRef = useRef()

	useEffect(() => {
		const prevObjectString = JSON.stringify(prevObjectRef.current)
		const currentObjectString = JSON.stringify(filterObject)

		if (prevObjectString !== currentObjectString) {
			onFilter()
			console.log('Object has changed:', filterObject)
		}

		prevObjectRef.current = filterObject
	}, [filterObject])

	const onFilter = useDebounceCallback(() => {
		setDataState(p => ({ ...p, page: 1 }))
    console.log('onFilter')
		loadData()
	}, 500)

	const clearFiltersUp = () => {
		clearFilters()
	}

	const loadData = () => {
    console.log('load data')
		setState(p => ({ ...p, load: false }))

		getMethod(
			user.access_token,
			filterObject,
			dataState.page,
			dataState.size
		).then(res => {
			setDataState(p => ({ ...p, items: res.items }))
			setState({
				total: res.total,
				totalPages: res.totalPages,
				load: true,
			})
		})
	}

	return (
		<>
			<div className='d-flex justify-content-between'>
				<div className='form-floating mb-3'>
					<p className='fs-6'>Размер таблицы</p>
					<AppSelect
						style={{ width: '90px' }}
						className='mt-1'
						onChange={e => setDataState(p => ({ ...p, size: +e.target.value }))}
						value={dataState.size}
						options={[
							{
								key: 80,
								value: 80,
							},
							{
								key: 50,
								value: 50,
							},
							{
								key: 25,
								value: 25,
							},
							{
								key: 10,
								value: 10,
							},
							{
								key: 5,
								value: 5,
							},
						]}
						name='pageSize'
					/>
				</div>
				{SearchAndFilter && <SearchAndFilter clearFilters={clearFiltersUp} />}
			</div>
			<Wrapper>
				{!state.load ? (
					<Loader />
				) : rows ? (
					rows.length ? (
						rows.map((el, i) => (
							<Template u={el} key={i} i={i} meetings={meetings} />
						))
					) : (
						<>Нет пользователей на этой странице</>
					)
				) : (
					<>Пользователей меньше, чем size</>
				)}
			</Wrapper>
			<div className='d-flex'>
				<Paginator
					state={state}
					dataState={dataState}
					setDataState={setDataState}
				/>
				<div className='form-floating w-25 ms-3'>
					<input
						className='form-control'
						style={{
							paddingTop: '0',
							paddingBottom: '0',
							height: '2.35rem',
							width: '15%',
						}}
						value={paginationPage}
						id='pageNum'
						onChange={e => {
							if (+e.target.value >= state.totalPages)
								setPaginationPage(state.totalPages)
							else setPaginationPage(e.target.value)
						}}
					/>
				</div>
			</div>
		</>
	)
}

const Paginator = ({ state, dataState, setDataState }) => {
	const canPrev = dataState.page > 1
	const canNext = dataState.page < state.totalPages

	const showFirst = dataState.page > 2
	const showFirstDots = dataState.page > 3

	const showLast = dataState.page < state.totalPages - 1
	const showLastDots = dataState.page < state.totalPages - 2

	const putPage = value => setDataState(p => ({ ...p, page: value }))
	const firstPage = () => putPage(1)
	const lastPage = () => putPage(state.totalPages)

	const changePage = delay =>
		setDataState(p => ({ ...p, page: dataState.page + delay }))
	const prevPage = () => canPrev && changePage(-1)
	const nextPage = () => canNext && changePage(1)

	return (
		<nav>
			<ul className='pagination' style={{ marginBottom: '0' }}>
				<li
					style={{ cursor: 'pointer' }}
					className={`page-item user-select-none ${canPrev ? '' : 'disabled'}`}
					onClick={prevPage}
				>
					<p className='page-link'>Предыдущая</p>
				</li>
				{showFirst && (
					<>
						<li className='page-item'>
							<p className='page-link' onClick={firstPage}>
								1
							</p>
						</li>
						{showFirstDots && (
							<li className='page-item user-select-none disabled'>
								<p className='page-link'>...</p>
							</li>
						)}
					</>
				)}

				{canPrev && (
					<li className='page-item'>
						<p className='page-link' onClick={prevPage}>
							{dataState.page - 1}
						</p>
					</li>
				)}

				<li
					className='page-item active'
					style={{ cursor: 'pointer' }}
					aria-current='page'
				>
					<p className='page-link'>{dataState.page}</p>
				</li>

				{canNext && (
					<li
						className='page-item'
						style={{ cursor: 'pointer' }}
						onClick={nextPage}
					>
						<p className='page-link'>{dataState.page + 1}</p>
					</li>
				)}
				{showLast && (
					<>
						{showLastDots && (
							<li className='page-item user-select-none disabled'>
								<p className='page-link'>...</p>
							</li>
						)}
						<li className='page-item' onClick={lastPage}>
							<p className='page-link'>{state.totalPages}</p>
						</li>
					</>
				)}
				<li
					className={`page-item user-select-none ${canNext ? '' : 'disabled'}`}
					onClick={nextPage}
					style={{ cursor: 'pointer' }}
				>
					<p className='page-link'>Следующая</p>
				</li>
			</ul>
		</nav>
	)
}
