import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './EditVacancy.module.scss';
import {
  COLORS,
  employmentTypeOptions,
  labelsByEmploymentType,
  labelsByExperience,
  labelsBySalaryCurrency,
  labelsByVacancyStatus,
  labelsByWorkplaceModel,
} from '../../../../constants';
import { ReactComponent as DeleteOutline } from '../../../../icons/delete-outline.svg';
import { ReactComponent as PlusSvg } from '../../../../icons/plus_circle.svg';
import { ReactComponent as ArrowDown } from '../../../../icons/arrow-down.svg';
import CompanyCard from '../../../business/employer/myCompanyCard/CompanyCard';
import TextareaAutosize from '../../../../components/ui/TextareaAutosize';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import InputAutosize from '../../../../components/ui/InputAutosize';
import {
  deleteVacancy,
  editVacancy,
  getAllVacancies,
  getVacancy,
} from '../../../../api/vacancies';
import {
  showErrorMsg,
  showSuccessMsg,
} from '../../../../store/slices/alertSlice';

import { AppSelect } from '../../../../components/ui/AppSelect';
import { Loader } from '../../../../components/ui/Loader';
import { NotFoundPage } from '../../../system/NotFoundPage';

// import Select from "react-select";

export const EditVacancy = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    vacancy: {},
    patchVacancy: {},
    loaded: false,
    notFound: false,
  });
  const mdEditorRef = useRef();

  const saveBtnHandler = async () => {
    const res = await editVacancy(user.access_token, id, {
      ...state.patchVacancy,
      description: mdEditorRef.current.getMarkdown(),
    });
    if (!res) return dispatch(showErrorMsg('Ошибка при сохранении'));
    setState((p) => ({
      ...p,
      vacancy: { ...p.vacancy, ...state.patchVacancy },
    }));
    dispatch(showSuccessMsg('Сохранено'));
  };

  const deleteBtnHandler = async () => {
    if (
      window.confirm(
        `Вы действительно хотите удалить вакансию ${state.patchVacancy.name}?`
      )
    ) {
      const result = await deleteVacancy(user.access_token, id);
      if (!result) return dispatch(showErrorMsg('Ошибка'));
      navigate(-1);
    }
  };

  useEffect(() => {
    (async () => {
      const vacancy = await getVacancy(user.access_token, id, {
        include: ['employer'],
      });
      if (!vacancy)
        return dispatch(showErrorMsg('Ошибка при загрузке вакансии'));
      if (vacancy == 404)
        setState((p) => ({
          ...p,
          notFound: true,
        }));
      setState((p) => ({
        ...p,
        loaded: true,
        vacancy,
        patchVacancy: {
          city: vacancy?.city,
          experience: vacancy?.experience,
          name: vacancy?.name,
          shortDescription: vacancy?.shortDescription,
          salary: vacancy?.salary,
          salaryCurrency: vacancy?.salaryCurrency,
          employmentType: vacancy?.employmentType,
          workplaceModel: vacancy?.workplaceModel,
          keySkills: vacancy?.keySkills || [''],
          teamRole: vacancy?.teamRole,
          reportingForm: vacancy?.reportingForm,
          workingHours: vacancy?.workingHours,
          status: vacancy?.status,
          _requester: 'MANAGER',
        },
      }));
    })();
  }, [user.access_token]);

  if (state.notFound) {
    return <NotFoundPage />;
  }

  return !state.loaded ? (
    <Loader />
  ) : (
    <div className='row'>
      <div className='col-lg-4 d-flex flex-column gap-3 pb-3'>
        <CompanyCard employer={state.vacancy.employer} canEdit={false} />
        <ul className={classes.VacancyParameters + ' p-4 p-xl-5'}>
          <li className='d-flex w-100 flex-row'>
            {state.patchVacancy?.city && (
              <p style={{ fontWeight: '600' }}>
                Локация: {state.patchVacancy.city}
              </p>
            )}
            {/* <ParamAdvSelect isSearchable={true} options={citiesAvailable} placeholder="указать" value={{label: state.patchVacancy.city, value: state.patchVacancy.city}}
                                    onChange={v => setState(p => ({...p, patchVacancy: {...p.patchVacancy, city: v.value}}))} /> */}
            {/*<InputAutosize type="text" className={classes.ParameterInput} placeholder="указать" value={state.patchVacancy.city}*/}
            {/*       onChange={e => setState(p => ({...p, patchVacancy: {...p.patchVacancy, city: e.target.value}}))} minWidth="1ch" defaultWidth="7ch"/>*/}
          </li>
          <li className='d-flex w-100 flex-row'>
            <p style={{ fontWeight: '600' }}>Формат:&nbsp;</p>
            <select
              className={`${classes.ParameterSelect} ${
                !state.patchVacancy.workplaceModel ? classes.Underline : ''
              }`}
              value={state.patchVacancy.workplaceModel}
              onChange={(e) =>
                setState((p) => ({
                  ...p,
                  patchVacancy: {
                    ...p.patchVacancy,
                    workplaceModel: e.target.value,
                  },
                }))
              }
            >
              <option
                disabled={true}
                selected={!state.patchVacancy.workplaceModel}
              >
                выбрать
              </option>
              {Object.keys(labelsByWorkplaceModel).map((opt) => (
                <option key={opt} value={opt}>
                  {labelsByWorkplaceModel[opt]}
                </option>
              ))}
            </select>
          </li>
          <li className='d-flex w-100 flex-row'>
            <p style={{ fontWeight: '600' }}>Занятость:&nbsp;</p>
            <select
              className={`${classes.ParameterSelect} ${
                !state.patchVacancy.employmentType ? classes.Underline : ''
              }`}
              value={state.patchVacancy.employmentType}
              onChange={(e) =>
                setState((p) => ({
                  ...p,
                  patchVacancy: {
                    ...p.patchVacancy,
                    employmentType: e.target.value,
                  },
                }))
              }
            >
              <option
                disabled={true}
                selected={!state.patchVacancy.employmentType}
              >
                выбрать
              </option>
              {Object.keys(labelsByEmploymentType).map((opt) => (
                <option key={opt} value={opt}>
                  {labelsByEmploymentType[opt]}
                </option>
              ))}
            </select>
          </li>
          <li className='d-flex w-100 flex-row'>
            <p style={{ fontWeight: '600' }}>Опыт:&nbsp;</p>
            <select
              className={`${classes.ParameterSelect} ${
                !state.patchVacancy.experience ? classes.Underline : ''
              }`}
              value={state.patchVacancy.experience}
              onChange={(e) =>
                setState((p) => ({
                  ...p,
                  patchVacancy: {
                    ...p.patchVacancy,
                    experience: e.target.value,
                  },
                }))
              }
            >
              <option disabled={true} selected={!state.patchVacancy.experience}>
                выбрать
              </option>
              {Object.keys(labelsByExperience).map((opt) => (
                <option key={opt} value={opt}>
                  {labelsByExperience[opt]}
                </option>
              ))}
            </select>
          </li>
          <li className='d-flex w-100 flex-row'>
            <p style={{ fontWeight: '600' }}>Зарплата:&nbsp;</p>
            <InputAutosize
              type='text'
              className={classes.ParameterInput}
              placeholder='указать'
              value={
                state.patchVacancy.salary
                  ? state.patchVacancy.salary.toLocaleString()
                  : null
              }
              minWidth='1ch'
              defaultWidth='7ch'
              onChange={(e) => {
                const val = e.target.value
                  ? +e.target.value.replace(/\D/g, '')
                  : 0;
                setState((p) => ({
                  ...p,
                  patchVacancy: { ...p.patchVacancy, salary: val },
                }));
              }}
            />
            {state.patchVacancy.salary ? (
              <>
                &nbsp;
                <select
                  className={`${classes.ParameterSelect} ${
                    !state.patchVacancy.salaryCurrency ? classes.Underline : ''
                  }`}
                  style={{ width: '8ch' }}
                  value={state.patchVacancy.salaryCurrency}
                  onChange={(e) =>
                    setState((p) => ({
                      ...p,
                      patchVacancy: {
                        ...p.patchVacancy,
                        salaryCurrency: e.target.value,
                      },
                    }))
                  }
                >
                  <option
                    disabled={true}
                    selected={!state.patchVacancy.salaryCurrency}
                  >
                    валюта
                  </option>
                  {Object.keys(labelsBySalaryCurrency).map((opt) => (
                    <option key={opt} value={opt}>
                      {labelsBySalaryCurrency[opt]}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              ''
            )}
          </li>

          {/* <li className="d-flex w-100 flex-row">
                    <p style={{ fontWeight: '600' }}>Роль в команде:&nbsp;</p>
                    <select className={`${classes.ParameterSelect} ${!state.patchVacancy.teamRole ? classes.Underline : ""}`}
                            value={state.patchVacancy.teamRole} onChange={e => setState(p => ({...p, patchVacancy: {...p.patchVacancy, teamRole: e.target.value}}))}>
                        <option disabled={true} selected={!state.patchVacancy.teamRole}>выбрать</option>
                        {employmentTypeOptions[4].map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                    </select>
                </li>
                 <li className="d-flex w-100 flex-row">
                    <p style={{ fontWeight: '600' }}>Кому отчитываться:&nbsp;</p>
                    <select className={`${classes.ParameterSelect} ${!state.patchVacancy.reportingForm ? classes.Underline : ""}`}
                            value={state.patchVacancy.reportingForm} onChange={e => setState(p => ({...p, patchVacancy: {...p.patchVacancy, reportingForm: e.target.value}}))}>
                        <option disabled={true} selected={!state.patchVacancy.reportingForm}>выбрать</option>
                        {employmentTypeOptions[2].map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                    </select>
                </li> 
                <li className="d-flex w-100 flex-row">
                    <p style={{ fontWeight: '600' }}>График:&nbsp;</p>
                    <select className={`${classes.ParameterSelect} ${!state.patchVacancy.workingHours ? classes.Underline : ""}`}
                            value={state.patchVacancy.workingHours} onChange={e => setState(p => ({...p, patchVacancy: {...p.patchVacancy, workingHours: e.target.value}}))}>
                        <option disabled={true} selected={!state.patchVacancy.workingHours}>выбрать</option>
                        {employmentTypeOptions[5].map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                    </select>
                </li> */}
        </ul>

        <div className='d-flex w-sm-max rounded flex-row bg-white py-2 px-3 roudned'>
          <p className='fw-bolded fs-5 me-3 my-auto'>Статус:&nbsp;</p>
          <select
            className={classes.ParameterSelect + ' p-1'}
            value={state.patchVacancy.status}
            style={{ border: '1px solid ' + COLORS.gray_text }}
            onChange={(e) =>
              setState((p) => ({
                ...p,
                patchVacancy: { ...p.patchVacancy, status: e.target.value },
              }))
            }
          >
            {Object.keys(labelsByVacancyStatus).map((opt) => (
              <option key={opt} value={opt}>
                {labelsByVacancyStatus[opt]}
              </option>
            ))}
          </select>
          <ArrowDown className='my-auto' style={{ marginLeft: '-20px' }} />
        </div>
        <button className='btn success' onClick={saveBtnHandler}>
          Сохранить вакансию
        </button>
        <button className='btn red_primary_outline' onClick={deleteBtnHandler}>
          Удалить вакансию
        </button>
      </div>
      <div className='col-lg-8'>
        <div className={classes.VacancyDescBlock + ' p-4 p-xl-5'}>
          <input
            type='text'
            className='w-100 fs-3 no_outline fw-semibold'
            placeholder='Наименование'
            value={state.patchVacancy.name}
            onChange={(e) =>
              setState((p) => ({
                ...p,
                patchVacancy: { ...p.patchVacancy, name: e.target.value },
              }))
            }
          />
          <div className='d-flex flex-row flex-wrap gap-3 align-items-center'>
            {(state.patchVacancy.keySkills || []).map((s, i) => (
              <KeySkill key={i} i={i} s={s} setState={setState} />
            ))}
            <PlusSvg
              style={{ cursor: 'pointer' }}
              className='ms-2'
              onClick={() =>
                setState((p) => ({
                  ...p,
                  patchVacancy: {
                    ...p.patchVacancy,
                    keySkills: [...p.patchVacancy.keySkills, ''],
                  },
                }))
              }
            />
          </div>
          <TextareaAutosize
            minHeight='1.5rem'
            defaultHeight='1.5em'
            className='no_outline fs-6 fw-medium'
            value={state.patchVacancy.shortDescription}
            placeholder='Введите сюда краткое описание вакансии'
            onChange={(e) =>
              setState((p) => ({
                ...p,
                patchVacancy: {
                  ...p.patchVacancy,
                  shortDescription: e.target.value,
                },
              }))
            }
          />
          <MDXEditor
            markdown={state.vacancy.description || ''}
            style={{}}
            placeholder='Описание вакансии'
            plugins={[
              headingsPlugin(),
              quotePlugin(),
              listsPlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <CodeToggle />
                    <ListsToggle />
                    <BlockTypeSelect />
                  </>
                ),
              }),
            ]}
            contentEditableClassName={classes.DescriptionField + ' p-0 '}
            ref={mdEditorRef}
          />
        </div>
      </div>
    </div>
  );
};

const KeySkill = ({ s, i, setState }) => {
  return (
    <span className='delete-parent delete-not-mobile'>
      <button
        className='rounded-start p-2'
        style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
        onClick={() =>
          setState((p) => ({
            ...p,
            patchVacancy: {
              ...p.patchVacancy,
              keySkills: p.patchVacancy.keySkills.filter((s, idx) => idx !== i),
            },
          }))
        }
      >
        <DeleteOutline fill='black' />
      </button>
      <InputAutosize
        type='text'
        className='fw-semibold px-3 py-2 rounded-end no_outline'
        style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
        placeholder='Навык'
        minWidth='4ch'
        defaultWidth='5.25rem'
        value={s}
        onChange={(e) => {
          setState((p) => ({
            ...p,
            patchVacancy: {
              ...p.patchVacancy,
              keySkills: p.patchVacancy.keySkills.map((s, idx) =>
                idx === i ? e.target.value : s
              ),
            },
          }));
        }}
      />
    </span>
  );
};

// const ParamAdvSelect = ({...props}) => {
//     return <Select theme={t => ({...t, colors: {...t.colors, primary: "transparent"}})} styles={{
//         control: styles => ({...styles, outline: "none", border: "none", fontSize: "1rem", flexGrow: 1, minHeight: "fit-content", minWidth: "150px", cursor: "pointer"}),
//         input: styles => ({...styles, padding: 0, margin: 0}),
//         valueContainer: styles => ({...styles, padding: 0}),
//         placeholder: styles => ({...styles, textDecoration: "underline", color: "black"}),
//         indicatorsContainer: styles => ({...styles, display: "none"})
//     }} {...props} />
// }
