import { SOURCE } from '../constants';

export async function getAllApplicants(token, queryObj = {}, page, size) {
  const params = new URLSearchParams();
  if (page) params.append('page', page);
  if (size) params.append('size', size);

  const array = ['resume'];

  for (const [key, value] of Object.entries(queryObj)) {
    if (value) {
      params.append(key, value);
    }
  }

  array.forEach((value, index) => {
    params.append('include', value);
  });

  const response = await fetch(
    `${SOURCE.url}applicants?` + new URLSearchParams(params),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function deleteAccount(id, token) {
  const response = await fetch(`${SOURCE.url}applicants/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    return false;
  }

  if (response.status === 204) return true;

  const json = await response.json();
  return json;
}

export async function getApplicant(id, token, queryObj = {}) {
  let query = '?';
  queryObj.include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });

  const response = await fetch(
    `${SOURCE.url}applicants/${id}${query === '?' ? '' : query}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function updateApplicant(id, token, newDate) {
  const response = await fetch(`${SOURCE.url}applicants/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(newDate),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  if (response.status === 204) return;

  const json = await response.json();
  return json;
}

export async function getAvatarCandidate(id, token) {
  const response = await fetch(`${SOURCE.url}applicants/${id}/avatar`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const json = await response.json();
  return json;
}

export async function uploadFile(id, token, file) {
  let formData = new FormData();
  formData.append('file', file, file.name);

  const response = await fetch(`${SOURCE.url}applicants/${id}/avatar`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: formData,
  });

  return response.ok;
}
