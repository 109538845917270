import { getPage } from '../../store/slices/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export const useRowsData = (
	initialState = {
		page: 1,
		size: 20,
		items: [],
	}
) => {
	const table = useSelector(state => state.tableSlice.table)
	const [state, setState] = useState({
		rows: [],
	})

	const user = useSelector(state => state.userSlice.user)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			getPage({
				items: initialState.items,
				page: initialState.page,
				size: initialState.size,
			})
		)
	}, [dispatch, initialState, user.access_token])
	useEffect(() => {
		if (table) setState(p => ({ ...p, rows: table.current }))
	}, [table])

	return state
}