import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { Candidate } from './Candidate';
import { getApplicant } from '../../../api/candidates';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../components/ui/Loader';
import { showErrorMsg } from '../../../store/slices/alertSlice';
import { getResume } from '../../../api/resume';
import { ReactComponent as MoreInfo } from '../../../icons/moreInfo.svg';
import { ReactComponent as ArrowBackSvg } from '../../../icons/arrow_back.svg';
import classes from './CandidateScreen.module.scss';
import { NotFoundPage } from '../../system/NotFoundPage';

export const CandidateScreen = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.userSlice.user);
  const loc = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loaded: false,
    applicant: {},
    resume: {},
    meetings: [],
    notFound: false,
  });

  useEffect(() => {
    async function fetchData() {
      const applicant = await getApplicant(id, user.access_token, {
        include: ['resume', 'meetings', 'vacancyResponses'],
      });
      if (applicant === 404) {
        setState((p) => ({ ...p, notFound: true }));
        dispatch(showErrorMsg('Произошла ошибка'));
        return;
      }
      let resume = {};
      if (applicant?.resume?.id) {
        resume = await getResume(applicant.resume.id, user.access_token, {
          include: [
            'certificates',
            'contacts',
            'education',
            'experience',
            'languages',
          ],
        });
        if (resume === 404) resume = {};
        resume.experience = (resume.experience || []).map((ex) => ({
          ...ex,
          startMonth: ex.startMonth
            ? ex.startMonth.toString().padStart(2, '0')
            : null,
          endMonth: ex.endMonth
            ? ex.endMonth.toString().padStart(2, '0')
            : null,
        }));
        setState((p) => ({
          ...p,
          resume: resume,
          loaded: true,
          meetings: applicant.meetings
            ? applicant.meetings.sort((a, b) => b.id - a.id)
            : [],
          applicant: applicant,
        }));
      } else
        setState((p) => ({
          ...p,
          resume: {},
          loaded: true,
        }));
    }
    if (user.access_token !== undefined) fetchData();
  }, [id, user.access_token]);

  const onBack = () => {
    navigate(-1);
  };

  if (state.notFound) {
    return <NotFoundPage />;
  }

  return !state.loaded ? (
    <Loader />
  ) : (
    <>
      <div className={classes.Navbar}>
        <button
          onClick={onBack}
          className={classes.ButtonBack + ' pe-md-4 pe-3'}
        >
          <ArrowBackSvg />
        </button>
        <button
          onClick={() => navigate('/candidates/' + id)}
          className={
            !loc.pathname.includes('neuro')
              ? classes.ButtonActive
              : classes.Button
          }
        >
          <MoreInfo />
          <span className='d-md-block d-none'>Резюме</span>
        </button>
      </div>
      <Routes>
        <Route
          path='/'
          element={
            <Candidate
              applicant={state.applicant}
              resume={state.resume}
              meetings={state.meetings}
              setState={setState}
            />
          }
        />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
