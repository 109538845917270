import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import alertSlice from './slices/alertSlice'
import tableSlice from './slices/tableSlice'

export default configureStore({
  reducer: {
    userSlice: userSlice,
    alertSlice: alertSlice,
    tableSlice: tableSlice,
  },
})