import styles from './styles.module.css'
import {HeaderPage} from './HeaderPage'
import {TitleSection} from './TitleSection';
import {DescriptionExperience} from './DescriptionExperience';
import { getObjContact } from '../utils';
import { useEffect, useState } from 'react';
import { SOURCE } from '../../../../../../constants';


export const PageTwo = ({props, applicant}) => {
    const [urlImage, setUrlImage] = useState()
    const {
        title,
        contacts,
        education,
        skills,
        experience,
        languages,
        summary,
    } = props

    const {
        firstName,
        lastName
    } = applicant

    // useEffect(() => {
    //     fetch(`${SOURCE.url}/api/v1/resume/${id}/avatar`)
    //         .then(response => response.json())
    //         .then(result => setUrlImage(result))
    // }, [id])

    const contactOjb = getObjContact(contacts)
 
    return (
        <div>
            <section className="container" style={{maxWidth: '700px', paddingTop: '30px'}}>
                <HeaderPage
                    user_mail={contactOjb.EMAIL}
                    phone={contactOjb.PHONE}
                    user_image={'url_user_image'}
                />
                <div className="row gy-1 fs-5 fw-normal">
                    <div className="col">
                        <p style={{marginBottom: '8px', color: 'black'}}>{`${firstName} ${lastName}`}, {title}</p>
                        <p style={{color: '#6F7C7F', marginBottom: '1.375rem'}}>{summary}</p>
                    </div>
                </div>

                {experience.length ? 
                <TitleSection title='Опыт Работы'/> : null}
                {experience?.map((elem) => {
                    return (
                        <DescriptionExperience
                            key={elem.id}
                            company_name={elem.company}
                            title_job={elem.position}
                            experience_start_year={elem.startYear}
                            experience_end_date_year={elem.endYear}
                            experience_start_month={elem.startMonth}
                            experience_end_date_month={elem.endMonth}
                            descriptioon_experience={elem.description}
                        />
                    )
                })}
                {education.length ?
                <TitleSection title='Образование'/> : null}
                {education.length ? 
                <div className={`row gy-1 ${styles.wrapper}`}>
                    {education.map((elem) => {
                        return(
                            <div className='d-flex justify-content-between flex-wrap' key={elem.id}>
                                <p className={`col-12 col-sm-3 ${styles.experience}`}>{elem.endYear}</p>

                                <div className="col-12 col-sm-9">
                                    <p className={styles.job}>{elem.name}</p>
                                    <p className={styles.description}>{elem.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div> : null}
                {skills.length || languages.length ?
                <TitleSection title='Другое'/> : null}
                <div className="row gy-2">
                    <div className='col-12 col-sm-2'></div>
                    {skills.length ?
                    <div className="col-12 col-sm-7">
                        <h3 className={`fw-bolder ${styles.box}`}>Навыки</h3>
                        <ul className={`row f-wrap  ${styles.list}`} style={{marginRight: '1.25rem'}}>
                            {skills?.map(elem => {
                                return <li
                                    key={elem}
                                    className={`col-6 col-sm-4 p-0 ${styles.item}`}
                                >{elem}</li>
                            })}
                        </ul>
                    </div> : null}
                    {languages.length ?
                    <div className="col-12 col-sm-3">
                        <h3 className={`fw-bolder ${styles.box}`}>Языки</h3>
                        <ul className={styles.list}>
                            {languages?.map(elem => {
                                return <li key={elem.id} className={styles.item}>{`${elem.name} ${elem.level}`}</li>
                            })}
                        </ul>
                    </div> : null}
                </div>
            </section>
        </div>
    )
}