import { useState } from 'react';
import { useNavigate } from 'react-router';
import { getAllVacancies } from '../../../api/vacancies';
import { DataTable } from '../../../components/table/Table';
import {
  labelsBySalaryCurrency,
  labelsByVacancyStatus,
} from '../../../constants';

import { SearchApplicants } from './GradesVacancies';
import {
  clearSliceFilters,
  setFilters,
} from '../../../store/slices/tableSlice';
import { useDispatch, useSelector } from 'react-redux';

export const Vacancies = ({ setState }) => {
  const { search } = useSelector((state) => state.tableSlice.filters);
  const dispatch = useDispatch();

  const customGetMethod = (access_token, queryObj, page, size) => {
    return getAllVacancies(
      access_token,
      { include: ['employer'], search: queryObj },
      page,
      size
    );
  };

  const clearFilters = () => {
    dispatch(clearSliceFilters({ search: '' }));
  };

  return (
    <div className='w-100'>
      <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
        Вакансии
      </p>
      <DataTable
        Template={Template}
        setParentState={setState}
        Wrapper={Wrapper}
        SearchAndFilter={SearchAndFilter}
        filterObject={{ search }}
        setFilterObject={setFilters}
        clearFilters={clearFilters}
        getMethod={customGetMethod}
      />
    </div>
  );
};

const SearchAndFilter = ({ filters, setFilters, onFilter, clearFilters }) => {
  return (
    <div className='d-flex align-items-center gap-4'>
      <SearchApplicants state={filters} setState={setFilters} />
      <button className='btn btn-warning' onClick={clearFilters}>
        Очистить
      </button>
      <button className='btn btn-success' onClick={onFilter}>
        Применить
      </button>
    </div>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className='table-responsive' key='uniqueKey'>
      <table className='table table-hover align-middle' id='myTable'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>название</th>
            <th scope='col'>компания</th>
            <th scope='col'>статус</th>
            <th scope='col'>з/п</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const Template = ({ u, i, setState }) => {
  const navigate = useNavigate();
  return (
    <tr key={i} onClick={() => navigate('/vacancies/' + u.id)}>
      <th>{i + 1}</th>
      <td style={{ cursor: 'pointer' }}>{u.name}</td>
      <td style={{ cursor: 'pointer' }}>{u.employer.name}</td>
      <td style={{ cursor: 'pointer' }}>{labelsByVacancyStatus[u.status]}</td>
      <td>
        {u.salary?.toLocaleString() +
          ' ' +
          labelsBySalaryCurrency[u.salaryCurrency]}
      </td>
    </tr>
  );
};
