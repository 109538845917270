import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice';
import { AppInput } from './../../components/ui/AppInput';
import { searchApplicantRegexp } from './../../constants';
import { setFilters } from '../../store/slices/tableSlice';

function cleanSpaces(input) {
  return input.trimStart().replace(/\s+/g, ' ');
}

export const SearchApplicants = () => {
  const filters = useSelector((state) => state.tableSlice.filters);
  const [text, setText] = useState(filters.search);

  const dispatch = useDispatch();

  const setSearch = (input) => {
    const search = cleanSpaces(input);

    if (!search || searchApplicantRegexp.test(search)) {
      setText(search);

      dispatch(
        setFilters({
          search: search.trim(),
        })
      );
    } else {
      dispatch(showErrorMsg('до 100 символов, до 7 слов'));
    }
  };

  return (
    <input
      className='form-control'
      placeholder='search...'
      style={{
        paddingTop: '0',
        paddingBottom: '0',
        height: '2.35rem',
      }}
      value={text}
      id='search'
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};

export const FilterApplicants = ({ setState }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className='btn px-3 primary'
        style={{ height: '2.4rem' }}
        onClick={() => setShow(true)}
      >
        Фильтр
      </button>
      <FilterModal setState={setState} show={show} setShow={setShow} />
    </>
  );
};

const FilterModal = ({ setState, show, setShow }) => {
  const filters = useSelector((state) => state.tableSlice.filters);
  const dispatch = useDispatch();

  const [localeState, setLocaleState] = useState({
    hadMeetingGte: filters.hadMeetingGte,
    hadMeetingLte: filters.hadMeetingLte,
  });

  const acceptFilter = () => {
    const filterData = {
      hadMeetingGte: localeState.hadMeetingGte
        ? new Date(localeState.hadMeetingGte).toISOString()
        : '',
      hadMeetingLte: localeState.hadMeetingLte
        ? new Date(localeState.hadMeetingLte).toISOString()
        : '',
    };

    dispatch(setFilters(filterData));
    setState((p) => ({
      ...p,
      ...filterData,
    }));
    setShow(false);
    dispatch(showSuccessMsg('Фильтры применены'));
  };

  return (
    <Modal
      dialogClassName='rounded-4'
      size='md'
      centered
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fw-bolded'>Создать</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <AppInput
            label='hadMeetingGte'
            type='datetime-local'
            value={localeState.hadMeetingGte}
            onChange={(v) =>
              setLocaleState((p) => ({ ...p, hadMeetingGte: v }))
            }
          />
          <AppInput
            label='hadMeetingLte'
            type='datetime-local'
            value={localeState.hadMeetingLte}
            onChange={(v) =>
              setLocaleState((p) => ({ ...p, hadMeetingLte: v }))
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn px-3 py-2 ms-auto btn primary'
          onClick={acceptFilter}
        >
          Применить
        </button>
      </Modal.Footer>
    </Modal>
  );
};
