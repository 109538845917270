import { Route, Routes } from 'react-router';
import { Vacancies } from './layouts/Vacancies';
import { EditVacancy } from './layouts/EditVacancy/EditVacancy';
import { NotFoundPage } from '../system/NotFoundPage';

export const VacanciesScreen = () => {
  return (
    <Routes>
      <Route path='/' element={<Vacancies />} />
      <Route path='/:id' element={<EditVacancy />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
