export const AppSelect = ({ className = '', label, value, onChange, options = [], disabled = false, style = {} }) => {
    if (typeof options[0] === 'string') {
        options = options.map(el => ({ key: el, value: el }))
    }
    return <div className={'mb-md-3 mb-2 ' + className} style={{ ...style }}>
        {label &&<label className="ms-3 position-absolute">
            <span className="h6 small bg-white text-muted px-1">{label}</span>
        </label> }
        <select className="form-select p-sm-3 py-2" style={{ marginTop: label ? '12px' : '', minWidth: '100px' }} value={value} disabled={disabled}
            onChange={onChange}>
            {options.map((el, i) => <option key={i} value={el.key}>{el.value}</option>)}
        </select>
    </div>
}