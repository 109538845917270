import { SOURCE } from '../constants';

export async function getMyMeetings(token, queryObj = {}) {
  const params = new URLSearchParams();

  const array = queryObj.include || [];
  array.forEach((value, _) => {
    params.append('include', value);
  });
  delete queryObj.include;

  for (const [key, value] of Object.entries(queryObj)) {
    if (value) {
      params.append(key, value);
    }
  }
  // params.append('size', queryObj.size)
  // queryObj.afterDateTime &&
  // 	params.append('afterDateTime', queryObj.afterDateTime)
  // queryObj.beforeDateTime &&
  // 	params.append('beforeDateTime', queryObj.beforeDateTime)

  const response = await fetch(
    `${SOURCE.url}meetings/my?` + new URLSearchParams(params),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getMeetings(token, queryObj = {}) {
  let query = '?';
  let include = queryObj.include || [];
  query += queryObj.hasFeedback ? 'include=' + queryObj.hasFeedback : '';
  include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });

  const response = await fetch(
    `${SOURCE.url}meetings${query === '?' ? '' : query}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function getMeetingById(token, id, queryObj = {}) {
  let query = '?';
  queryObj.include.forEach((el) => {
    if (query !== '?') query += '&';
    query += 'include=' + el;
  });

  const response = await fetch(
    `${SOURCE.url}meetings/${id}${query === '?' ? '' : query}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function updateMeeting(token, id, body) {
  const response = await fetch(`${SOURCE.url}meetings/` + id, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return false;
  }

  const json = await response.json();
  return json;
}

export async function deleteMeeting(token, id) {
  return await fetch(`${SOURCE.url}meetings/` + id, {
    method: 'DELETE',
    headers: {
      Authorization: token,
    },
  });
}

export async function uploadVideo(token, id, file) {
  const formData = new FormData();
  formData.append('file', file, file.name);

  const response = await fetch(`${SOURCE.url}meetings/${id}/video`, {
    method: 'PUT',
    headers: {
      Authorization: token,
    },
    body: formData,
  });

  return response.ok;
}
