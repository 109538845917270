import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createMeetingSlot, createMeetingSlots } from '../../api/meetingSlot'
import {
	COLORS,
	MEETING_TYPES,
	USER_ERROR_MESSAGES,
	USER_SUCCESS_MESSAGES,
} from '../../constants'
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice'
import { AppInput } from '../ui/AppInput'
import { AppSwitch } from '../ui/AppSwitch/AppSwitch'

export const CreateSlotModal = ({ shown, set }) => {
	const dispatch = useDispatch()

	const [state, setState] = useState({
		auto: true,
		selectedTime: '',
		types: [],
		selectedDate: '',
		StartWork: '',
		EndWork: '',
		interval: '',
	})

	const user = useSelector(state => state.userSlice.user)

	async function createSlotClickHandler() {
		if (!state.auto) {
			const [hourInterval, minutesInterval] = state.interval.split(':')
			const dateOfSlots = {
				startDate: new Date(state.StartWork).toISOString(),
				endDate: new Date(state.EndWork).toISOString(),
				interval: +hourInterval * 60 + +minutesInterval,
				types: state.types,
			}
			createSlots(dateOfSlots)
		} else {
			const dateOfSlot = {
				dateTime: new Date(state.selectedTime).toISOString(),
				types: state.types,
			}
			createSlot(dateOfSlot)
		}
	}

	const createSlot = async dateOfSlot => {
		try {
			await createMeetingSlot(user.access_token, dateOfSlot)
			dispatch(showSuccessMsg(USER_SUCCESS_MESSAGES.CREATE_SLOT))

			set(p => ({
				...p,
				createSlotModalShown: false,
				slots: { ...p.slots, loaded: false },
			}))
		} catch (error) {
			dispatch(showErrorMsg(USER_ERROR_MESSAGES.ERROR_SLOT))
			console.error(error)
		}

		setState(p => ({ ...p, selectedTime: '', types: [] }))
	}

	const createSlots = async arrayOfSlot => {
		try {
			await createMeetingSlots(user.access_token, arrayOfSlot)
			dispatch(showSuccessMsg(USER_SUCCESS_MESSAGES.CREATE_SLOTS))
			set(p => ({
				...p,
				createSlotModalShown: false,
				slots: { ...p.slots, loaded: false },
			}))
		} catch (error) {
			dispatch(showErrorMsg(USER_ERROR_MESSAGES.ERROR_SLOTS))
			console.error(error)
		}

		setState(p => ({ ...p, selectedTime: '', types: [] }))
	}
	return (
		<Modal
			dialogClassName='rounded-4'
			size={`${state.auto ? 'md' : 'lg'}`}
			centered
			show={shown}
			onHide={() => set(p => ({ ...p, createSlotModalShown: false }))}
		>
			<Modal.Header closeButton>
				<Modal.Title className='fw-bolded'>Создать</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column'>
					<div className='d-flex align-items-center mb-2'>
						<AppSwitch
							value={!state.auto}
							onChange={v => setState(p => ({ ...p, auto: !p.auto }))}
						/>
						<div className='ms-1'>auto</div>
					</div>
					<SelectTypeAndDate state={state} setState={setState} />

					<div className='row mx-0 gap-2'>
						{Object.keys(MEETING_TYPES).map((type, i) => {
							const active = state.types.indexOf(type) >= 0
							return (
								<div
									key={i}
									style={{
										cursor: 'pointer',
										border: '1px solid ' + COLORS.primary,
										color: !active ? COLORS.primary : '#fff',
										backgroundColor: !active ? '' : COLORS.primary,
									}}
									className='rounded-3 col-auto py-1 px-3 '
									onClick={() =>
										setState(p => ({
											...p,
											types: !active
												? [...p.types, type]
												: p.types.filter(e => e !== type),
										}))
									}
								>
									<p className='fw-bolded'>{MEETING_TYPES[type]}</p>
								</div>
							)
						})}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					className='btn px-3 py-2 ms-auto btn primary'
					disabled={
						!state.types.length ||
						(state.auto
							? !state.selectedTime
							: Boolean(!state.StartWork || !state.EndWork || !state.interval))
					}
					onClick={() => createSlotClickHandler()}
				>
					Создать
				</button>
			</Modal.Footer>
		</Modal>
	)
}

const SelectTypeAndDate = ({ state, setState }) => {
	return (
		<>
			{state.auto ? (
				<>
					<AppInput
						label='Дата встречи'
						type='datetime-local'
						value={state.selectedTime}
						onChange={v => setState(p => ({ ...p, selectedTime: v }))}
					/>
					<p className='fs-6 mb-2 fw-bolded'>Типы встречи в слоте</p>
				</>
			) : (
				<>
					<div className='row'>
						<div className='col d-flex'>
							<AppInput
								label='Начало'
								type='datetime-local'
								value={state.StartWork}
								className='w-100'
								onChange={v => setState(p => ({ ...p, StartWork: v }))}
							/>
						</div>
						<div className='col-sm d-flex'>
							<AppInput
								label='Конец'
								type='datetime-local'
								value={state.EndWork}
								className='w-100'
								onChange={v => setState(p => ({ ...p, EndWork: v }))}
							/>
						</div>
						<div className='col-md d-flex'>
							<AppInput
								label='Интервал'
								type='time'
								className='w-100'
								value={state.interval}
								onChange={v => setState(p => ({ ...p, interval: v }))}
							/>
						</div>
					</div>
					<p className='fs-6 mb-2 fw-bolded'>Типы встреч в слоте</p>
				</>
			)}
		</>
	)
}
