// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-wrapper:hover {
    transform: scale(1.05);
    background-color: var(--hover-light-primary);

}`, "",{"version":3,"sources":["webpack://./src/screens/promocodes/PromocodesScreen.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAA4C;;AAEhD","sourcesContent":[".code-wrapper:hover {\n    transform: scale(1.05);\n    background-color: var(--hover-light-primary);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
