import { SOURCE } from '../constants';

export async function deleteAccount(token, id) {
  const response = await fetch(`${SOURCE.url}employers/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    return false;
  }
  if (response.status === 204) return true;

  const json = await response.json();
  return json;
}

export async function getAllEmployers(token, queryObj = {}, page, size) {
  let query = '';
  let include = queryObj.include || [];
  include.forEach((el) => {
    if (query !== '') query += '&';
    query += 'include=' + el;
  });

  const params = {};
  if (query) params.include = include;
  if (page) params.page = page;
  if (size) params.size = size;

  const response = await fetch(
    `${SOURCE.url}employers?` + new URLSearchParams(params),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  if (response.status === 404) return 404;

  if (!response.ok) {
    return false;
  }

  if (response.status === 204) return true;

  const json = await response.json();
  return json;
}

export async function getCompany(id, token, ...args) {
  const arr = [...args];

  let include = arr || [];
  const params = new URLSearchParams();

  include.forEach((el) => {
    params.append('include', el);
  });

  const response = await fetch(`${SOURCE.url}employers/${id}?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (response.status == 404) return 404;

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  if (response.status === 204) return;

  const json = await response.json();
  return json;
}

export async function patchEmployer(token, id, patchEmployer) {
  const res = await fetch(SOURCE.url + 'employers/' + id, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(patchEmployer),
  });
  return res.ok;
}

export async function uploadEmployerAvatar(token, id, formData) {
  const res = await fetch(SOURCE.url + 'employers/' + id + '/avatar', {
    method: 'PUT',
    headers: {
      Authorization: token,
    },
    body: formData,
  });
  return res.ok;
}
