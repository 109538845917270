import {SOURCE} from "../constants";

export async function createVacancyResponse(access_token, data) {
    try {
        const res = await fetch(SOURCE.url + 'vacancyResponses', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
            body: JSON.stringify(data),
        });
        if (!res.ok) return false;
        return await res.json();
    } catch {
        return false;
    }
}